import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Store } from 'types/store';
import { isRegionValid, isUserFullyCanceled, isUserActive } from 'utils/common';
import { REDIRECT_LOCATIONS } from './constant';

// IMPORTANT NOTE ABOUT THIS FILE:
// The path that this hook generates is used ONLY as a backup in case the backend is unable
// to determine the correct path for the user. The application will first try and use the
// resume_url from getOnboardingStatus, and if that fails, it will use the path generated
// by this hook.

export const useLoginSuccess = () => {
  const location = useLocation();
  const { state: locationState } = location;
  const {
    attributes: { role, patient, phone }
  } = useSelector((state: Store) => state.global_reducer.current_user);
  const { therapist, current_provider } = useSelector((store: Store) => store.patient_reducer);
  const canReactivate = useSelector((state: Store) => state.patient_reducer?.canReactivate);
  const accessToken = useSelector((state: Store) => state.global_reducer.current_user.attributes['access-token']);

  const getRedirectPath = () => {
    const isPhoneNumberScreenShown = !phone;
    const isPreferredNameScreenShown = phone && !patient?.preferred_name;
    const isZipcodeScreenShown = patient?.preferred_name && !patient?.postal_code;

    let path;

    const patientRegion = patient?.region;
    const patientStatus = patient?.status;
    const nextLocation = locationState?.next;

    if (isPreferredNameScreenShown) {
      path = '/patient/preferred_name';
    } else if (isZipcodeScreenShown) {
      path = '/patient/zip_code';
    } else if (isPhoneNumberScreenShown) {
      path = '/patient/phone-number';
    } else if (patientRegion && !isRegionValid(patientRegion)) {
      path = '/patient/create-account';
    } else if (isUserFullyCanceled(patientStatus) && canReactivate) {
      path = '/patient/dashboard/canceled';
    } else if (!isUserActive(patientStatus)) {
      path = '/patient/dashboard/profile_info';
    } else if (sessionStorage.getItem('fsa') === 'true') {
      path = '/payment-fsa';
    } else {
      path = '/patient/dashboard';

      if (nextLocation) {
        const nextLocationComponents = nextLocation.split('?');
        const nextLocationPath = nextLocationComponents[0];

        if (REDIRECT_LOCATIONS.includes(nextLocationPath)) {
          if (nextLocationPath === '/patient/reassign/provider') {
            path = `/patient/dashboard/reassignment/prescriber/intro/${current_provider?.user_id}`;
          } else if (nextLocationPath === '/patient/reassign/therapist') {
            path = `/patient/dashboard/reassignment/therapist/intro/${therapist?.user_id}`;
          } else {
            path = nextLocation;
          }
        }
      }
    }

    return path;
  };

  return useMemo(() => {
    return role === 'patient' ? { redirectPath: getRedirectPath(), role } : {};
  }, [patient, accessToken]);
};
