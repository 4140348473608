import React, { ChangeEvent, ChangeEventHandler, ReactElement } from 'react';
import styled from 'styled-components/macro';
import Error from '../Error';

const TextFieldArea = styled.textarea`
  display: flex !important;
  flex-direction: column !important;
  width: 100%;
  min-height: 100px;
  color: #250044;
  padding: 7px;
  background: #ffffff;
  border: 1px solid #d3dbf8;
  box-sizing: border-box;
  box-shadow: 0 0 12px 4px #f6f6f9;
  border-radius: 4px;
  font-size: 14px;
  margin: 10px 0;

  ::placeholder {
    font-size: 1em;
    font-style: italic;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: #250044;
  }
`;

export interface CustomCheckBoxProps {
  event_handler: ChangeEventHandler;
  isChecked?: boolean;
  name?: string;
  radio?: boolean;
  id?: string;
}

export interface TextAreaProps {
  error?: string;
  onChange(e: React.ChangeEvent): void;
  value: string | undefined;
  rows: number;
  placeholder: string;
  maxLength?: number;
}

export interface CustomRadioButtonProps {
  onChange?: () => void;
  name?: string;
  checked?: boolean;
  id?: string;
}

export interface CustomRadioButtonWithTextProps {
  onChange(): void;
  name?: string;
  checked?: boolean;
  text: string;
  className: string;
}

export interface ButtonProps {
  onClick?: () => void;
  className?: string;
  value?: string;
  id?: string;
  is_disabled?: boolean;
}

export const CustomCheckBox = ({ event_handler, isChecked = false, name = '', radio, id }: CustomCheckBoxProps) => (
  <div className={`checkbox-container ${radio ? 'checkbox-radio' : ''}`}>
    <input
      aria-label="checkBox-input"
      type="checkbox"
      onChange={event_handler}
      name={name}
      id={id}
      checked={isChecked}
    />
    <span className="checkbox-checkmark">
      <img src={`${process.env.PUBLIC_URL}/img/check-dark.svg`} alt="" />
    </span>
  </div>
);

export const TextArea = ({ error = '', onChange, value, rows, placeholder, maxLength }: TextAreaProps) => (
  <>
    <TextFieldArea
      aria-label="textArea-input"
      placeholder={placeholder}
      onChange={onChange}
      rows={rows}
      defaultValue={value}
      maxLength={maxLength}
    />
    <Error error={error} style={{ marginBottom: '10px', textAlign: 'center' }} />
  </>
);

export const CustomRadioButton = ({
  onChange = () => {},
  name = 'radio',
  checked = false,
  id
}: CustomRadioButtonProps) => (
  <label className="radio-button-container" htmlFor={id}>
    <input type="radio" defaultChecked={checked} name={name} id={id} onChange={onChange} />
    <span className="radio-button" />
  </label>
);

export const CustomRadioButtonWithText = ({
  onChange,
  checked,
  name,
  text,
  className
}: CustomRadioButtonWithTextProps) => (
  <>
    <CustomRadioButton onChange={onChange} checked={checked} name={name} />
    <div className={className}>{text}</div>
  </>
);

interface Divwrap {
  [key: string]: unknown;
}

export const divwrap =
  (classNames?: string, styles?: React.CSSProperties) =>
  (Component: React.ElementType) =>
  (props: Divwrap): unknown => (
    <div className={classNames} style={styles}>
      <Component {...props} />
    </div>
  );

export const Button = ({
  className = '',
  onClick = () => {},
  value = '',
  id = '',
  is_disabled = false
}: ButtonProps) => (
  <button type="button" className={className} id={id} onClick={onClick} disabled={is_disabled}>
    {value}
  </button>
);

interface InputTypeProps {
  labelStyles: React.CSSProperties | undefined;
  className: string;
  isLabel: boolean;
  event_handler: (event: ChangeEvent<HTMLInputElement>) => void;
  text: string;
  error: string;
  value: string;
  autoComplete: string;
  id: string;
  pattern: string;
}

// remove classNames and styles when new payment screen will be implemented
export const inputType = (type: string) => {
  return (props: InputTypeProps): ReactElement => {
    const label: React.CSSProperties = { width: '100%', textAlign: 'left', color: '#250044', flex: 1 };
    const {
      labelStyles = {},
      className = '',
      isLabel = true,
      event_handler = () => {},
      text = '',
      error = '',
      value = '',
      autoComplete = 'on',
      id = '',
      pattern = '.*'
    } = props;

    const labelStyle = { ...label, ...labelStyles };
    const isOn = value.length > 0 && isLabel;

    return (
      <label style={labelStyle} htmlFor={id}>
        {isOn ? text : ''}
        <input
          id={id}
          className={className}
          onChange={event_handler}
          type={type}
          pattern={pattern}
          defaultValue={value}
          placeholder={text}
          autoComplete={autoComplete}
        />
        <Error error={error} />
      </label>
    );
  };
};

export interface inputNoBorderProps {
  event_handler: (event: ChangeEvent<HTMLInputElement>) => void;
  text: string;
  default_value: string;
  type: string;
}

export interface resetButtonProps {
  event_handler: (e: React.MouseEvent, type: string) => void;
  type: string;
  description: string;
}

export const pwd_reset_button = (
  event_handler: (e: React.MouseEvent, type: string) => void,
  description: string,
  type: string
): ReactElement => (
  <input className="btn-pwd-reset" onClick={e => event_handler(e, type)} type="button" value={description} />
);
