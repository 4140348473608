import React, { createContext, useContext } from 'react';
import { GlobalsResponse } from '@cerebral-inc/utils';
import { useQuery } from 'react-query';
import { useApi } from 'utils/api';

interface Regions {
  isTherapistRegion(region: string | undefined): boolean;
  therapyRegions: string[];
}

type GlobalData = GlobalsResponse & Regions;

export const defaultValue: GlobalData = {
  'chatting-available': false,
  'regions-available-for-insurance': [],
  'regions-available-for-therapists': [],
  api_version: '',
  max_coordinator_cap: '',
  promo_notification_variable: '',
  terms_and_conditions_version: '',
  isTherapistRegion: () => false,
  therapyRegions: [],
  default_papr_model: '',
  default_path_model: '',
  default_prof_model: ''
};

export const GlobalsContext = createContext<GlobalData>(defaultValue);

export const GlobalsProvider = ({ children }: { children: React.ReactNode }) => {
  const api = useApi();
  const { data } = useQuery('globalData', () => api.get<GlobalsResponse>('/api/globals'));

  const response = data?.data;
  const therapyRegions = response?.['regions-available-for-therapists'] || [];
  const isTherapistRegion = (region: string | undefined) => (region ? therapyRegions.includes(region) : false);

  const globalData: GlobalData = response
    ? {
        ...response,
        isTherapistRegion,
        therapyRegions
      }
    : defaultValue;

  return <GlobalsContext.Provider value={globalData}>{children}</GlobalsContext.Provider>;
};

export const useGlobals = (): GlobalData => useContext(GlobalsContext);

export interface WithGlobalProps {
  globals?: GlobalData;
  children?: React.ReactNode;
}

export const withGlobals = <P extends WithGlobalProps>(Component: React.ComponentType<P>) => {
  return (props: P) => {
    const globalData = useContext(GlobalsContext);
    const { children } = props;

    return (
      <Component {...props} globals={globalData}>
        {children}
      </Component>
    );
  };
};
