import React, { ReactNode } from 'react';
import { NB } from '@cerebral-inc/ui';

const { Flex } = NB;

export const OnboardingMobileBodyWrapper = ({
  children,
  shouldVerticallyAlignToCenter
}: {
  children: ReactNode;
  shouldVerticallyAlignToCenter?: boolean;
}) => {
  return (
    <Flex
      minH={{ base: 'calc(100dvh - 180px)', md: 'auto' }}
      justifyContent={shouldVerticallyAlignToCenter ? 'center' : 'auto'}
    >
      <>{children}</>
    </Flex>
  );
};
