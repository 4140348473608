import { useFeatureFlags } from 'features';
import { CUSTOM_EVENT, IAnalyticsData, ANALYTICS_EVENT_TYPE } from './types';
import { getMSClickId } from './utils';

const useAnalyticsPlatform = () => {
  const baseURL = `${process.env.REACT_APP_CEREBRAL_PLATFORM_URL}/api/analytics-platform/v1/track`;
  const { data: flags } = useFeatureFlags();

  const track = (type: ANALYTICS_EVENT_TYPE, data: IAnalyticsData) => {
    const jsonData = new Blob(
      [
        JSON.stringify({
          platforms: data.platforms,
          content: {
            eventName: data.eventName,
            pageUrl: data.pageUrl,
            ltv: data.ltv,
            planNameAlias: data.planNameAlias,
            clickId: getMSClickId()
          }
        })
      ],
      {
        type: 'application/json'
      }
    );

    navigator.sendBeacon(`${baseURL}/${type}`, jsonData);
  };

  const trackCustomEvent = (data: IAnalyticsData) => {
    // track
    if (flags.enable_bing_ads) {
      track(CUSTOM_EVENT, data);
    }
  };

  return {
    trackCustomEvent
  };
};

export default useAnalyticsPlatform;
