import {
  prescriptionProof,
  selectPlan,
  lastQuestions,
  phoneConfirmation,
  prvMeds,
  hhMeds,
  sameMeds
} from './additionalQuestions';

export const mapTitleToType = title => {
  switch (title) {
    case 'ADHD':
      return 'asrs';
    case 'Depression':
      return 'phq9';
    case 'Anxiety':
      return 'gad7';
    case 'Insomnia':
      return 'isi';
    case 'Bipolar':
      return 'mdq';
    case 'Smoking':
      return 'smoking';
    case 'Alcohol':
      return 'alcohol';
    case 'PTSD':
      return 'pcl5';
    case 'Alcohol Dependence':
      return 'ldq';
    case 'Opioid Use':
      return 'oud';
    default:
      return '';
  }
};

/**
 * For "prv_med" question.
 * We don't want to ask about medication related to OUD if the patient has
 * comorbitidies.
 *
 * E.g. we want to ask about "Anxiety" not "Anxiety or Opioid Use"
 */
const serviceLinesToString = lines => {
  if (!lines.length) return '';
  const serviceLines = lines.length > 1 ? lines.filter(line => line.title !== 'Opioid Use') : lines;

  let serviceLineStr = serviceLines[0].title;
  if (serviceLines.length > 1) {
    const last = serviceLines.pop();
    serviceLineStr = serviceLines.map(line => line.title).join(', ');
    serviceLineStr += ` or ${last.title}`;
  }
  return serviceLineStr.toLowerCase().replace('adhd', 'ADHD');
};

const mapRegionToPayer = region =>
  ({
    NY: 'a Cigna Behavioral Health or Medicare of New York',
    CA:
      'an Aetna California, Anthem Blue Cross of California, Blue Shield of California, Cigna Behavioral Health, ' +
      'Medicare, or Kaiser Permanente/Magellan of California',
    TX: 'a Blue Cross and Blue Shield/Magellan Health or a Cigna Behavioral Health',
    GA: 'an Aetna, Anthem Blue Cross Blue Shield, Cigna Behavioral Health, or Medicare of Georgia',
    AZ: 'a Cigna Behavioral Health of Arizona',
    WA: 'an Aetna Washington or a Cigna Behavioral Health of Washington',
    IN: 'a Medicare of Indiana',
    OH: 'an Aetna Ohio or a Medicare of Ohio',
    TN: 'an Aetna Tennessee or a Medicare of Tennessee',
    FL: 'an Aetna or Cigna Behavioral Health of Florida',
    IL: 'a Cigna Illinois',
    PA: 'a Pennsylvania Highmark Blue Shield or Cigna Pennsylvania',
    VA: 'a Cigna Virginia',
    AR: 'an Aetna of Arkansas or a Blue Cross Blue Shield of Arkansas',
    CO: 'an Aetna Colorado',
    CT: 'an Aetna Connecticut',
    NC: 'an Aetna North Carolina',
    SC: 'an Aetna South Carolina',
    WV: 'an Aetna West Virginia'
  })[region] || '';

export const updateTitles = (bank_name, questions, { offeringKey, serviceLines, region }) => {
  const serviceLineStr = serviceLinesToString(serviceLines);
  const insurancePayer = mapRegionToPayer(region);

  switch (bank_name) {
    case 'update_plan':
      questions.forEach(q => {
        if (q.name === 'upgrade') {
          q.title = q.title.replace(/\$\{offering_key\}/, offeringKey);
        }
      });

      return questions;

    case 'smoking_update_plan':
      return questions;

    case 'health_and_history_part2':
      questions.forEach(q => {
        if (q.name === 'specific_meds' || q.name === 'prv_med') {
          // eslint-disable-next-line no-template-curly-in-string
          q.title = q.title.replace(/\$\{service_lines\}/, serviceLineStr);
        }
      });

      return questions;
    case 'in_insurance':
      questions.forEach(q => {
        if (q.name === 'choose_in_insurance') {
          // eslint-disable-next-line no-template-curly-in-string
          q.title = q.title.replace(/\$\{insurance_payer\}/, insurancePayer);
        }
      });

      return questions;

    case 'mental_health_medication_assessment':
      questions.forEach(q => {
        if (q.name === 'specific_meds' || q.name === 'prv_med') {
          // eslint-disable-next-line no-template-curly-in-string
          q.title = q.title.replace(/\$\{service_lines\}/, serviceLineStr);
        }
      });

      return questions;
    default:
      return questions;
  }
};

export const setBranchQuestions = flow_type => {
  switch (flow_type) {
    case 'full_assessment_first':
      return (state, action) => {
        const { plan, visit_object } = state;
        const { service_lines, is_follow_up } = visit_object;
        let questions = [...action.questions];
        const values = {
          offeringKey: plan.offering_key === 'medication' ? 'Therapy' : 'Medication Management',
          serviceLines: [...service_lines],
          region: action.region
        };

        // addding results question to the end of the assessment_end bank
        // we can remove it once BE for the BE flow is deployed
        if (action.bank_name === 'assessment_end') {
          if (is_follow_up) {
            questions = questions.filter(q => q.name !== 'events');
          }

          const results = questions.find(q => q.name === 'results');

          if (!results) {
            questions.push(lastQuestions);
          }
        }
        if (visit_object.visit_type === 'adhoc') {
          questions.push(lastQuestions);
        }

        // addding phone_confirmation question to the end of the communication bank
        // we can remove it once BE for the BE flow is deployed
        if (action.bank_name === 'communication') {
          const phoneQuestion = questions.find(q => q.name === 'phone_confirmation');

          if (!phoneQuestion) {
            questions.push(phoneConfirmation);
          }
        }

        // addding prescription_proof, prvMeds, hhMeds, sameMeds questions to health_and_history_part2 bank
        // we can remove it once BE for the BE flow is deployed
        if (action.bank_name === 'health_and_history_part2') {
          const proofQuestion = questions.find(q => q.name === 'prescription_proof');
          const prvMedQuestion = questions.find(q => q.name === 'prv_med');
          const hhMedQuestion = questions.find(q => q.name === 'hh_med');
          const sameMedQuestion = questions.find(q => q.name === 'same_med');

          const currentIdx = questions.findIndex(q => q.name === 'current_medications');

          if (!sameMedQuestion) {
            questions.splice(currentIdx, 0, sameMeds);
          }

          if (!hhMedQuestion) {
            questions.splice(currentIdx, 0, hhMeds);
          }

          if (!prvMedQuestion) {
            questions.splice(currentIdx, 0, prvMeds);
          }

          if (!proofQuestion) {
            const idx = questions.findIndex(q => q.name === 'side_effects');
            questions.splice(idx, 0, prescriptionProof);
          }
        }

        // addding select_plan question to the begining of the checkout bank
        // we can remove it once BE for the BE flow is deployed
        if (action.bank_name === 'checkout') {
          const planQuestion = questions.find(q => q.name === 'select_plan');

          if (!planQuestion) {
            questions.unshift(selectPlan);
          }
        }
        return updateTitles(action.bank_name, questions, values);
      };
    case 'legacy':
      return (state, action) => {
        const { plan, visit_object } = state;
        const { service_lines, is_follow_up } = visit_object;
        const values = {
          offeringKey: plan.offering_key === 'medication' ? 'therapy' : 'medication',
          serviceLines: service_lines,
          region: action.region
        };

        let questions = null;

        if (action.bank_name === 'assessment_end' && is_follow_up) {
          questions = questions.filter(bank => bank.name !== 'events');
        }

        if (action.bank_name === 'screen') {
          const types = service_lines.map(line => mapTitleToType(line.title));
          questions = action.questions.filter(question => types.includes(question.assessment_type));
        } else {
          questions = [...action.questions];
        }

        return updateTitles(action.bank_name, questions, values);
      };
    default:
      return () => {};
  }
};

export const sortPrescriptions = prescriptions => {
  const unique = prescriptions.reduce((acc, p) => {
    const { id } = p.treatment;

    if (acc[id]) {
      if (Date.parse(acc[id].created_at) < Date.parse(p.created_at)) {
        acc[id].refills = p.refills;
      }

      if (Date.parse(acc[id].current_treatment_expiration_date) < Date.parse(p.current_treatment_expiration_date)) {
        acc[id].current_treatment_expiration_date = p.current_treatment_expiration_date;
      }
    } else {
      acc[id] = { ...p };
    }

    return acc;
  }, {});

  return Object.keys(unique).map(key => unique[key]);
};

export const mapTitleToIndex = title => {
  switch (title) {
    case 'Opioid Use':
      return 0;
    case 'Depression':
      return 1;
    case 'Anxiety':
      return 2;
    case 'Insomnia':
      return 3;
    case 'ADHD':
      return 4;
    case 'Bipolar':
      return 5;
    case 'Smoking':
      return 6;
    case 'Alcohol':
      return 7;
    case "I'm not sure":
      return 8;
    case 'Other':
      return 9;
    case 'Self Care':
      return 10;
    case 'PTSD':
      return 11;
    case 'Alcohol Dependence':
      return 12;
    default:
      return -1;
  }
};

export const populate = (original, idx, newArr) => {
  if (!newArr.length) {
    return [...original];
  }

  return [
    ...original.slice(0, idx + 1),
    ...newArr,
    /**
     * last four questions to avoid duplictaion.
     * 4 is a megic number and it should be avoided but it should do for now
     */
    ...original.slice(original.length - 4)
  ];
};
