import React from 'react';
import styled from 'styled-components/macro';
import classnames from 'classnames';

export const SpinnerHolder = styled.div<{ height?: string }>`
  height: ${({ height }) => height ?? '100%'};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default styled(({ size = '1.8rem', borderWidth = '2px', color = 'inherit', className }) => (
  <div
    style={{ width: size, height: size, borderWidth, color }}
    className={classnames('spinner-border spinner-border-sm', className)}
    role="status"
  >
    <span className="sr-only">Loading...</span>
  </div>
))``;
