import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import migrations from './migrations';

// createStore(reducer, [preloadedState], [enhancer])
// Todo: preloadedState

const persistConfig = {
  key: 'root',
  // TODO: IMPORTANT!!!
  version: 25, //  <---- increment this version together with migrations key every time when you add a new property to any reducer
  storage,
  migrate: createMigrate(migrations, { debug: true }),
  blacklist: ['messenger_reducer']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));
  const persistor = persistStore(store);
  return { store, persistor };
};
