import axios from 'axios';
import { useApiGateway } from 'types/universal-login.utils';

const baseURL = useApiGateway()
  ? `${process.env.REACT_APP_API_SERVER_API_GATEWAY_URL}/public`
  : process.env.REACT_APP_API_SERVER_URL;
const axiosInstance = axios.create({
  baseURL,
  timeout: 30000
});

export default axiosInstance;
