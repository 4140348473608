export const PRE_SIGNUP_BASE_PATH = '/pre-signup';
export const WELCOME = 'welcome';
export const GETTING_STARTED = 'getting-started';
export const NAME = 'name';
export const FEELINGS = 'feelings';
export const FEELINGS_WELCOME = 'feelings-welcome';
export const ZIP_CODE = 'zip-code';
export const ZIP_CODE_WELCOME = 'zip-code-welcome';
export const GREETINGS = 'greetings';

export const PRE_SIGNUP_QUESTION_BANK = [
  WELCOME,
  FEELINGS,
  FEELINGS_WELCOME,
  GETTING_STARTED,
  NAME,
  GREETINGS,
  ZIP_CODE,
  ZIP_CODE_WELCOME
];
