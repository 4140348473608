import React from 'react';
import HelpContact from 'components/HelpContact';
import { Title, Header, BodyContainer, Icon, Paragraphs, PageContainer } from './components';

export const MaintenancePage = (): JSX.Element => {
  return (
    <PageContainer>
      <Header />
      <BodyContainer>
        <Icon />
        <Title />
        <Paragraphs />
      </BodyContainer>
      <HelpContact isMaintenanceMode />
    </PageContainer>
  );
};
