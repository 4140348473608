import React from 'react';
import { NB } from '@cerebral-inc/ui';

const { Text, Flex, Image } = NB;

interface IImageStepsProps {
  steps: ImageStep[];
  isNewUi?: boolean;
}

interface ImageStep {
  icon: string | JSX.Element;
  title?: string;
  description: string;
}

export const ImageSteps = ({ steps, isNewUi }: IImageStepsProps): JSX.Element => {
  return (
    <Flex flexDir={{ base: 'column', lg: 'row' }} maxWidth="100%" alignItems="center">
      {steps.map((step, index) => {
        return (
          <Flex
            w={{ base: '100%', lg: '309.33px' }}
            maxW="327px"
            flexDir={{ base: isNewUi ? 'row' : 'column', lg: 'column' }}
            minH={{ base: 'auto', lg: '144px' }}
            pb={index === steps.length - 1 ? { base: '20px', lg: '0' } : '0'}
            textAlign="center"
            mr={{ base: '0', lg: '32px' }}
            ml={{ base: '0', lg: '32px' }}
            mt={{ base: '25px', lg: '0' }}
            key={step.description}
          >
            {typeof step.icon === 'string' ? (
              <Image src={step.icon} alt={step.description} height="48px" width="48px" mx="auto" mb="16px" />
            ) : (
              step.icon
            )}
            {step?.title && (
              <Text
                fontSize="16px"
                lineHeight="20px"
                textAlign="center"
                fontFamily="Poppins"
                fontWeight="600"
                color="#353A41"
                mb="8px"
              >
                {step.title}
              </Text>
            )}
            <Text
              fontSize="14px"
              lineHeight="20px"
              textAlign={{ base: isNewUi ? 'left' : 'center', lg: 'center' }}
              ml={{ base: isNewUi ? '24px' : '0', lg: '0' }}
              fontFamily="Poppins"
              fontWeight="400"
              color="#353A41"
            >
              {step.description}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
};
