import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useApi } from 'utils/api';
import type { ProgressiveResponse } from '../types';

interface InsurancePayer {
  value: number;
  label: string;
  rte_enabled: boolean;
  display_name: string | null;
}

interface StateConfig {
  region: string;
  name: string;
  rte_enabled: boolean;
  insurance_payers: InsurancePayer[];
}

export interface RteConfigs {
  global_rte_enabled: boolean;
  states: StateConfig[];
}

export type FlagData = Record<string, boolean>;

export const useRTEConfigs = (
  params?: Record<string, string | number | undefined | null | boolean>
): ProgressiveResponse<RteConfigs> => {
  const api = useApi();

  const { data, status, isLoading, isSuccess } = useQuery(
    ['rteConfigs', ...(params ? Object.entries(params).map(([key, value]) => `${key}:${value}`) : [])],
    () => api?.get<RteConfigs>('/api/insurance/rte_configs', params ? { params } : {}),
    { enabled: !!api }
  );

  return useMemo(
    () => ({
      status,
      isLoading,
      isSuccess,
      data: {
        global_rte_enabled: data?.data?.global_rte_enabled ?? false,
        states: data?.data?.states ?? []
      }
    }),
    [data, params, isLoading]
  );
};
