import { useSelector } from 'react-redux';
import { Store } from 'types/store';

export const MAIN_FLOW = 'initial_v3';

export const useIsUpdatePaymentScreen = (): boolean => {
  const flowKey = useSelector((store: Store) => store.patient_reducer?.visit_object?.flow_key);
  const isMainFlow = flowKey === MAIN_FLOW;
  const isInsurance = useSelector(({ patient_reducer: { plan } }: Store) => plan.is_insurance);
  return isMainFlow || (isInsurance !== undefined ? isInsurance : false);
};
