export interface Variation {
  variation: string;
  'payment-banner': string;
  'payment-banner-week'?: string;
  expired?: boolean;
}

const variations: Variation[] = [
  {
    variation: 'EMPTY',
    'payment-banner': 'Your monthly subscription will automatically be billed 30 days after signup. Cancel anytime.',
    'payment-banner-week':
      'Your monthly subscription will automatically be billed 30 days after signup. Cancel anytime.'
  },
  {
    variation: 'default',
    'payment-banner':
      "We've gone ahead and applied your  discount for the first month of your membership plan. " +
      'Your monthly subscription will automatically be billed 30 days after signup. Cancel anytime.',
    'payment-banner-week':
      "We've gone ahead and applied your  discount for the first month of your membership plan. " +
      'Your monthly subscription will automatically be billed 30 days after signup. Cancel anytime.'
  },
  {
    variation: '*',
    'payment-banner': 'Your monthly subscription will automatically be billed 30 days after signup. Cancel anytime.',
    'payment-banner-week':
      'Your monthly subscription will automatically be billed 30 days after signup. Cancel anytime.'
  },
  {
    variation: 'STARTTODAY',
    'payment-banner':
      'We are offering your first month for only $45 if you sign up today. Just use the following promo code below: ' +
      '<br /> <b>STARTTODAY</b><br />You will be billed monthly and you may cancel any time.',
    'payment-banner-week':
      'We are offering your first month for only $11.25/week if you sign up today. Just use the following promo code below: ' +
      '<br /> <b>STARTTODAY</b><br />You will be billed monthly and you may cancel any time.'
  },
  {
    variation: 'JUST25',
    'payment-banner': `We are offering $25/month for your first 2 months if you sign up before<br /> September 1st. Just use the following promo code below:
<br /><b>JUST25</b><br />You will be billed monthly and you may cancel any time.`,
    expired: true
  },
  {
    variation: 'SAVE75',
    'payment-banner':
      'We are offering 75% off for your first 2 months if you sign up before<br /> September 1st. ' +
      'Just use the following promo code below:<br/> <b>SAVE75</b> <br />You will be billed monthly and you may cancel any time.',
    expired: true
  },
  {
    variation: '1MONTH25',
    'payment-banner':
      "We've gone ahead and applied your discount for your first month. " +
      'Your plan will automatically be renewed at $99/month starting one month from today. Cancel anytime.',
    expired: true
  }
];

export default variations;
