import { is_signed_in } from 'actions/user_auth_action';
import { useSelector } from 'react-redux';
import { Store } from 'types/store';
import { useApi } from 'utils/api';

const useIsSignedIn = () => {
  const api = useApi();
  const user = useSelector((state: Store) => state.global_reducer.current_user);

  const isAuth0SignedIn = async (): Promise<boolean> => {
    try {
      await api.get('/api/v3/auth/token_validation');
      return true;
    } catch (error) {
      return false;
    }
  };

  const isCerebralSignedIn = async (): Promise<boolean> => {
    const signedIn = await is_signed_in(api);
    return signedIn as unknown as boolean;
  };

  const isSignedIn = async (): Promise<boolean> => {
    if (user.attributes && user.attributes.auth0AccessToken) {
      return isAuth0SignedIn();
    }

    if (user.attributes && user.attributes['access-token']) {
      return isCerebralSignedIn();
    }

    return false;
  };

  return { isSignedIn };
};

export default useIsSignedIn;
