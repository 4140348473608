import axios from 'axios';
import { handleActions } from 'redux-actions';
import { mapResponseToError } from '../utils/common';

export const API_IDLE = 'API_IDLE';
export const API_PENDING = 'API_PENDING';
export const API_SUCCESS = 'API_SUCCESS';
export const API_FAILURE = 'API_FAILURE';
export const API_REAUTH = 'API_REAUTH';

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error.response);
  }
);

const api_axios = (url, header, body, type) => {
  if (type === 'GET') {
    return axios.get(url, header);
  }
  if (type === 'POST') {
    return axios.post(url, body, header);
  }
  if (type === 'PUT') {
    return axios.put(url, body, header);
  }
  // eslint-disable-next-line no-throw-literal
  throw 'Unimplemented REST type in api middleware';
};

export const api_reset = () => dispatch => {
  dispatch({
    type: API_IDLE
  });
};

export const api_call =
  (type, url, header, body = null) =>
  dispatch => {
    dispatch({ type: API_PENDING });

    return api_axios(url, header, body, type)
      .then(resp => {
        dispatch({
          type: API_SUCCESS,
          data: resp.data
        });

        return resp.data;
      })
      .catch(error => {
        const responseError = mapResponseToError(error);

        if (error && error.status === 401) {
          dispatch({
            type: API_REAUTH,
            error: responseError
          });
        } else {
          dispatch({
            type: API_FAILURE,
            error: responseError
          });
        }

        throw new Error(responseError);
      });
  };

export const initial_api = {
  status: '',
  error: '',
  data: null
};

export const api_middleware = handleActions(
  {
    [API_PENDING]: state => {
      return {
        ...state,
        status: 'PENDING',
        error: false
      };
    },
    [API_SUCCESS]: (state, action) => {
      return {
        ...state,
        status: 'SUCCESS',
        data: action.data,
        error: ''
      };
    },
    [API_FAILURE]: (state, action) => {
      return {
        ...state,
        status: 'FAILURE',
        error: action.error
      };
    },
    [API_REAUTH]: (state, action) => {
      return {
        ...state,
        status: 'REAUTH',
        error: action.error
      };
    },
    [API_IDLE]: state => {
      return {
        ...state,
        status: 'IDLE',
        error: false
      };
    }
  },
  initial_api
);
