const prescriptionProof = {
  name: 'prescription_proof',
  question_type: 'prescription_proof',
  title: 'Proof of past prescriptions.'
};

const selectPlan = {
  name: 'select_plan',
  question_type: 'plan_selector'
};

const lastQuestions = {
  question_type: 'preliminary_results',
  name: 'results'
};

const phoneConfirmation = {
  id: 4567, // need to update when push to BE
  name: 'phone_confirmation',
  title: 'Please confirm your phone number.',
  description: null,
  assessment_type: null,
  skip_option: null,
  subscript: '*Your Care Team will use this number for your appointments.',
  question_type: 'phone_confirmation',
  options: [],
  flag_title: null,
  optional: null,
  save_answer: true
};

const prvMeds = {
  name: 'prv_med',
  title: 'Have you taken any medication for ${service_lines} in the past?', // eslint-disable-line no-template-curly-in-string
  description: null,
  flag_title: null,
  options: [
    {
      title: 'Yes',
      name: 'Yes'
    },
    {
      title: 'No',
      name: 'No'
    }
  ],
  optional: false,
  question_type: 'question_selector',
  save_answer: true
};

const hhMeds = {
  name: 'hh_med',
  title: 'Select medication(s) that applies.',
  description: null,
  flag_title: 'Please specify medication name and dosage.',
  options: [
    {
      title: 'Bupropion (Wellbutrin)',
      name: 'Bupropion (Wellbutrin)'
    },
    {
      title: 'Sertraline (Zoloft)',
      name: 'Sertraline (Zoloft)'
    },
    {
      title: 'Fluoxetine (Prozac)',
      name: 'Fluoxetine (Prozac)'
    },
    {
      title: 'Citalopram (Celexa)',
      name: 'Citalopram (Celexa)'
    },
    {
      title: 'Escitalopram (Lexapro)',
      name: 'Escitalopram (Lexapro)'
    },
    {
      title: 'Trazodone (Desyrel)',
      name: 'Trazodone (Desyrel)'
    },
    {
      title: 'Mirtazapine (Remeron)',
      name: 'Mirtazapine (Remeron)'
    },
    {
      title: 'Hydroxyzine (Vistaril)',
      name: 'Hydroxyzine (Vistaril)'
    },
    {
      title: 'Other',
      name: 'Other'
    }
  ],
  optional: false,
  question_type: 'branch_checkbox_details',
  save_answer: true
};

const sameMeds = {
  name: 'same_med',
  title: 'Do you want to continue using the same medication?',
  description: '',
  flag_title: "Please specify medication dosage and elaborate on why it didn't work for you.",
  options: [
    {
      title: 'Yes, continue',
      name: 'Yes, continue'
    },
    {
      title: 'No, I want a different medication',
      name: 'No, I want a different medication'
    }
  ],
  optional: false,
  question_type: 'medication_selector',
  save_answer: true
};

export { prescriptionProof, selectPlan, lastQuestions, phoneConfirmation, prvMeds, hhMeds, sameMeds };
