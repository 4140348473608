import React from 'react';
import { ProfileHolder } from './styled';

interface IWrapperProps {
  children: React.ReactNode;
  isNoPadding?: boolean;
}

export const Wrapper = ({ children, isNoPadding = false }: IWrapperProps) => (
  <ProfileHolder>{isNoPadding ? children : <div className="profile-main-container">{children}</div>}</ProfileHolder>
);
