import styled from 'styled-components/macro';

export const BodyContainer = styled.div`
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10% auto 0;
  text-align: center;
  max-width: 33em;
  @media (max-width: 600px) {
    max-width: 21em;
  }
  @media (max-width: 500px) {
    margin: 35% auto 1.5em;
  }
`;
