window.kameleoonQueue = window.kameleoonQueue || [];

const getVariantValue = (experimentName: string): string => {
  let variationIdResult = 'Reference';

  if (window.Kameleoon && experimentName) {
    // Make sure Kameleoon is loaded and active at this point
    const experiment = window.Kameleoon.API.Experiments.getByName(experimentName);
    if (!experiment) {
      variationIdResult = 'Disabled';
    } else if (experiment.associatedVariation.name === 'Excluded') {
      // The experiment is not currently live (not launched yet, or paused). The default behavior should take place
      variationIdResult = 'Excluded';
    } else {
      variationIdResult = experiment.associatedVariation.name;
    }
  }
  return variationIdResult || 'Reference';
};

const triggerEventKameleoon = (eventName: string): void => {
  window?.kameleoonQueue.push(['Events.trigger', eventName]);
};

const setCustomDataKameleoon = async (
  name: string,
  value: string | boolean | null,
  overwriteIfCollection?: boolean
): Promise<void> => {
  await window?.kameleoonQueue.push(['Data.setCustomData', name, value, overwriteIfCollection]);
};

const isExperimentActive = (experimentName: string): boolean => {
  if (window.Kameleoon && experimentName) {
    const experiment = window.Kameleoon.API.Experiments.getByName(experimentName);
    return Boolean(experiment?.active);
  }

  return false;
};

export { getVariantValue, triggerEventKameleoon, setCustomDataKameleoon, isExperimentActive };
