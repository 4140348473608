export const SET_APP_STATE = 'SET_APP_STATE';
export const SET_ENV = 'SET_ENV';

// https://redux.js.org/basics/actions#action-creators
export const set_app_state = state => ({
  type: SET_APP_STATE,
  new_app_state: state
});

export const set_app_env = env => ({
  type: SET_ENV,
  env
});

export const update_app_state = state => dispatch => {
  return dispatch(set_app_state(state));
};

export const set_env = env => dispatch => {
  return dispatch(set_app_env(env));
};
