export const formatPriceToNumber = (price: string, defaultPrice?: number): number | null => {
  if (!price || Array.isArray(price)) {
    return defaultPrice || null;
  }
  const parseNum = parseFloat(price);

  if (Number.isNaN(parseNum)) {
    return defaultPrice || null;
  }
  return Number(parseNum.toFixed(2));
};
