export const REDIRECT_LOCATIONS = [
  '/patient/service-lines',
  '/patient/change-plan',
  '/patient/schedule/therapist/schedule-th/45',
  '/patient/schedule/therapist/schedule-th/30',
  '/patient/schedule/provider/schedule-dr/0',
  '/patient/schedule/provider/schedule-dr/15',
  '/patient/schedule/provider/schedule-dr/30',
  '/patient/schedule/care_manager/schedule-cm/30',
  '/patient/dashboard/appointments',
  '/patient/dashboard',
  '/patient/dashboard/message',
  '/patient/dashboard/profile_info',
  '/patient/dashboard/prescription',
  '/patient/dashboard/result',
  '/patient/dashboard/canceled',
  '/patient/ilv/provider',
  '/patient/dashboard/update_visit_insurance_cards',
  '/patient/dashboard/update_insurance_cards',
  '/patient/dashboard/update_medication_insurance_cards',
  '/patient/reassign/prescriber',
  '/patient/reassign/provider',
  '/patient/reassign/therapist',
  '/patient/dashboard/reassignment/therapist/intro',
  '/patient/dashboard/reassignment/prescriber/intro',
  '/patient/dashboard/manage_payments',
  '/patient/dashboard/home/async-checkin/intro'
];
