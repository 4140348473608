import { useApi } from 'utils/api';
import { useQuery } from 'react-query';
import { useCallback, useMemo } from 'react';
import { SubscriptionV2 } from 'types/subscription';
import { StepdownPlan } from 'components/CancellationPlan/screens/CancelOffer/types';
import { SubscriptionsResponse, GroupPlans, SubscriptionDetailsResponse } from './types';

const hasStepdownSubscription = (subscriptions: SubscriptionV2[], stepdownPlans: StepdownPlan[]) => {
  const previousPlanPriceIds = subscriptions.map(subscription => subscription.plan?.plan_price?.plan_price_id);
  const stepdownPlanPriceIds = stepdownPlans
    .map(plan => plan.plan_prices.map(price => price.plan_price_id))
    .reduce((acc, val) => acc.concat(val), []);
  return stepdownPlanPriceIds.some(id => previousPlanPriceIds.includes(id));
};

export const useSubscriptionDetails = (patientId: string): SubscriptionDetailsResponse => {
  const api = useApi();

  const getSubscriptionsV2 = async (patient: string, showInactive = false): Promise<SubscriptionV2[]> => {
    const res = await api.get<SubscriptionsResponse>(
      `/api/v2/patients/${patient}/subscriptions?inactive=${showInactive}`
    );
    return res.data.patient_subscriptions;
  };

  const getPlanGroups = async (planGroupKey: string) => {
    return api
      .post<GroupPlans>(`/api/v2/plan_group/plans?plan_group_key=${planGroupKey}`, {
        params: { plan_group_key: planGroupKey }
      })
      .then(res => res.data);
  };

  const usePlanGroup = (planGroupKey: string) => {
    return useQuery(['usePlanGroup', planGroupKey], () =>
      getPlanGroups(planGroupKey).then(planGroup => planGroup.plans)
    );
  };

  const useSubscriptionV2 = (patient: string) => {
    const {
      data: subscriptionsV2,
      isLoading: isLoadingSubscriptionsV2,
      refetch: refetchSubscriptionV2
    } = useQuery(
      ['useSubscriptionV2(ActiveSubscrition)', patient],
      () => {
        if (patient) return getSubscriptionsV2(patient, false);
        return null;
      },
      { enabled: !!patient, refetchOnMount: 'always' }
    );

    const subscriptionV2 = subscriptionsV2?.[0];
    return {
      isLoading: isLoadingSubscriptionsV2,
      subscriptionV2,
      refetchSubscriptionV2,
      isStrongStart: !subscriptionV2?.plan.plan_price.recurring && !!subscriptionV2?.plan.plan_price.follow_up_price
    };
  };

  const { data: changeStepdownPlans, isLoading: isLoadingChangeStepdownPlans } = usePlanGroup('stepdown_plan_change');
  const { data: twoxBHPlans, isLoading: isLoadingTwoxBHPlans } = usePlanGroup('two_x_behavioral_health_package');
  const {
    subscriptionV2: currentSubscription,
    isLoading: isLoadingCurrentSubscription,
    isStrongStart,
    refetchSubscriptionV2
  } = useSubscriptionV2(patientId.toString());

  const {
    data: otherSubscriptions,
    isLoading: isLoadingOtherSubscriptions,
    refetch: refetchOtherSubscriptions
  } = useQuery(
    ['useSubscriptionDetails2 - otherSubscriptions', patientId],
    () => {
      if (patientId) return getSubscriptionsV2(patientId.toString(), true);
      return null;
    },
    { enabled: !!patientId }
  );

  const isLoading = useMemo(
    () =>
      [
        isLoadingChangeStepdownPlans,
        isLoadingCurrentSubscription,
        isLoadingOtherSubscriptions,
        isLoadingTwoxBHPlans
      ].some(Boolean),
    [isLoadingChangeStepdownPlans, isLoadingCurrentSubscription, isLoadingOtherSubscriptions, isLoadingTwoxBHPlans]
  );

  const isTwoxBHPlans = useMemo(() => {
    const twoxBHPlanPriceIds = twoxBHPlans?.map(plan => {
      if (['therapy', 'medication-therapy'].includes(plan.offering_key)) {
        return plan.plan_prices.map(price => price.plan_price_id);
      }
      return undefined;
    });
    return twoxBHPlanPriceIds?.some(ids => ids?.includes?.(currentSubscription?.plan?.plan_price?.plan_price_id ?? -1));
  }, [currentSubscription?.plan?.plan_price?.plan_price_id, twoxBHPlans]);

  const canStepdown = useMemo(() => {
    if (
      isLoading ||
      isTwoxBHPlans ||
      !currentSubscription?.plan?.offering_key?.includes?.('therapy') ||
      currentSubscription?.plan?.offering_key?.includes?.('couples') ||
      isStrongStart ||
      currentSubscription?.plan?.plan_price?.term_months > 1 ||
      (currentSubscription?.plan?.plan_price?.follow_up_price?.term_months ?? 0) > 1
    ) {
      return false;
    }

    const currentSubscriptionAsArray = currentSubscription ? [currentSubscription] : [];
    const activeAndPendingSubscriptions = [...currentSubscriptionAsArray, ...(otherSubscriptions ?? [])].filter(
      subscription => ['active', 'pending'].includes(subscription.status)
    );
    const stepdownPlans = changeStepdownPlans ?? [];

    return !hasStepdownSubscription(activeAndPendingSubscriptions, stepdownPlans);
  }, [otherSubscriptions, currentSubscription, changeStepdownPlans, isLoading, isStrongStart, isTwoxBHPlans]);

  const stepdownDiscount = useMemo((): number | undefined => {
    const currentPrice = currentSubscription?.plan?.plan_price?.price;
    const offeringKey = currentSubscription?.plan?.offering_key;
    if (isLoading || !currentSubscription || !changeStepdownPlans || !currentPrice) {
      return undefined;
    }

    const changePlan = changeStepdownPlans.find(plan => plan.offering_key === offeringKey);
    const planPrice = changePlan?.plan_prices?.filter(price => price.meta_data?.legacyPrice !== 'true')[0];

    if (planPrice?.price && typeof currentPrice === 'number' && typeof planPrice?.price === 'number') {
      const discount = currentPrice - planPrice.price;
      return discount > 0 ? discount : undefined;
    }

    return undefined;
  }, [currentSubscription, changeStepdownPlans, isLoading]);

  const isCurrentSubscriptionStepdown = useMemo(() => {
    if (isLoading) {
      return false;
    }

    const currentSubscriptionAsArray = currentSubscription ? [currentSubscription] : [];

    return isTwoxBHPlans || hasStepdownSubscription(currentSubscriptionAsArray, changeStepdownPlans ?? []);
  }, [currentSubscription, changeStepdownPlans, isLoading]);

  const refetch = useCallback(() => {
    refetchSubscriptionV2();
    refetchOtherSubscriptions();
  }, [refetchOtherSubscriptions, refetchSubscriptionV2]);

  return {
    isLoading,
    currentSubscription,
    isStrongStart,
    canStepdown,
    isCurrentSubscriptionStepdown,
    stepdownDiscount,
    refetch
  };
};
