import React from 'react';
import styled from 'styled-components/macro';

export const directionValues = ['row', 'column'] as const;
export const justifyContentValues = [
  'center',
  'end',
  'flex-start',
  'flex-end',
  'space-around',
  'space-between',
  'space-evenly',
  'start'
] as const;

export const alignItemValues = ['stretch', 'center', 'flex-start', 'flex-end', 'baseline'] as const;

export interface FlexboxProps {
  direction?: (typeof directionValues)[number];
  justifyContent?: (typeof justifyContentValues)[number];
  alignItems?: (typeof alignItemValues)[number];
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const FlexboxStyled = styled.div<FlexboxProps>`
  display: flex;
  flex-direction: ${({ direction = 'row' }: FlexboxProps) => direction};
  justify-content: ${({ justifyContent = 'center' }: FlexboxProps) => justifyContent};
  align-items: ${({ alignItems = 'center' }: FlexboxProps) => alignItems};
`;

const Flexbox = (props: FlexboxProps) => <FlexboxStyled {...props} />;

export default Flexbox;
