import React from 'react';
import Spinner from '../../Spinner';
import TimedModal from '../../TimedModal';

const TimedModalComponent = (): JSX.Element => (
  <div style={{ minHeight: '100vh' }} className="d-flex align-items-center justify-content-center">
    <Spinner size="2.0rem" borderWidth="3px" />
  </div>
);

const PageLoading = (): JSX.Element => {
  return <TimedModal component={TimedModalComponent} delay={200} />;
};

export default PageLoading;
