import React, { useEffect } from 'react';
import useIdle from 'react-use/lib/useIdle';
import Counter from '../Timer/Counter';
import { ModalWrapper, ModalWrapperInner, ModalBody, OkButton, Holder, Divider } from './styled';

const timeToIdle = Number(process.env.REACT_APP_AUTO_LOGOUT_DELAY) || 1000 * 60 * 14;

/*
/video-appointment
/immediate_visit_video
/appointment/:hash
exclude /appointment/:hash/edit
*/

export const showTimerModal = path => {
  if (['/appointment/', '/edit'].every(item => path.includes(item))) {
    return true;
  }

  return !['/appointment/', '/immediate_visit_video', '/video-appointment'].some(item => path.includes(item));
};

const TimerModal = ({ onLogout }) => {
  const [isIdle, setIsIdle] = React.useState(false);
  const idle = useIdle(timeToIdle);

  useEffect(() => {
    if (idle) {
      setIsIdle(true);
    }
  }, [idle]);

  const onStayClick = () => {
    setIsIdle(false);
  };

  const onLogoutClick = () => {
    setIsIdle(false);
    onLogout();
  };

  if (!isIdle) {
    return null;
  }

  return (
    <ModalWrapper zIndex="99">
      <ModalWrapperInner>
        <Counter onLogout={onLogout}>
          {counter => {
            return (
              <Holder>
                <ModalBody>You will be automatically log out after:</ModalBody>
                <ModalBody>{counter}</ModalBody>
                <Divider />
                <Holder row>
                  <OkButton center onClick={onStayClick}>
                    Stay
                  </OkButton>
                  <Divider />
                  <OkButton center onClick={onLogoutClick}>
                    Log out
                  </OkButton>
                </Holder>
              </Holder>
            );
          }}
        </Counter>
      </ModalWrapperInner>
    </ModalWrapper>
  );
};

export default TimerModal;
