export const getAuthorizationUrl = (
  domain: string,
  codeChallenge: string,
  clientId: string,
  redirectUrl: string,
  audience: string,
  state: string,
  nonce: string,
  screen_hint?: string
): string => {
  let url = `${domain}/authorize?response_type=code&code_challenge=${codeChallenge}&code_challenge_method=S256&client_id=${clientId}`;
  url += `&redirect_uri=${redirectUrl}&scope=openid profile offline_access&audience=${audience}`;
  url += `&state=${state}&nonce=${nonce}&prompt=login&screen_hint=${screen_hint}`;
  return url;
};

export const createRandomString = (): string => {
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.';
  let random = '';
  const randomValues = Array.from(window.crypto.getRandomValues(new Uint8Array(43)));
  randomValues.forEach(v => {
    random += charset[v % charset.length];
  });
  return random;
};

export const urlEncodeB64 = (input: string): string => {
  const b64Chars: { [index: string]: string } = { '+': '-', '/': '_', '=': '' };
  return input.replace(/[+/=]/g, (m: string) => b64Chars[m]);
};

export const sha256 = async (s: string): Promise<ArrayBuffer> => {
  return window.crypto.subtle.digest({ name: 'SHA-256' }, new TextEncoder().encode(s));
};

export const bufferToBase64UrlEncoded = (input: ArrayBuffer): string => {
  const ie11SafeInput = new Uint8Array(input);
  return urlEncodeB64(window.btoa(String.fromCharCode(...Array.from(ie11SafeInput))));
};

export const encode = (value: string): string => btoa(value);
