import React from 'react';
import styled from 'styled-components/macro';

const ParagraphsContainer = styled.div`
  & > p {
    margin-bottom: 0;
  }
  & > p:first-child {
    margin-bottom: 24px;
  }
  @media (max-width: 375px) {
    padding: 0 16px;
  }
`;

const LastParagraph = styled.span`
  @media (max-width: 500px) {
    display: block;
  }
`;

export const Paragraphs = (): JSX.Element => {
  return (
    <ParagraphsContainer>
      <p>We&apos;re upgrading and enhancing our site to ensure the best possible care experience for our members.</p>
      <p>
        This shouldn&apos;t take too long. <LastParagraph>Check back soon!</LastParagraph>
      </p>
    </ParagraphsContainer>
  );
};
