// https://stackoverflow.com/questions/28821804/how-can-i-quickly-determine-the-state-for-a-given-zipcode
interface State {
  min: number;
  max: number;
  abbreviation: string;
}

interface StateName {
  shortName: string;
  fullName: string;
}

const zipCodeToState = (zip: number): string | undefined => {
  const states: State[] = [
    { min: 35000, max: 36999, abbreviation: 'AL' },
    { min: 99500, max: 99999, abbreviation: 'AK' },
    { min: 85000, max: 86999, abbreviation: 'AZ' },
    { min: 71600, max: 72999, abbreviation: 'AR' },
    { min: 90000, max: 96699, abbreviation: 'CA' },
    { min: 80000, max: 81999, abbreviation: 'CO' },
    { min: 6000, max: 6999, abbreviation: 'CT' },
    { min: 19700, max: 19999, abbreviation: 'DE' },
    { min: 32000, max: 34999, abbreviation: 'FL' },
    { min: 30000, max: 31999, abbreviation: 'GA' },
    { min: 96701, max: 96797, abbreviation: 'HI' },
    { min: 96801, max: 96898, abbreviation: 'HI' },
    { min: 96799, max: 96799, abbreviation: 'AS' },
    { min: 83200, max: 83999, abbreviation: 'ID' },
    { min: 60000, max: 62999, abbreviation: 'IL' },
    { min: 46000, max: 47999, abbreviation: 'IN' },
    { min: 50000, max: 52999, abbreviation: 'IA' },
    { min: 66000, max: 67999, abbreviation: 'KS' },
    { min: 40000, max: 42999, abbreviation: 'KY' },
    { min: 70000, max: 71599, abbreviation: 'LA' },
    { min: 3900, max: 4999, abbreviation: 'ME' },
    { min: 20600, max: 21999, abbreviation: 'MD' },
    { min: 1000, max: 2799, abbreviation: 'MA' },
    { min: 48000, max: 49999, abbreviation: 'MI' },
    { min: 55000, max: 56999, abbreviation: 'MN' },
    { min: 38600, max: 39999, abbreviation: 'MS' },
    { min: 63000, max: 65999, abbreviation: 'MO' },
    { min: 59000, max: 59999, abbreviation: 'MT' },
    { min: 27000, max: 28999, abbreviation: 'NC' },
    { min: 58000, max: 58999, abbreviation: 'ND' },
    { min: 68000, max: 69999, abbreviation: 'NE' },
    { min: 88900, max: 89999, abbreviation: 'NV' },
    { min: 3000, max: 3899, abbreviation: 'NH' },
    { min: 7000, max: 8999, abbreviation: 'NJ' },
    { min: 87000, max: 88499, abbreviation: 'NM' },
    { min: 10000, max: 14999, abbreviation: 'NY' },
    { min: 43000, max: 45999, abbreviation: 'OH' },
    { min: 73301, max: 73301, abbreviation: 'TX' },
    { min: 73344, max: 73344, abbreviation: 'TX' },
    { min: 73960, max: 73960, abbreviation: 'TX' },
    { min: 73000, max: 74999, abbreviation: 'OK' },
    { min: 97000, max: 97999, abbreviation: 'OR' },
    { min: 15000, max: 19699, abbreviation: 'PA' },
    { min: 300, max: 999, abbreviation: 'PR' },
    { min: 2800, max: 2999, abbreviation: 'RI' },
    { min: 29000, max: 29999, abbreviation: 'SC' },
    { min: 57000, max: 57999, abbreviation: 'SD' },
    { min: 37000, max: 38599, abbreviation: 'TN' },
    { min: 75000, max: 79999, abbreviation: 'TX' },
    { min: 88500, max: 88599, abbreviation: 'TX' },
    { min: 84000, max: 84999, abbreviation: 'UT' },
    { min: 5000, max: 5999, abbreviation: 'VT' },
    { min: 20101, max: 20598, abbreviation: 'VA' },
    { min: 22003, max: 24658, abbreviation: 'VA' },
    { min: 20000, max: 20599, abbreviation: 'DC' },
    { min: 98000, max: 99499, abbreviation: 'WA' },
    { min: 24700, max: 26999, abbreviation: 'WV' },
    { min: 53000, max: 54999, abbreviation: 'WI' },
    { min: 82000, max: 83199, abbreviation: 'WY' }
  ];

  return states.find(({ min, max }) => zip >= min && zip <= max)?.abbreviation;
};

export const getFullnameState = (shortName: string): string | undefined => {
  const states: StateName[] = [
    { shortName: 'AL', fullName: 'Alabama' },
    { shortName: 'AK', fullName: 'Alaska' },
    { shortName: 'AZ', fullName: 'Arizona' },
    { shortName: 'AR', fullName: 'Arkansas' },
    { shortName: 'CA', fullName: 'California' },
    { shortName: 'CO', fullName: 'Colorado' },
    { shortName: 'CT', fullName: 'Connecticut' },
    { shortName: 'DE', fullName: 'Delaware' },
    { shortName: 'FL', fullName: 'Florida' },
    { shortName: 'GA', fullName: 'Georgia' },
    { shortName: 'HI', fullName: 'Hawaii' },
    { shortName: 'AS', fullName: 'American Samoa' },
    { shortName: 'ID', fullName: 'Idaho' },
    { shortName: 'IL', fullName: 'Illinois' },
    { shortName: 'IN', fullName: 'Indiana' },
    { shortName: 'IA', fullName: 'Iowa' },
    { shortName: 'KS', fullName: 'Kansas' },
    { shortName: 'KY', fullName: 'Kentucky' },
    { shortName: 'LA', fullName: 'Louisiana' },
    { shortName: 'ME', fullName: 'Maine' },
    { shortName: 'MD', fullName: 'Maryland' },
    { shortName: 'MA', fullName: 'Massachusetts' },
    { shortName: 'MI', fullName: 'Michigan' },
    { shortName: 'MN', fullName: 'Minnesota' },
    { shortName: 'MS', fullName: 'Mississippi' },
    { shortName: 'MO', fullName: 'Missouri' },
    { shortName: 'MT', fullName: 'Montana' },
    { shortName: 'NC', fullName: 'North Carolina' },
    { shortName: 'ND', fullName: 'North Dakota' },
    { shortName: 'NE', fullName: 'Nebraska' },
    { shortName: 'NV', fullName: 'Nevada' },
    { shortName: 'NH', fullName: 'New Hampshire' },
    { shortName: 'NJ', fullName: 'New Jersey' },
    { shortName: 'NM', fullName: 'New Mexico' },
    { shortName: 'NY', fullName: 'New York' },
    { shortName: 'OH', fullName: 'Ohio' },
    { shortName: 'OK', fullName: 'Oklahoma' },
    { shortName: 'OR', fullName: 'Oregon' },
    { shortName: 'PA', fullName: 'Pennsylvania' },
    { shortName: 'PR', fullName: 'Puerto Rico' },
    { shortName: 'RI', fullName: 'Rhode Island' },
    { shortName: 'SC', fullName: 'South Carolina' },
    { shortName: 'SD', fullName: 'South Dakota' },
    { shortName: 'TN', fullName: 'Tennessee' },
    { shortName: 'TX', fullName: 'Texas' },
    { shortName: 'UT', fullName: 'Utah' },
    { shortName: 'VT', fullName: 'Vermont' },
    { shortName: 'VA', fullName: 'Virginia' },
    { shortName: 'DC', fullName: 'District of Columbia' },
    { shortName: 'WA', fullName: 'Washington' },
    { shortName: 'WV', fullName: 'West Virginia' },
    { shortName: 'WI', fullName: 'Wisconsin' },
    { shortName: 'WY', fullName: 'Wyoming' }
  ];
  return states.find(item => item.shortName === shortName)?.fullName;
};

export default zipCodeToState;
