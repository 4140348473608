export const REFERENCE = 'Reference';
export const FIRST_AD_HOC_THERAPY = 'first-ad-hoc-therapy';
export const PLAN_SELECTION = 'plan_selection';
export const MAT_COUNSELING = 'mat-counseling';
export const MEDICATION_THERAPY = 'medication-therapy';
export const MEDICATION = 'medication';
export const THERAPY = 'therapy';
export const COUPLES_THERAPY = 'couples_therapy';
export const SIGN_UP_BUTTON_TEXT = 'Continue';
export const CARE_COUNSELING_PLAN_NAME = 'Care Counseling';
export const MEDICATION_PLAN_TITLE = 'Medication';
export const MEDICATION_PLAN_NAME = 'Medication';
export const THERAPY_MEDICATION_PLAN_TITLE = 'Therapy +\nMedication';
export const THERAPY_MEDICATION_PLAN_NAME = 'Therapy + Medication';
export const THERAPY_PLAN_TITLE = 'Therapy';
export const THERAPY_PLAN_NAME = 'Therapy';
export const MEDICATION_COACHING_PLAN_NAME = 'Medication + Coaching';
export const COACHING_PLAN_TITLE = 'Coaching';
export const START_TODAY_BUTTON_TEXT = 'Start Today';
export const MEDICATION_MANAGEMENT = 'Medication Management';
export const MEDICATION_TREATMENT_PLAN_NAME = 'Medication-Assisted Treatment + Care Counseling';
export const START_THERAPY_MEDICATION_BUTTON_TEXT = 'Start Therapy + Meds Today';
export const CONTINUE = 'Continue';
export const MEDICARE_CHECKBOX_TEXT =
  'I confirm I am not enrolled in any state or federal insurance program, such as Medicare, Medicaid, ' +
  'or Veterans Affairs and understand I cannot use Cerebral at all if I am.';
