import Cookies from 'js-cookie';

export class PromoCode {
  private key = 'promo';

  private searchParams = new URLSearchParams(window.location?.search);

  public setKey(key: string) {
    this.key = key;
  }

  public getKey() {
    return this.key;
  }

  public setCodeFromQueryString(): void {
    if (this.searchParams.has(this.key)) {
      Cookies.set(this.key, this.searchParams.get(this.key) || '', { expires: 1 });
    }
  }

  public getCode(): string | null {
    let promoCode = null;
    const cookiePromoCode = Cookies.get(this.key);

    if (cookiePromoCode) {
      promoCode = cookiePromoCode;
    } else if (this.searchParams.has(this.key)) {
      promoCode = this.searchParams.get(this.key);
    }

    return promoCode as string;
  }
}
