import React, { useState, useEffect } from 'react';
import { NB, ChoicePill } from '@cerebral-inc/ui';
import { Question } from 'types/question';
import segment from 'utils/segment';
import { OnboardingButton } from 'components/Onboarding/shared';
import { getSegmentEvent, getSubmitSegmentEvent } from '../../helpers';
import { iterableIntegrations, segmentIntegrations } from '../../utils';
import { EmergencySituation } from '../../PreConversion/Popups/EmergencySituation';
import { OnboardingHeight } from '../constants/constants';

const { Center, Text, Heading, Flex, Box, Button } = NB;

export interface IMultiSelectProps {
  question: Question;
  submitAnswer: (answer: string, question: Question, skip?: number) => void;
}

export const MultipleSelector = ({ question, submitAnswer }: IMultiSelectProps) => {
  const [answers, setAnswers] = useState<string[]>([]);
  const [isEmergencySituationOpen, setIsEmergencySituationOpen] = useState(false);
  const { title, options, subscript, name } = question;

  useEffect(() => {
    const segmentName = getSegmentEvent(name);
    if (segmentName) segment.track(segmentName, undefined, segmentIntegrations);
  }, []);

  const handleAnswerClick = (clickedAnswer: string) => {
    if (answers.includes(clickedAnswer)) {
      setAnswers(currentAnswers => currentAnswers.filter(answer => clickedAnswer !== answer));
    } else {
      setAnswers(currentAnswers => [...currentAnswers, clickedAnswer]);
    }
  };

  const handleSubmit = () => {
    const finalAnswers = answers.join('|');
    submitAnswer(finalAnswers, question);
    const eventName = getSubmitSegmentEvent(name);
    if (eventName) segment.track(eventName, { answered: finalAnswers }, iterableIntegrations);
  };

  return (
    <>
      <EmergencySituation isOpen={isEmergencySituationOpen} onClose={() => setIsEmergencySituationOpen(false)} />
      <Center>
        <Flex minH={{ base: OnboardingHeight, md: 'auto' }} px={{ base: '16px', md: '0' }}>
          <Center>
            <Heading
              fontFamily="Poppins"
              fontSize={{ base: '20px', sm: '22px' }}
              fontWeight="600"
              lineHeight="24px"
              marginBottom="1"
              color="#1c1e21"
            >
              {title}
            </Heading>
            <Text fontFamily="Poppins" fontWeight="400" marginBottom="2" color="#353A41" lineHeight="24px">
              {subscript}
            </Text>
            <Text
              fontFamily="Poppins"
              fontSize="12px"
              fontWeight="400"
              fontStyle="italic"
              marginBottom="5"
              color="#626874"
              lineHeight="16px"
            >
              Select all that apply
            </Text>
          </Center>
          <Flex direction="row" justify="center" maxWidth="2xl" width="full" flexWrap="wrap" marginBottom="2">
            {options.map(option => (
              <Box margin="4px">
                <ChoicePill
                  onSelect={() => handleAnswerClick(option.name)}
                  choicePillKey={option.name}
                  choicePillType="multiple"
                  selected={answers.includes(option.name)}
                  labelText={option.name}
                  fullWidth
                />
              </Box>
            ))}
          </Flex>
          <Box maxWidth="md" width="full" mx="auto">
            <Button
              marginTop="5"
              variant="tertiary"
              size="lg"
              marginBottom="2"
              onPress={() => setIsEmergencySituationOpen(true)}
              _text={{
                fontFamily: 'Poppins'
              }}
              fontWeight="600"
            >
              I&apos;m having an emergency situation
            </Button>
          </Box>
        </Flex>
        <OnboardingButton isDisabled={answers.length === 0} text="Continue" onSubmit={handleSubmit} isStickyButton />
      </Center>
    </>
  );
};
