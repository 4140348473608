import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ApiContext } from '../../utils/api';

const Method = styled.select``;

const Path = styled.input``;

const Payload = styled.textarea`
  display: block;
`;

const Submit = styled.button``;

const Response = styled.textarea`
  display: block;
`;

const Where = styled.div`
  display: flex;
  ${Path} {
    flex: 1;
  }
`;

const Fields = styled.div`
  padding: 15px;
  ${Method} {
    margin-right: 10px;
  }
  ${Payload} {
    margin-top: 10px;
    display: block;
    width: 100%;
  }

  ${Submit} {
    margin-top: 20px;
  }

  ${Response} {
    margin-top: 20px;
    display: block;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.1);
`;

const methods = ['GET', 'POST', 'PUT'];

export default function ApiTester() {
  const [method, setMethod] = useState('GET');
  const [path, setPath] = useState('/');
  const [payload, setPayload] = useState('{}');
  const api = useContext(ApiContext);
  const [response, setResponse] = useState('');
  const [sending, setSending] = useState(false);
  const [visible, setVisible] = useState(false);
  const [valid, setValid] = useState(true);

  useEffect(() => {
    const onKeyDown = e => {
      if (e.keyCode === 192) {
        setVisible(v => !v);
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  useEffect(() => {
    try {
      if (method !== 'GET') {
        JSON.parse(payload);
      }
      setValid(true);
    } catch (e) {
      setValid(false);
    }
  }, [payload, method]);

  const onSubmitClick = async () => {
    setSending(true);
    setResponse('');
    try {
      const args = [path];

      if (method === 'POST' || method === 'PUT') {
        args.push(JSON.parse(payload));
      }

      const result = await api[method.toLowerCase()](...args);
      const { data } = result;

      setResponse(JSON.stringify(data));
    } catch (e) {
      setResponse(JSON.stringify(e));
    }
    setSending(false);
  };

  if (!visible) {
    return null;
  }

  const renderSubmitButtonText = () => {
    if (valid) {
      if (sending) {
        return 'Sending';
      }
      return 'Submit';
    }
    return 'Invalid Payload';
  };

  return (
    <Wrapper>
      <Fields>
        <Where>
          <Method value={method} onChange={e => setMethod(e.target.value)}>
            {methods.map(m => (
              <option value={m} key={m}>
                {m}
              </option>
            ))}
          </Method>
          <Path value={path} onChange={e => setPath(e.target.value)} />
        </Where>

        {method !== 'GET' && <Payload value={payload} onChange={e => setPayload(e.target.value)} />}

        <Submit type="button" onClick={onSubmitClick} disabled={!valid}>
          {renderSubmitButtonText()}
        </Submit>

        {response !== '' && <Response value={response} readOnly rows="5" />}
      </Fields>
    </Wrapper>
  );
}
