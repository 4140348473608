import { useState, useEffect } from 'react';

const Counter = ({ onLogout, children }) => {
  const [counter, setCounter] = useState(60);

  useEffect(() => {
    let timer = null;

    if (counter > 0) {
      timer = setTimeout(() => {
        setCounter(c => c - 1);
      }, 1000);
    } else {
      onLogout();
    }

    return () => {
      clearTimeout(timer);
    };
  }, [counter]);

  return children(counter);
};

export default Counter;
