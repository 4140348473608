import {
  MEDICATION_PLAN_NAME,
  MEDICATION_PLAN_TITLE,
  THERAPY_MEDICATION_PLAN_TITLE,
  THERAPY_MEDICATION_PLAN_NAME,
  START_THERAPY_MEDICATION_BUTTON_TEXT
} from 'constants/textConstants';
import createPricing from './createPricing';
import { IStrings } from './types';
import { USPaymentTerm, USSignupTerm, USPaymentNonTherapyTerm } from '../components/ImportantInformation/Terms';

const discountedTherapyPrice = 99;

export default {
  nationalSuicideHotlineName: 'National Suicide Hotline',
  nationalSuicideHotlineNumber: '988',
  nationalEmergencyNumber: '911',
  currencySymbol: '$',
  assessmentFee: 15,
  nutritionManagementUpsellPriceDefault: 69,
  nutritionManagementPriceDefault: 69,
  nutritionMedicationUpsellPriceDefault: 85,
  nutritionMedicationPriceDefault: 85,
  nutritionMedicationGlp1UpsellPriceDefault: 120,
  nutritionMedicationGlp1PriceDefault: 120,
  conselingUpgradePrice: 30,
  nutritionTherapyUpgradePrice: 99,
  smokingAssessmentUpgradePrice: 40,
  medsTherapyCta: 'Start Today',
  medsTherapyCtaChoose: START_THERAPY_MEDICATION_BUTTON_TEXT,
  matCounselingCta: 'Start MAT Today',
  medicationTherapyPrices: createPricing(325, 139),
  medicationTherapy365Prices: createPricing(365, 139),
  medicationTherapy365AndFirstMonth175Prices: createPricing(365, 175),
  medicationNicotinePrices: createPricing(40, 28),
  medicationBupropionPrices: createPricing(40, 17),
  medicationBupropionNicotinePrices: createPricing(80, 40),
  medicationManagementTXCAPlan: createPricing(50, 25),
  medicationManagementNYFLPlan: createPricing(85, 30),
  therapyPrices: createPricing(259, discountedTherapyPrice),
  therapy295Prices: createPricing(295, 99),
  therapy295AndFirstMonth145Prices: createPricing(295, 145),
  adHocTherapyPrices: createPricing(105, 65),
  discountedTherapyPrice,
  medicationPrices: createPricing(85, 30),
  medication30Price: 30,
  medication49Price: 49,
  medication79Price: 79,
  medication99Prices: createPricing(99, 30),
  nutritionPrices: createPricing(69, 25),
  nutritionMedicationPrices: createPricing(85, 30),
  nutritionMedicationGlp1Prices: createPricing(120, 45),
  nutritionMedicationGlp1TherapyPrices: createPricing(379, 144),
  matCounselingPrices: createPricing(195, 195),
  medsPlanTitle: {
    title: MEDICATION_PLAN_TITLE,
    name: MEDICATION_PLAN_NAME
  },
  medsTherapyPlanTitle: {
    title: THERAPY_MEDICATION_PLAN_TITLE,
    name: THERAPY_MEDICATION_PLAN_NAME
  },
  matPlanTitle: {
    title: 'Medication-Assisted Treatment\n+ Care Counseling',
    name: 'Medication Assisted Treatment + Care Counseling'
  },
  prescriberText: 'prescriber',
  defaultSubpriceMessage: 'Billed monthly <br />FSA / HSA eligible<br /> Cancel anytime',
  weeklyVideoPhone: 'Weekly video/phone sessions with a licensed therapist',
  regularVideo: 'Regular video sessions with an assigned care counselor',
  defaultPromoCode: 'CARE30',
  defaultMedicationManagementNYAndFL: '55OFF',
  defaultMedicationManagementTXAndCA: '25OFF',
  defaultSMCampaignCode: 'SIMONE',
  defaultTherapyCode: 'START99',
  defaultTherapy99Code: '99START',
  defaultTherapy145Code: 'START145',
  defaultAdHocTherapyCode: 'START99',
  defaultAdHocTherapyCodeOneTime: 'TRYTHERAPY65',
  defaultTherapyCampaignCode: 'CARE79',
  defaultMedsTherapyCampaignCode: 'CARE99',
  defaultMedsTherapyCode: 'START139',
  defaultMedsTherapy139Code: '139START',
  defaultMedsTherapy175Code: 'START175',
  defaultNicotineCode: '12OFF',
  defaultBupropionCode: '23OFF',
  defaultBupropionNicotineCode: '40OFF',
  defaultNicotineCounselingCode: '52OFF',
  defaultBupropionCounselingCode: '63OFF',
  defaultBupropionNicotineCounselingCode: '80OFF',
  defaultNicotineCodePrice: 12,
  defaultBupropionCodePrice: 23,
  defaultBupropionNicotineCodePrice: 40,
  defaultNicotineCounselingCodePrice: 52,
  defaultBupropionCounselingCodePrice: 63,
  defaultBupropionNicotineCounselingCodePrice: 80,
  defaultMedicationAdhd115: 'CARE41',
  defaultMedicationAdhd135: 'CARE48',
  defaultMedicationBipolar115: 'START41',
  defaultMedicationBipolar135: 'START48',
  defaultMed99: 'CEREBRAL30',
  defaultMed109: 'CERE30',
  defaultMed119: 'CEREB30',
  defaultMedsTherapy345: 'CERE139',
  defaultMedsTherapy365: 'CEREB139',
  defaultTherapy275: 'CERE99',
  defaultTherapy290: 'CEREB99',
  defaultTherapyCampaignPrice: 79,
  defaultMedsTherapyCampaignPrice: 99,
  defaultMedsTherapyPrice: 139,
  defaultAssessmentFeeAddonCode: 'assessmentfee-15',
  defaultNutritionCode: '44OFF',
  defaultNutritionMedicationCode: '55OFF',
  defaultNutritionMedicationGlp1Code: '75OFF',
  defaultNutritionManagementMMCounselingCode: '99OFF',
  defaultNutritionMMGlp1Therapy: '235OFF',
  defaultNutritionManagementMMTherapy: '230OFF',
  defaultNutritionManagementTherapy: '204OFF',
  defaultNutritionMedicationGlp1CodePrice: 75,
  defaultNutritionCodePrice: 44,
  defaultNutritionMedicationCodePrice: 55,
  defaultMedicationManagementNYAndFLPrice: 55,
  defaultMedicationManagementTXAndCAPrice: 25,
  defaultMedicationCoachingCode: 'COACH79',
  defaultCoachingCode: 'COACH59',
  defaultMedicationCoachingAbtestCode: 'COACH121',
  defaultCoachingAbtestCode: 'COACH90',
  defaultOudCode: ' ',
  countryCode: 'US',
  infoScreeningMessage:
    'The following questions serve as a preliminary screen to establish whether medication is the right course of treatment for you.',
  zipCodeLength: 5,
  zipCodeParser: /\D/g,
  phoneRegion: 'US',
  subscriptionStripeApi: '/api/payments/stripes/subscription_info',
  paymentStripeApi: '/api/payments/stripes/payment_info',
  PaymentTerms: USPaymentTerm,
  SignupTerms: USSignupTerm,
  checkoutTitle: 'Your Cerebral Membership',
  checkoutTrialTitle: 'Your Cerebral Trial',
  meditationMonthlyUpsellPriceDefault: 9.99,
  meditationMonthlyPriceDefault: 9.99,
  meditationMonthlyPrices: createPricing(9.99, 9.99),
  meditationYearlyUpsellPriceDefault: 59.94,
  meditationYearlyPriceDefault: 59.94,
  meditationYearlyPrices: createPricing(4.99, 4.99, 59.94, 59.94),
  defaultMeditationMonthlyCode: 'MEDIT10',
  defaultMeditationYearlyCode: 'MEDIT5',
  meditationMonthlyBUpsellPriceDefault: 4.99,
  meditationMonthlyBPriceDefault: 4.99,
  meditationMonthlyBPrices: createPricing(4.99, 4.99),
  meditationYearlyBUpsellPriceDefault: 29.94,
  meditationYearlyBPriceDefault: 29.94,
  meditationYearlyBPrices: createPricing(4.99, 4.99, 29.94, 29.94),
  defaultMeditationMonthlyBCode: 'MEDIT10',
  defaultMeditationYearlyBCode: 'MEDIT5',
  defaultMedication99Code: 'MENTALHEALTH49',
  defaultMedication99Code79: 'MENTALHEALTH79',
  defaultMedication99Code49: 'MENTALHEALTH49',
  checkoutOneTimeTitle: 'Your Cerebral Purchase',
  PaymentNonTherapyTerms: USPaymentNonTherapyTerm,
  serviceLinePopup: {
    title: 'Why do we ask this?',
    content: `We'll give you a customized assessment based on the condition(s) you select. If your condition isn't
    listed, or if you don't have a diagnosed condition, you can select "Other" or "I'm not sure." We'll ask you a few
    additional questions to get a clearer picture of your mental health experience and care needs.`
  },
  liveEventPopup: {
    title: 'Why do we ask this?',
    content: `Big changes or stressful life events can have an impact on your mental health. Knowing a bit more about
    what's going on in your life can help better prepare our care team members for your first appointment.`
  },
  assessmentResultPopup: {
    title: 'What do these results mean?',
    content: `Your assessment responses were scored to determine the initial results provided here. Note that we are
    not officially diagnosing you with anything at this point in time. If you'd like to receive an official mental
    health diagnosis, you can do so by completing a full evaluation with a Cerebral clinician.`
  },
  medsPreference: {
    title: 'Why do we ask this?',
    content: `Knowing your medication preferences and history will help our clinicians better prepare for your first appointment.<br/><br/>
    Ultimately, you will work with your care team to figure out if medication is right for you.
    Our clinicians provide all clients education surrounding the use of medication as a treatment protocol,
    as well as make clinically appropriate recommendations based on your symptom severity.`
  },
  willingnessToPay: {
    title: 'Why do we ask this?',
    content: `Treatment plans at Cerebral vary in price. This is mostly due to differences in levels of clinician
    licensure, as well as frequency of clinician visits per month.<br/><br/> Additionally, Cerebral operates under a subscription
    model with a first month discount rate. That means that the price of your plan and subscription per month will
    increase after your first month ends.<br/><br/><br/>`
  },
  purpose: {
    title: 'Why do we ask this?',
    content: `We want to understand your treatment goals. If seeking a diagnosis, this assessment will be used to help
    match your symptoms to one or more mental health conditions. Your care team will receive those results and provide
    a full evaluation during your first appointment.<br/><br/>
    If seeking continued care, this assessment will inform your care team about your medical and mental health history.
    They’ll use this to work with you and make any adjustments to your current treatment regime (if appropriate).`
  }
} as IStrings;
