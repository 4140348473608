export const segmentIntegrations = {
  integrations: {
    All: false,
    Iterable: true,
    Heap: true
  }
};

export const iterableIntegrations = {
  integrations: {
    All: false,
    Iterable: true
  }
};
