import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import { batch } from 'react-redux';
import queryString, { parse } from 'query-string';
import { API_PATHS } from '../types/api-path';
import stringify from '../utils/stringify';
import segment from '../utils/segment';
import { isVisitCompleted } from '../utils/common';
import { getInternalQuestionBanks, getQuestionsForInternalBanks } from '../utils/internal_question_banks';
import tracking from '../utils/tracking';
import create_visit from './patientActions/createVisit';
import * as global_actions from './user_auth_action';
import get_current_patient from './patientActions/getCurrentPatient';
import set_question_banks from './patientActions/setQuestionBanks';
import set_visit from './patientActions/setVisit';
import { setCustomDataKameleoon } from '../utils/kameleoon';

const { get_user_attr, make_headers } = global_actions;

export const SET_STEP = 'patient/SET_STEP';
export const SET_COUPON = 'patient/SET_COUPON';
export const SET_IS_PAYMENT_SUBMITTED = 'patient/IS_PAYMENT_SUBMITTED';
export const SET_VISIT_IS_COMPLETE = 'patient/SET_VISIT_IS_COMPLETE';
export const SET_VISIT_TYPE = 'patient/SET_VISIT_TYPE';
export const RESET_STATE = 'RESET';
export const RESET_QUESTIONS_AND_VISIT = 'patient/iRESET_QUESTIONS_AND_VISIT';
export const SET_PATIENT_STATE = 'patient/SET_STATE';
export const SET_STATE_WITH_STEP = 'patient/SET_STATE_WITH_STEP';
export const SET_QUESTION_BANKS_STEP = 'patient/SET_QUESTION_BANKS_STEP';
export const SET_PATIENT_QUESTIONS = 'patient/SET_PATIENT_QUESTIONS';
export const SET_CURRENT_PATIENT_PATH = 'patient/SET_CURRENT_PATIENT_PATH';
export const SET_PENDING_UPDATE = 'patient/SET_PENDING_UPDATE';
export const SET_SERVICE_LINE = 'patient/SET_SERVICE_LINE';
export const REMOVE_PATIENT_QUESTIONS = 'patient/REMOVE_PATIENT_QUESTIONS';
export const REMOVE_PATIENT_QUESTION_BANKS = 'patient/REMOVE_PATIENT_QUESTION_BANKS';
export const SET_BRANCH_QUESTION = 'patient/SET_BRANCH_QUESTION';
export const SET_BRANCH_QUESTION_STEP = 'patient/SET_BRANCH_QUESTION_STEP';
export const SET_BRANCH_QUESTION_ACTIVE = 'patient/SET_BRANCH_QUESTION_ACTIVE';
export const ADD_ADDITIONAL_QUESTIONS_TO_CURRENT_BANK = 'patient/ADD_ADDITIONAL_QUESTIONS_TO_CURRENT_BANK';
export const SET_BANK_STEP_AND_QUESTION_STEP = 'patient/SET_BANK_STEP_AND_QUESTION_STEP';
export const SET_GLOBAL_PROGRESSBAR_WIDTH = 'patient/SET_GLOBAL_PROGRESSBAR_WIDTH';
// step 2..9

export const SET_PATIENT = 'patient/SET_PATIENT';
export const SET_SCORE = 'patient/SET_SCORE';
export const SET_FOLLOWUP_VISIT = 'patient/SET_FOLLOWUP_VISIT';
export const SET_CARE_MANAGER = 'patient/SET_CARE_MANAGER';
export const SET_CARE_COORDINATOR = 'patient/SET_CARE_COORDINATOR';
export const SET_THERAPIST = 'patient/SET_THERAPIST';
export const SET_PROVIDER = 'SET_PROVIDER';
export const REMOVE_PROVIDER = 'REMOVE_PROVIDER';
export const SET_PENDING_PLAN_CHANGE = 'patient/SET_PENDING_PLAN_CHANGE';
export const SET_CARE_MANAGER_AVAILABILITY = 'patient/SET_CARE_MANAGER_AVAILABILITY';
export const REMOVE_VISIT = 'patient/REMOVE_VISIT';
export const SET_TREATMENT = 'patient/SET_TREATMENT';
export const SET_TREATMENT_OBJECT = 'patient/SET_TREATMENT_OBJECT';
export const SET_DOSAGE = 'patient/SET_DOSAGE';

export const SET_PATIENT_TASKS = 'patient/SET_TASKS';
export const SET_PATIENT_STATUS = 'SET_PATIENT_STATUS';
export const SET_ELIGIBLE_SERVICE_LINES = 'patient/SET_ELIGIBLE_SERVICE_LINES';
export const UPDATE_TASK = 'patient/UPDATE_TASK';
export const UPDATE_PATIENT_COMMUNICATION_FOLLOWUP_APPOINTMENT =
  'patient/UPDATE_PATIENT_COMMUNICATION_FOLLOWUP_APPOINTMENT';
export const SET_VISITS_FOR_PATIENT = 'patient/SET_VISITS_FOR_PATIENT';

export const SET_PATIENTS_APPOINTMENT = 'patient/SET_PATIENTS_APPOINTMENT';
export const REMOVE_APPOINTMENT_BY_ID = 'patient/REMOVE_APPOINTMENT_BY_ID';
export const SET_AVAILABLE_DATES = 'patient/SET_AVAILABLE_DATES';
export const SET_AVAILABLE_TIME_SLOTS = 'patient/SET_AVAILABLE_TIME_SLOTS';
export const SET_TIME_SLOT = 'patient/SET_TIME_SLOT';

export const GET_AVAILABLE_DATES_FETCH = 'patient/GET_AVAILABLE_DATES_FETCH';
export const GET_AVAILABLE_DATES_SUCCESS = 'patient/GET_AVAILABLE_DATES_SUCCESS';
export const GET_AVAILABLE_DATES_ERROR = 'patient/GET_AVAILABLE_DATES_ERROR';

export const GET_PRESCRIPTIONS_FETCH = 'patient/GET_PRESCRIPTIONS_FETCH';
export const GET_PRESCRIPTIONS_SUCCESS = 'patient/GET_PRESCRIPTIONS_SUCCESS';
export const GET_PRESCRIPTIONS_ERROR = 'patient/GET_PRESCRIPTIONS_ERROR';
export const SET_PRESCRIPTIONS = 'patient/SET_PRESCRIPTIONS';

export const INIT_CANCELLATION_FLOW = 'patient/INIT_CANCELLATION_FLOW';

export const CANCEL_SUBSCRIPTION_START = 'patient/CANCEL_SUBSCRIPTION_START';
export const CANCEL_SUBSCRIPTION_SUCESS = 'patient/CANCEL_SUBSCRIPTION_SUCESS';
export const CANCEL_SUBSCRIPTION_FINISHED = 'patient/CANCEL_SUBSCRIPTION_FINISHED';

export const APPLY_COUPON_START = 'patient/APPLY_COUPON_START';
export const APPLY_COUPON_SUCESS = 'patient/APPLY_COUPON_SUCESS';
export const APPLY_COUPON_FINISHED = 'patient/APPLY_COUPON_FINISHED';
export const SET_CONTROLLED_SUBSTANCE_REQUESTED = 'patient/SET_CONTROLLED_SUBSTANCE_REQUESTED';
export const SET_PLAN = 'patient/SET_PLAN';
export const SET_PLAN_GROUP_PLAN = 'patient/SET_PLAN_GROUP_PLAN';
export const SET_COMMUNICATION_PREFERENCES = 'patient/SET_COMMUNICATION_PREFERENCES';
export const SET_PRESCRIPTION_DELIVERY_METHOD = 'patient/SET_PRESCRIPTION_DELIVERY_METHOD';
export const SET_INSURANCE_STATUS = 'patient/SET_INSURANCE_STATUS';
export const SET_NEXT_RATING = 'patient/SET_NEXT_RATING';
export const SET_TERMS_VERSION_UPDATED = 'patient/SET_TERMS_VERSION_UPDATED';

export const SET_ABTEST = 'patient/SET_ABTEST';
export const SET_CAPACITY_CHECK = 'patient/SET_CAPACITY_CHECK';
export const OUTSTANDING_TASK_MODAL_CLOSED = 'patient/OUTSTANDING_TASK_MODAL_CLOSED';
export const NEW_APPOINTMENT_MODAL_CLOSED = 'patient/NEW_APPOINTMENT_MODAL_CLOSED';
export const SET_INSURANCE_VISITS_POLICY = 'patient/SET_INSURANCE_VISITS_POLICY';
export const SET_INSURANCE_MEDICATIONS_POLICY = 'patient/SET_INSURANCE_MEDICATIONS_POLICY';
export const REMOVE_QUESTION_BANK = 'patient/REMOVE_QUESTION_BANK';
export const SET_DIRECT_MESSAGING_PILOT = 'patient/SET_DIRECT_MESSAGING_PILOT';
export const SET_REGION = 'patient/SET_REGION';
export const SET_OFFERING_KEY = 'patient/SET_OFFERING_KEY';
export const SET_MEDICATION_PHOTOS = 'patient/SET_MEDICATION_PHOTOS';
export const SET_INSURANCE_PHOTOS = 'patient/SET_INSURANCE_PHOTOS';
export const SET_SECOND_INSURANCE_PHOTOS = 'patient/SET_SECOND_INSURANCE_PHOTOS';
export const SET_FLOW_KIND = 'patient/SET_FLOW_KIND';
export const SET_ELIGIBLE = 'patient/SET_ELIGIBLE';
export const SET_IS_MEDICARE_SECONDARY = 'patient/IS_MEDICARE_SECONDARY';
export const SET_PAYER_ONBOARDING = 'patient/SET_PAYER_ONBOARDING';
export const SET_PAYER_NOT_IN_LISTED = 'patient/SET_PAYER_NOT_IN_LISTED';
export const SET_MIGRATION_STATUS = 'patient/SET_MIGRATION_STATUS';
export const SET_LASTEST_MIGRATION_STATUS = 'patient/SET_LASTEST_MIGRATION_STATUS';
export const SET_SERVICE_LINE_NOT_AVAILABLE = 'patient/SET_SERVICE_LINE_NOT_AVAILABLE';
export const SET_IS_ONLINE_CHECKIN = 'patient/SET_IS_ONLINE_CHECKIN';
export const SET_INITIAL_SERVICE_LINE = 'patient/SET_INITIAL_SERVICE_LINE';
export const SET_INITIAL_PLAN = 'patient/SET_INITIAL_PLAN';
export const SET_CHARGE_ONE_TIME = 'patient/SET_CHARGE_ONE_TIME';
export const SET_CHANGING_TRIAL_PLAN = 'patient/SET_CHANGING_TRIAL_PLAN';
export const SET_IS_MEDICATION_SHIPPED = 'patient/SET_IS_MEDICATION_SHIPPED';
export const SET_MEDICATION_WAITLIST = 'patient/SET_MEDICATION_WAITLIST';
export const SET_UPGRADABLE_PLANS = 'patient/SET_UPGRADABLE_PLANS';
export const SET_MEDITATION_UPGRADE = 'patient/SET_MEDITATION_UPGRADE';
export const SET_ENTITLEMENTS = 'patient/SET_ENTITLEMENTS';
export const SET_POST_CHECKOUT_FLOW = 'patient/POST_CHECKOUT_FLOW';
export const SET_COMPARE_PLAN_OFFERING_KEY = 'patient/SET_COMPARE_PLAN_OFFERING_KEY';
export const SET_PAYMENT_SUBSCRIPTION = 'patient/PAYMENT_SUBSCRIPTION';
export const SET_CANCELLATION_APPOINTMENTS = 'patient/SET_CANCELLATION_APPOINTMENTS';
export const SET_CANCEL_NOTIFICATION_READ = 'patient/SET_CANCEL_NOTIFICATION_READ';
export const SET_INSURANCE_INFORMATION = 'patient/SET_INSURANCE_INFORMATION';
export const SET_REACTIVATION_VISIT = 'patient/SET_IS_REACTIVATION';
export const SET_ILV_STATUS = 'patient/SET_ILV_STATUS';
export const SET_ILV_PRESCRIBER_INFO = 'patient/SET_ILV_PRESCRIBER_INFO';
export const SET_SUBSCRIBED_AFTER_REMOVING_CARE_COUNSELOR = 'patient/SET_SUBSCRIBED_AFTER_REMOVING_CARE_COUNSELOR';
export const SET_REASSIGNMENT_REASON = 'patient/SET_REASSIGNMENT_REASON';
export const SET_PROVIDER_TO_REASSIGN = 'patient/SET_PROVIDER_TO_REASSIGN';
export const SET_SELECTED_PLAN_PRICE = 'patient/SET_SELECTED_PLAN_PRICE';
export const SET_PATIENT_PLAN_PRICE = 'patient/SET_PATIENT_PLAN_PRICE';
export const SET_PLAN_PRICE_SUBSCRIPTION = 'patient/SET_PLAN_PRICE_SUBSCRIPTION';

export const SET_SELECTED_PAYMENT_METHOD = 'patient/SET_SELECTED_PAYMENT_METHOD';

export const SET_ACCEPTED_HIPAA_AUTHORIZATION_AT = 'patient/SET_ACCEPTED_HIPAA_AUTHORIZATION_AT';
export const SET_HIPAA_AUTHORIZATION_STATUS = 'patient/SET_HIPAA_AUTHORIZATION_STATUS';

export const SET_PREFERRED_PLAN = 'patient/SET_PREFERRED_PLAN';
export const SET_IMPACT_TOKEN = 'patient/SET_IMPACT_TOKEN';
export const SET_SHOW_RTE_MODAL = 'patient/SET_SHOW_RTE_MODAL';

export const setCareMemberToReassign = provider => {
  return {
    type: SET_PROVIDER_TO_REASSIGN,
    provider
  };
};
export const setReassignmentReason = reason => {
  // reason object contains {emr:string, client:string}
  return {
    type: SET_REASSIGNMENT_REASON,
    reason
  };
};
export const SET_CAN_REACTIVATE = 'patient/SET_CAN_REACTIVATE';
export const SET_IS_REACTIVATING = 'patient/SET_IS_REACTIVATING';
export const SET_PREFERRED_NAME = 'patient/SET_PREFERRED_NAME';
export const SET_CANCELLATION_DATE = 'patient/SET_CANCELLATION_DATE';
export const setReactivationVisit = reactivation_visit => {
  return {
    type: SET_REACTIVATION_VISIT,
    reactivation_visit
  };
};

export const setSelectedPaymentMethod = paymentMethod => {
  return {
    type: SET_SELECTED_PAYMENT_METHOD,
    paymentMethod
  };
};

export const setInsuranceInformation = data => {
  // data contains :{ insurance, photos, secondPhotos, hasNewPhotos, hasNewSecondPhotos, medicareCheckbox }
  return {
    type: SET_INSURANCE_INFORMATION,
    data
  };
};

export const setLastestMigrattionStatus = status => ({
  type: SET_LASTEST_MIGRATION_STATUS,
  status
});

export const setPayerNotInListed = payer => ({
  type: SET_PAYER_NOT_IN_LISTED,
  payer
});
export const SET_PROVIDER_INI = 'patient/SET_PROVIDER_INI';

export const setHasProviderIni = (has_prescriber, has_therapist) => {
  return {
    type: SET_PROVIDER_INI,
    payload: {
      has_prescriber,
      has_therapist
    }
  };
};

export const setPayerOnboarding = payer => ({
  type: SET_PAYER_ONBOARDING,
  payer
});

export const setMigrationStatus = migrationStatus => ({
  type: SET_MIGRATION_STATUS,
  migrationStatus
});

export const setInitialServiceLine = initialServiceLine => ({
  type: SET_INITIAL_SERVICE_LINE,
  initialServiceLine
});

export const setUpgradablePlans = upgradablePlans => ({
  type: SET_UPGRADABLE_PLANS,
  upgradablePlans
});

export const setMeditationUpgrade = isMeditationUpgrade => ({
  type: SET_MEDITATION_UPGRADE,
  isMeditationUpgrade
});

export const setInitialPlan = initialPlan => ({
  type: SET_INITIAL_PLAN,
  initialPlan
});

export const setServiceLineNotAvailable = msg => ({
  type: SET_SERVICE_LINE_NOT_AVAILABLE,
  service_line_not_available: msg
});

export const setIsMedicareSecondary = isMedicareSecondary => ({
  type: SET_IS_MEDICARE_SECONDARY,
  isMedicareSecondary
});

export const setEligibleStatus = isEligible => ({
  type: SET_ELIGIBLE,
  isEligible
});

export const setFlowKind = flowKind => ({
  type: SET_FLOW_KIND,
  flowKind
});

export const setMedicationPhotos = photos => ({
  type: SET_MEDICATION_PHOTOS,
  photos
});

export const setInsurancePhotos = photos => ({
  type: SET_INSURANCE_PHOTOS,
  photos
});

export const setSecondInsurancePhotos = photos => ({
  type: SET_SECOND_INSURANCE_PHOTOS,
  photos
});

export const removeQuestionBank = ({ name }) => ({
  type: REMOVE_QUESTION_BANK,
  name
});

export const setInsuranceMedicationsPolicy = ({ policy }) => ({
  type: SET_INSURANCE_MEDICATIONS_POLICY,
  policy
});

export const setInsuranceVisitsPolicy = ({ policy }) => ({
  type: SET_INSURANCE_VISITS_POLICY,
  policy
});
export const setCapacityCheck = ({ capacity }) => ({
  type: SET_CAPACITY_CHECK,
  capacity
});
export const close_outstanding_modal = () => ({
  type: OUTSTANDING_TASK_MODAL_CLOSED
});

export const close_new_appointment_modal = () => ({
  type: NEW_APPOINTMENT_MODAL_CLOSED
});

export const set_abtest = (abTestType, value) => ({
  type: SET_ABTEST,
  abTestType,
  value
});

export const setCoupon = (coupon, price, offering) => ({
  type: SET_COUPON,
  coupon,
  price,
  offering
});

export const setCommunicationPreferences = preference => ({
  type: SET_COMMUNICATION_PREFERENCES,
  preference
});

export const setPrescriptionDeliveryMethod = prescriptionDeliveryMethod => ({
  type: SET_PRESCRIPTION_DELIVERY_METHOD,
  prescriptionDeliveryMethod
});

export const setNextRating = ({
  ratingType,
  userId,
  appointmentStartAt,
  carePersonFullName,
  carePersonId,
  carePersonProfilePhotoUrl
}) => ({
  type: SET_NEXT_RATING,
  ratingType,
  userId,
  appointmentStartAt,
  carePersonFullName,
  carePersonId,
  carePersonProfilePhotoUrl
});

export const setVisitType = visitType => ({
  type: SET_VISIT_TYPE,
  visitType
});

export const setControlledSubstanceRequested = value => ({
  type: SET_CONTROLLED_SUBSTANCE_REQUESTED,
  value
});

export const reset_state = () => ({
  type: RESET_STATE
});

export const cleanup_questions_and_visit = () => ({
  type: RESET_QUESTIONS_AND_VISIT
});

export const set_visit_is_complete = ({ visitType = '', pendingUpdates = false, isComplete = false }) => ({
  type: SET_VISIT_IS_COMPLETE,
  visitType,
  pendingUpdates,
  isComplete
});

export const set_is_payment_submitted = (flag, is_insurance = false) => ({
  type: SET_IS_PAYMENT_SUBMITTED,
  is_payment_submitted: flag,
  is_insurance
});

export const setEntitlements = entitlements => ({
  type: SET_ENTITLEMENTS,
  entitlements
});

export const set_post_checkout_flow = value => ({
  type: SET_POST_CHECKOUT_FLOW,
  value
});

export const set_payment_subscription = value => ({
  type: SET_PAYMENT_SUBSCRIPTION,
  value
});

export const setCanReactivate = value => ({
  type: SET_CAN_REACTIVATE,
  value
});

export const setIsReactivating = value => ({
  type: SET_IS_REACTIVATING,
  value
});

export const setPreferredName = value => ({
  type: SET_PREFERRED_NAME,
  value
});

export const setCancellationDate = value => ({
  type: SET_CANCELLATION_DATE,
  value
});

// TODO: implement middleware for handling api call
export const set_step = (step_num, is_complete) => ({
  type: SET_STEP,
  step: step_num,
  is_complete
});

// https://redux.js.org/basics/actions#actions
// https://redux.js.org/basics/actions#action-creators

export const set_state_with_step = (state, new_step) => ({
  type: SET_STATE_WITH_STEP,
  new_state: state,
  new_step
});

export const set_patient_questions = (questions, bank_id, bank_name, q_id, bank_step) => ({
  type: SET_PATIENT_QUESTIONS,
  questions,
  total_step: questions.length,
  bank_id,
  bank_name,
  q_id,
  bank_step
});

export const set_bank_step_and_question_step = (bank_idx, question_idx, key) => ({
  type: SET_BANK_STEP_AND_QUESTION_STEP,
  bank_index: bank_idx,
  question_index: question_idx,
  key
});

export const add_additional_questions_to_current_bank = (bank_name, idx, questions, region) => ({
  type: ADD_ADDITIONAL_QUESTIONS_TO_CURRENT_BANK,
  bank_name,
  idx,
  questions,
  region
});

export const set_branch_question = (data, bank_name, flow_type, region) => ({
  type: SET_BRANCH_QUESTION,
  questions: data,
  bank_name,
  flow_type,
  region
});

export const set_question_banks_step = question_banks_step => ({
  type: SET_QUESTION_BANKS_STEP,
  question_banks_step
});

export const set_patient = patient_object => ({
  type: SET_PATIENT,
  patient_object
});

export const set_score = (assessment_type, name, value) => ({
  type: SET_SCORE,
  assessment_type,
  name,
  value
});

export const set_follow_up_visit = flag => ({
  type: SET_FOLLOWUP_VISIT,
  is_follow_up: flag
});

export const set_care_manager = manager => ({
  type: SET_CARE_MANAGER,
  care_manager: manager
});

export const set_care_coordinator = care_coordinator => ({
  type: SET_CARE_COORDINATOR,
  care_coordinator
});

export const set_provider = provider => ({
  type: SET_PROVIDER,
  provider
});

export const remove_provider = provider => ({
  type: REMOVE_PROVIDER,
  provider
});

export const set_therapist = therapist => ({
  type: SET_THERAPIST,
  therapist
});

export const setPendingPlanChange = change => ({
  type: SET_PENDING_PLAN_CHANGE,
  change
});

export const setCareTeamMember = (type, member) => {
  switch (type) {
    case 'care_manager':
      return set_care_manager(member);
    case 'therapist':
      return set_therapist(member);
    case 'provider':
      return set_provider(member);
    default:
      throw new Error('Unknow member type.');
  }
};

export const set_care_manager_availability = is_care_manager_available => ({
  type: SET_CARE_MANAGER_AVAILABILITY,
  is_care_manager_available
});

export const set_treatment_object = object => ({
  type: SET_TREATMENT_OBJECT,
  treatment_object: object
});

export const remove_visit = () => ({
  type: REMOVE_VISIT
});

export const set_treatment = treatment_object => ({
  type: SET_TREATMENT,
  treatment_object
});

export const set_dosage = dosage_object => ({
  type: SET_DOSAGE,
  dosage_object
});

export const remove_patient_questions = () => ({
  type: REMOVE_PATIENT_QUESTIONS
});

export const set_patient_state = pstate => ({
  type: SET_PATIENT_STATE,
  patient_state: pstate
});

export const set_service_line = ptype => ({
  type: SET_SERVICE_LINE,
  service_line: ptype
});

export const set_patient_tasks = tasks => ({
  type: SET_PATIENT_TASKS,
  tasks
});

export const setEligibleServiceLines = serviceLines => ({
  type: SET_ELIGIBLE_SERVICE_LINES,
  serviceLines
});

export const update_patient_task = task => ({
  type: UPDATE_TASK,
  task
});

export const update_patient_communication_perference_followup_appointment_redux = commnType => ({
  type: UPDATE_PATIENT_COMMUNICATION_FOLLOWUP_APPOINTMENT,
  commnType
});

export const set_visits_for_patient = visits => ({
  type: SET_VISITS_FOR_PATIENT,
  visits
});

export const set_available_time_slots = timeSlots => ({
  type: SET_AVAILABLE_TIME_SLOTS,
  timeSlots
});

export const set_available_dates = dates => ({
  type: SET_AVAILABLE_DATES,
  dates
});

export const set_patients_appointment = appointment => ({
  type: SET_PATIENTS_APPOINTMENT,
  appointment
});

export const remove_appointment_by_id = id => ({
  type: REMOVE_APPOINTMENT_BY_ID,
  id
});

export const select_time_slot = timeSlot => ({
  type: SET_TIME_SLOT,
  timeSlot
});

export const get_available_dates_fetch = () => ({
  type: GET_AVAILABLE_DATES_FETCH
});
export const get_available_dates_success = () => ({
  type: GET_AVAILABLE_DATES_SUCCESS
});
export const get_available_dates_error = () => ({
  type: GET_AVAILABLE_DATES_ERROR
});

export const get_prescriptions_fetch = () => ({
  type: GET_PRESCRIPTIONS_FETCH
});
export const get_prescriptions_success = () => ({
  type: GET_PRESCRIPTIONS_SUCCESS
});
export const get_prescriptions_error = () => ({
  type: GET_PRESCRIPTIONS_ERROR
});

export const set_prescriptions = (prescriptions /* , page */) => ({
  type: SET_PRESCRIPTIONS,
  prescriptions
  // page
});

export const set_patient_status = status => ({
  type: SET_PATIENT_STATUS,
  status
});

export const setPlan = ({ plan }) => ({
  type: SET_PLAN,
  plan
});

export const setPlanGroupPlan = planGroupPlan => ({
  type: SET_PLAN_GROUP_PLAN,
  planGroupPlan
});

export const setSelectedPlanPrice = selectedPlanPrice => ({
  type: SET_SELECTED_PLAN_PRICE,
  selectedPlanPrice
});

export const setPatientPlanPrice = patientPlanPrice => ({
  type: SET_PATIENT_PLAN_PRICE,
  patientPlanPrice
});

export const setPlanPriceSubscription = planPriceSubscription => ({
  type: SET_PLAN_PRICE_SUBSCRIPTION,
  planPriceSubscription
});

export const setDirectMessagingPilot = enabled => ({
  type: SET_DIRECT_MESSAGING_PILOT,
  dmFlag: enabled
});

export const setRegion = region => ({
  type: SET_REGION,
  region
});

export const setOfferingKey = offering_key => ({
  type: SET_OFFERING_KEY,
  offering_key
});

export const setIsOnlineCheckin = isOnlineCheckin => ({
  type: SET_IS_ONLINE_CHECKIN,
  isOnlineCheckin
});

export const setIsChargeOneTime = isChargeOneTime => ({
  type: SET_CHARGE_ONE_TIME,
  isChargeOneTime
});

export const setIsChangingTrialPlan = isChangingTrialPlan => ({
  type: SET_CHANGING_TRIAL_PLAN,
  isChangingTrialPlan
});

export const setIsMedicationShipped = isMedicationShipped => ({
  type: SET_IS_MEDICATION_SHIPPED,
  isMedicationShipped
});

export const setMedicationWaitlist = medicationWaitlist => ({
  type: SET_MEDICATION_WAITLIST,
  medicationWaitlist
});

export const setComparePlanOfferingKey = offeringKey => ({
  type: SET_COMPARE_PLAN_OFFERING_KEY,
  offeringKey
});

export const setCancellationAppointments = cancellationAppointments => ({
  type: SET_CANCELLATION_APPOINTMENTS,
  cancellationAppointments
});

export const setCancelNotificationRead = (id, value) => ({
  type: SET_CANCEL_NOTIFICATION_READ,
  id,
  value
});

export const setILVStatus = ilvStatus => ({
  type: SET_ILV_STATUS,
  ilvStatus
});

export const setILVPrescriberInfo = ilvPrescriber => ({
  type: SET_ILV_PRESCRIBER_INFO,
  ilvPrescriber
});

export const setSubscribedAfterRemovingCareCounselor = subscribedAfterRemovingCareCounselor => ({
  type: SET_SUBSCRIBED_AFTER_REMOVING_CARE_COUNSELOR,
  subscribedAfterRemovingCareCounselor
});

export const setHipaaAuthorizationStatus = hipaaAuthorizationStatus => ({
  type: SET_HIPAA_AUTHORIZATION_STATUS,
  hipaaAuthorizationStatus
});

export const setPreferredPlan = preferredPlan => ({
  type: SET_PREFERRED_PLAN,
  preferredPlan
});

export const init_cancelation_flow = ({ discountEndDate }) => ({
  type: INIT_CANCELLATION_FLOW,
  discountEndDate
});

export const submitRating =
  (axiosMiddleware, { rating, comment, rating_type, user_id }) =>
  async (dispatch, getState) => {
    const body = {
      rating: { rating, comment, rating_type, user_id }
    };
    const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
    try {
      await (axiosMiddleware || axios).post(`/api/users/${user_id}/rate`, body, { headers });
    } catch (e) {
      tracking.errorV2('Error submitting rating', e, {
        tags: ['rating', 'error'],
        userId: user_id,
        ratingType: rating_type,
        rating,
        comment
      });
    } finally {
      dispatch(setNextRating({ ratingType: null, userId: null }));
    }
  };

/**
 * check if ILV is available
 * We should probably replace get_visit_available with it
 */

export const isILVAvailable = axiosMiddleware => async (_, getState) => {
  if (sessionStorage.getItem('shouldSkipILV') === 'true') {
    return false;
  }
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));

  try {
    const { data } = await (axiosMiddleware || axios).get(API_PATHS.ILV_VISITS, { headers });

    return data.ilv_available;
  } catch (err) {
    tracking.errorV2('Error to fetch available states for ILV', err, {
      tags: ['ILV', 'error']
    });
    return false;
  }
};

export const setShowRteModal = showRteModal => ({
  type: SET_SHOW_RTE_MODAL,
  showRteModal
});

export const get_patient_details =
  (axiosMiddleware, saveVisit = false) =>
  async (dispatch, getState) => {
    const user_attr = get_user_attr(getState());
    const headers = axiosMiddleware ? null : make_headers(user_attr);
    const defaultPlan = { offering_key: 'medication', is_insurance: false };
    const {
      patient: { id: patientId }
    } = user_attr;
    const { data } = await (axiosMiddleware || axios).get(`/api/patients/${patientId}/`, { headers });

    const flowKind = data.flow_kind === 'backend' ? 'BE' : 'FE';
    window.localStorage.setItem('user-state', data.region);
    window.localStorage.setItem('user-postal-code', data.postal_code);

    batch(() => {
      // TODO: this sholdn't be here, we should look to bring back the set_patient action commented out above
      dispatch(init_cancelation_flow({ discountEndDate: data.cancellation_discount_end_date }));
      dispatch(set_care_manager(data.care_manager));
      dispatch(set_care_coordinator(data.care_coordinator));
      dispatch(set_provider(data.current_doctor));
      dispatch(set_patient_status(data.status));
      if (saveVisit) dispatch(set_visit(data.first_visit));
      dispatch(
        set_patient_state({
          verification_complete: data.verification_complete,
          persona_inquiry_id: data.persona_inquiry_id,
          tac_update_needed: data.terms_and_conditions_update_needed,
          pronoun: data.pronoun,
          communication_preference: data.communication_preference,
          on_cash_plan_price: data.on_cash_plan_price ?? false,
          is_active_ish: data.is_active_ish ?? false,
          new_scheduled_upcoming_appointment: data?.new_scheduled_upcoming_appointment ?? false,
          is_video_initial_visit_enabled: data?.is_video_initial_visit_enabled ?? false,
          communication_preferences: data.communication_preferences,
          emergency_contact_first_name: data.emergency_contact_first_name,
          emergency_contact_last_name: data.emergency_contact_last_name,
          emergency_contact_phone: data.emergency_contact_phone,
          payment_customer_id: data.payment_customer_id,
          rte_status: data.rte_status
        })
      );
      dispatch(set_therapist(data.therapist));
      dispatch(setInsuranceVisitsPolicy({ policy: data.insurance_visits_policy || {} }));
      dispatch(setInsuranceMedicationsPolicy({ policy: data.insurance_medications_policy || {} }));
      dispatch(setIsMedicareSecondary(data.is_medicare_secondary));
      dispatch(setPendingPlanChange(data.pending_plan_change));
      dispatch(
        setNextRating(
          data.next_rating
            ? {
                userId: data.next_rating.user_id,
                ratingType: data.next_rating.type,
                appointmentStartAt: data.next_rating.appointment_start_at,
                carePersonFullName: data.next_rating.care_person_full_name,
                carePersonId: data.next_rating.care_person_id,
                carePersonProfilePhotoUrl: data.next_rating.care_person_profile_photo_url
              }
            : { userId: null, ratingType: null }
        )
      );
      dispatch(setPlan({ plan: data.plan || defaultPlan }));
      if (data.ab_test_values)
        Object.keys(data.ab_test_values).forEach(id => dispatch(set_abtest(id, data.ab_test_values[id])));
      dispatch(setDirectMessagingPilot(data.direct_messaging_pilot));
      dispatch(setRegion(data.region));
      dispatch(setOfferingKey(data.offering_key));
      dispatch(setEligibleServiceLines(data.service_lines));
      dispatch(setInsurancePhotos(data.insurance_visits_policy ? data.insurance_cards : []));
      dispatch(setSecondInsurancePhotos(data.insurance_visits_policy ? data.insurance_second_cards : []));
      dispatch(setMedicationPhotos(data.insurance_medications_policy ? data.pharmacy_cards : []));
      dispatch(setFlowKind(flowKind));
      dispatch(setPayerNotInListed(data?.insurance_payer_name));
      dispatch(setPayerOnboarding({}));
      dispatch(setMigrationStatus(data.migration_status));
      dispatch(setLastestMigrattionStatus(data?.lastest_migration_status));
      dispatch(setInitialServiceLine(data.initial_service_line));
      dispatch(setEntitlements(data?.entitlements));
      dispatch(set_post_checkout_flow(data?.post_checkout_flow));
      dispatch(set_payment_subscription(data?.payment_processor));
      dispatch(setCanReactivate(data?.can_reactivate));
      dispatch(setIsReactivating(data?.is_reactivating));
      dispatch(setPreferredName(data?.preferred_name));
      dispatch(setCancellationDate(data?.cancellation_date));
      dispatch(setHipaaAuthorizationStatus(data?.hipaa_authorization_status));
      dispatch(setPreferredPlan(data?.onboarding_preference?.offering_key));
    });
    return data;
  };

export const get_available_dates =
  (
    axiosMiddleware,
    {
      getAllAvailableDates = false,
      appointmentType = 'provider',
      duration = 30,
      provider_id,
      superAdhocLinkId,
      reschedulingSuperAdhoc,
      time_zone
    }
  ) =>
  (dispatch, getState) => {
    let url = `/api/calendar/availability?appointment_type=${appointmentType}&duration=${duration}`;

    if (time_zone) {
      url += `&time_zone=${time_zone}`;
    }

    if (provider_id) {
      url += `&careperson_id=${provider_id}`;
    }

    if (superAdhocLinkId) {
      url += `&super_adhoc_link_id=${superAdhocLinkId}`;
    }

    if (reschedulingSuperAdhoc) {
      url += `&rescheduling_super_adhoc=${reschedulingSuperAdhoc}`;
    }

    if (getAllAvailableDates) {
      url = `/api/calendars/care_people_days_available?appointment_type=${appointmentType}`;
    }

    const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));

    dispatch(get_available_dates_fetch());
    // dispatch(get_patient());
    return (axiosMiddleware || axios)
      .get(url, {
        headers
      })
      .then(resp => {
        batch(() => {
          dispatch(get_available_dates_success());
          dispatch(set_available_dates(resp.data));
        });
        return resp;
      });
  };

export const get_available_time_slots =
  (
    axiosMiddleware,
    { selectedDate, appointmentType = 'provider', duration = 30, provider_id, getAllTimeSlot = false, time_zone }
  ) =>
  (dispatch, getState) => {
    let url = `/api/calendar/slots_by_date?date=${selectedDate}&appointment_type=${appointmentType}&duration=${duration}`;

    if (time_zone) {
      url += `&time_zone=${time_zone}`;
    }

    if (provider_id) {
      url += `&careperson_id=${provider_id}`;
    }

    if (getAllTimeSlot) {
      url = `/api/calendars/care_people_slots_available?appointment_type=${appointmentType}&date=${selectedDate}`;
    }

    const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));

    dispatch(get_available_dates_fetch());
    return (axiosMiddleware || axios)
      .get(url, {
        headers
      })
      .then(resp => {
        batch(() => {
          dispatch(get_available_dates_success());
          dispatch(set_available_time_slots(resp.data));
        });
        return resp;
      });
  };

export const should_show_reschedule_button = (axiosMiddleware, appointmentId) => (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));

  return (axiosMiddleware || axios).get(
    `/api/calendar/should_show_rescheduled_button?appointment_id=${appointmentId}`,
    {
      headers
    }
  );
};

export const should_show_cancel_button = (axiosMiddleware, appointmentId) => (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));

  return (axiosMiddleware || axios).get(`/api/calendar/should_show_cancelled_button?appointment_id=${appointmentId}`, {
    headers
  });
};

export const cancel_appointment =
  (axiosMiddleware, appointmentId, reassignment_improvements = false) =>
  (dispatch, getState) => {
    const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
    return (axiosMiddleware || axios).post(
      `/api/calendar/cancel_appointment?appointment_id=${appointmentId}`,
      { is_enabled_ab_self_pay: false, is_reassignment_improvements: reassignment_improvements },
      { headers }
    );
  };

export const get_schedule_info =
  (axiosMiddleware, appointmentType, cancel_appointment_id, provider_id, superAdhocLinkId) => (dispatch, getState) => {
    let url = `/api/calendar/schedule_info?appointment_type=${appointmentType}`;

    if (cancel_appointment_id) {
      url += `&cancel_appointment_id=${cancel_appointment_id}`;
    }

    if (provider_id) {
      url += `&careperson_id=${provider_id}`;
    }

    if (superAdhocLinkId) {
      url += `&super_adhoc_link_id=${superAdhocLinkId}`;
    }

    const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
    return (axiosMiddleware || axios).get(url, { headers });
  };

export const submit_appointment =
  (
    axiosMiddleware,
    starts_at,
    cancel_appointment_id,
    appointment_type,
    duration,
    adhoc,
    provider_id,
    superAdhocLinkId,
    isReassign
  ) =>
  (dispatch, getState) => {
    const data = {
      starts_at,
      duration
    };

    if (adhoc) {
      data.adhoc = String(adhoc);
    }

    if (cancel_appointment_id) {
      data.cancel_appointment_id = cancel_appointment_id;
      dispatch(remove_appointment_by_id(cancel_appointment_id));
    }
    if (appointment_type) {
      data.appointment_type = appointment_type;
    }

    if (provider_id) {
      data.careperson_id = provider_id;
    }

    if (superAdhocLinkId) {
      data.super_adhoc_link_id = superAdhocLinkId;
    }

    data.is_enabled_ab_self_pay = false;

    const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));

    if (isReassign) {
      const payload = {
        care_person_type: appointment_type,
        care_person_id: provider_id,
        starts_at,
        duration,
        adhoc: String(true)
      };
      return (axiosMiddleware || axios).post('/api/v1/adhoc_clinician_reassignment/adhoc_schedule', payload, {
        headers
      });
    }

    return (axiosMiddleware || axios).post('/api/calendar', data, { headers });
  };

/** BE FLOW UPDATE: remove if not used */
export const get_additional_questions_for_current_bank_by_name =
  (axiosMiddleware, name, currentBankName, index) => (dispatch, getState) => {
    const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
    return (axiosMiddleware || axios).get(`/api/question_banks/search?name=${name}`).then(bank => {
      return (axiosMiddleware || axios).get(`/api/question_banks/${bank.data.id}/questions`, headers).then(resp => {
        const userAttributes = getState().global_reducer.current_user?.attributes;
        const patientRegion = userAttributes?.patient?.region;

        dispatch(add_additional_questions_to_current_bank(currentBankName, index, resp.data, patientRegion));
      });
    });
  };

/** BE FLOW UPDATE: remove if not used */
export const get_branch_questions_by_id =
  (axiosMiddleware, branch_question_id, branch_name) => (dispatch, getState) => {
    const userAttributes = getState().global_reducer.current_user?.attributes;
    const flowType = userAttributes?.patient?.flow_type;
    const patientRegion = userAttributes?.patient?.region;

    const questions = getQuestionsForInternalBanks(branch_name);

    if (!isEmpty(questions)) {
      dispatch(set_branch_question(questions, branch_name, flowType, patientRegion));
      return Promise.resolve(questions);
    }

    const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));

    return (axiosMiddleware || axios)
      .get(`/api/question_banks/${branch_question_id}/questions`, { headers })
      .then(resp => {
        dispatch(set_branch_question(resp.data, branch_name, flowType, patientRegion));
        return resp;
      });
  };

/** BE FLOW UPDATE: remove if not used */
export const setCurrentPatientPath =
  ({ isPaymentSubmitted, isMedsTherapy = false, isTherapistRegion }) =>
  (dispatch, getState) => {
    const {
      global_reducer: {
        current_user: {
          attributes: {
            patient: { communication_preference }
          }
        }
      }
    } = getState();

    return dispatch({
      type: SET_CURRENT_PATIENT_PATH,
      isPaymentSubmitted,
      communicationPreferences: communication_preference,
      removeUpdatePlan: isMedsTherapy || !isTherapistRegion
    });
  };

export const setPendingUpdate = flag => ({
  type: SET_PENDING_UPDATE,
  flag
});

/** BE FLOW UPDATE: remove if not used */
export const get_question_banks_by_visit_id =
  (
    axiosMiddleware,
    visit,
    therapistRegions,
    assessment_flow = false,
    initial_intake_form_task = false,
    intake_assessment_task = false,
    intake_assessment_reactivation_task = false
  ) =>
  (dispatch, getState) => {
    const {
      global_reducer: {
        current_user: {
          attributes: {
            patient: { flow_type, completed_payment_at }
          }
        }
      },
      patient_reducer: {
        visit_object,
        plan: { offering_key }
      }
    } = getState();
    const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));

    const { id, is_follow_up } = visit ?? visit_object;
    const is_full = flow_type === 'full_assessment_first';

    const query = {
      visit_id: id,
      is_full,
      assessment_flow,
      initial_intake_form_task,
      intake_assessment_task,
      intake_assessment_reactivation_task
    };
    return (axiosMiddleware || axios)
      .get(`/api/question_banks/for_visit?${queryString.stringify(query)}`, { headers })
      .then(({ data }) => {
        return data.map(bank => {
          if (bank.name === 'assessment_end' && is_full) {
            if (is_follow_up) {
              bank.num_questions -= 1; // remove question about what causing the issue
            }
            bank.num_questions += 1; // preliminary score
          }

          return bank;
        });
      })
      .then(data => {
        const internalBanks =
          assessment_flow || initial_intake_form_task || intake_assessment_task || intake_assessment_reactivation_task
            ? []
            : getInternalQuestionBanks();
        const banks = data.concat(internalBanks);
        batch(() => {
          dispatch(set_question_banks({}, banks, 0, completed_payment_at));
          dispatch(
            setCurrentPatientPath({
              isPaymentSubmitted: !!completed_payment_at,
              isMedsTherapy: ['medication-therapy', '345_meds_therapy', '365_meds_therapy'].includes(offering_key),
              therapistRegions
            })
          );
        });

        return data;
      });
  };

export const update_patient_seen_new_created_appointment = (axiosMiddleware, public_id) => (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  return (axiosMiddleware || axios)
    .put(`/api/appointments/${public_id}`, { is_seen_by_patient: true }, { headers })
    .then(resp => {
      dispatch(close_new_appointment_modal());
      return resp.data;
    });
};
export const get_patient_tasks = axiosMiddleware => (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  return (axiosMiddleware || axios).get('/api/tasks', { headers }).then(resp => {
    dispatch(set_patient_tasks(resp.data));
    return resp.data;
  });
};

export const complete_patient_task = (axiosMiddleware, task_key) => (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  return (axiosMiddleware || axios).post(`/api/tasks/complete/${task_key}`, {}, { headers }).then(resp => {
    return resp.data;
  });
};

export const reset_retired_flow_leads = (axiosMiddleware, visitId) => (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  const user_attr = get_user_attr(getState());
  const {
    patient: { id: patientId }
  } = user_attr;

  return (axiosMiddleware || axios)
    .post(`/api/patients/${patientId}/visits/${visitId}/reset_lead`, {}, { headers })
    .then(resp => {
      return resp.DateTimeFormat;
    });
};

export const update_patient_communication_perference_followup_appointment =
  (axiosMiddleware, data) => (dispatch, getState) => {
    const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
    const patient = dispatch(get_current_patient());

    const body = {
      communication_preference_followup_appointment: {
        ...data
      }
    };
    return (axiosMiddleware || axios)
      .put(`/api/patients/${patient.id}/update_communication_preference`, body, {
        headers
      })
      .then(() => {
        dispatch(update_patient_communication_perference_followup_appointment_redux(data.prescriber));
      });
  };

// makes an authenticated GET call to the server and unwraps the response nicely
export const get_with_auth_and_return_just_data = (axiosMiddleware, url) => (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  return (axiosMiddleware || axios).get(url, { headers }).then(resp => {
    return Promise.resolve(resp.data);
  });
};

/** BE FLOW UPDATE: remove if not used */
export const get_treatment_by_name = (axiosMiddleware, treatment_name) => dispatch => {
  return dispatch(get_with_auth_and_return_just_data(axiosMiddleware, `/api/treatments/search?name=${treatment_name}`));
};

export const updatePatientAddress =
  (axiosMiddleware, addr, is_update_care_team = false, address_type = 'ShippingAddress') =>
  (dispatch, getState) => {
    const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
    const patient = dispatch(get_current_patient());
    return (axiosMiddleware || axios).post(
      `/api/patients/${patient.id}/addresses?is_update_care_team=${is_update_care_team}`,
      { ...addr, address_type },
      { headers }
    );
  };

export const updateExistingPatientAddress =
  (axiosMiddleware, addr, is_confirm_update_address = false, address_type = 'ShippingAddress') =>
  (dispatch, getState) => {
    const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
    const patient = dispatch(get_current_patient());
    return (axiosMiddleware || axios)
      .put(
        `/api/patients/${patient.id}/addresses?is_confirm_update_address=${is_confirm_update_address}`,
        { ...addr, address_type },
        { headers }
      )
      .catch(error => error);
  };

export const get_anonymous_detail = (axiosMiddleware, id) => (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  return (axiosMiddleware || axios).get(`/api/anonymous_appointment/${id}`, { headers });
};

export const get_patient_detail = axiosMiddleware => async (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  const {
    patient: { id: patientId }
  } = user_attr;
  const { data } = await (axiosMiddleware || axios).get(`/api/patients/${patientId}/`, { headers });
  return data;
};

export const get_availability_dates = (email, axiosMiddleware) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return (axiosMiddleware || axios).get(`/api/public/calendar/availability?email=${email}&timezone=${timezone}`);
};

export const update_patient = (axiosMiddleware, updates) => (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  const patient = dispatch(get_current_patient());
  return (axiosMiddleware || axios).put(`/api/patients/${patient.id}/`, updates, { headers }).then(resp => {
    const { data = {} } = resp;

    batch(() => {
      if (data && data.care_manager) {
        dispatch(set_care_manager(data.care_manager));
      }
      if (data.ab_test_values)
        Object.keys(data.ab_test_values).forEach(id => {
          dispatch(set_abtest(id, data.ab_test_values[id]));
        });

      const flowKind = data.flow_kind === 'backend' ? 'BE' : 'FE';

      dispatch(set_patient_state(updates));
      dispatch(setDirectMessagingPilot(data.direct_messaging_pilot));
      dispatch(setRegion(data.region));
      dispatch(setFlowKind(flowKind));
      dispatch(setEligibleServiceLines(data.service_lines));
      dispatch(set_post_checkout_flow(data?.post_checkout_flow));
      dispatch(setPreferredName(data?.preferred_name));
      dispatch(setHipaaAuthorizationStatus(data?.hipaa_authorization_status));
      dispatch(setPreferredPlan(data?.onboarding_preference?.offering_key));
    });
    return resp;
  });
};

export const consentToPrescriptions = (axiosMiddleware, type, answer) => (dispatch, getState) => {
  // answer: 'agreed' or 'disagree'
  // type: 'copay', 'fill', 'pay_in_cash'
  // method: 'email' 'online' 'text' 'verbal'

  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  const patient = dispatch(get_current_patient());

  const body = {
    type,
    answer,
    method: 'online'
  };

  return (axiosMiddleware || axios).put(`/api/patients/${patient.id}/consents`, body, { headers });
};

export const ensure_visit = (axiosMiddleware, is_new_reactivation_flow) => (dispatch, getState) => {
  const visit = getState().patient_reducer.visit_object;
  const serviceLine = getState().patient_reducer?.service_line;
  // TODO: wecould also check to make sure the visit is not expired
  if (visit && !isVisitCompleted(visit)) {
    return Promise.resolve(visit);
  }
  if (is_new_reactivation_flow) {
    return visit
      ? Promise.resolve(visit)
      : Promise.reject(new Error('new reactivation has questions requires the initial visit'));
  }

  // NOTE: the server will take care of creating a new visit or giving us back an existing visit if needed
  return dispatch(create_visit(axiosMiddleware, serviceLine.name)).then(new_visit => {
    dispatch(set_visit(new_visit));
    return Promise.resolve(new_visit);
  });
};

export const get_current_patient_and_visit = (axiosMiddleware, is_new_reactivation_flow) => dispatch => {
  const patient = dispatch(get_current_patient());

  // TODO: if there is not patient in current state, we could get the patient object using user_id

  if (patient == null) {
    return Promise.resolve({ patient: null, visit: null });
  }

  return dispatch(ensure_visit(axiosMiddleware, is_new_reactivation_flow)).then(visit => {
    return Promise.resolve({ patient, visit });
  });
};

/** BE FLOW UPDATE: remove if not used */
export const complete_current_visit = (type, carePerson, task, axiosMiddleware) => (dispatch, getState) => {
  const {
    patient_reducer: {
      visit_object: { is_follow_up },
      reactivation_visit
    }
  } = getState();

  return dispatch(get_current_patient_and_visit(axiosMiddleware)).then(resp => {
    const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
    const path = `/api/patients/${resp?.patient.id}/visits/${resp.visit.id}/complete`;

    const body = {
      reactivation_visit: reactivation_visit || String(!is_follow_up)
    };

    if (carePerson && carePerson.id) {
      body.care_person_id = carePerson.id;
      body.care_person_type = type;
    }

    if (task) body.task = task;

    return (axiosMiddleware || axios).put(path, body, { headers }).then(response => {
      const new_visit = response.data;
      dispatch(set_visit_is_complete({ isComplete: true, pendingUpdates: false, visitType: new_visit.visit_type }));
      return Promise.resolve(new_visit);
    });
  });
};

/** BE FLOW UPDATE: remove if not used */
export const isVisitComplete = axiosMiddleware => (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  return dispatch(get_current_patient_and_visit(axiosMiddleware)).then(resp => {
    return (axiosMiddleware || axios)
      .get(`/api/patients/${resp?.patient.id}/visits/${resp.visit.id}/is_complete`, {
        headers
      })
      .then(({ data: visit }) => {
        if (isVisitCompleted(visit)) {
          dispatch(set_visit_is_complete({ isComplete: true, pendingUpdates: false }));
        }

        return Promise.resolve(visit);
      });
  });
};

/** BE FLOW UPDATE: remove if not used */
export const answer_current_question =
  (axiosMiddleware, answer, question, is_new_reactivation_flow) => (dispatch, getState) => {
    const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));

    const patient_state = getState()?.patient_reducer;

    const current_question = question || patient_state.branch_questions[patient_state.branch_question_step];

    // this answer does not need to be recorded because we have been explicitly told not to do so
    if (current_question == null || !current_question.save_answer) return Promise.resolve();

    // get_current_patient_and_visit which will create new records if they are needed
    return dispatch(get_current_patient_and_visit(axiosMiddleware, is_new_reactivation_flow)).then(resp => {
      if (resp?.patient == null || resp.visit == null) {
        // this answer does not need to be recorded because there is no current patient or visit
        return Promise.resolve();
      }

      const body = {
        ...answer,
        question_bank_id: patient_state.question_bank_id,
        question_id: current_question.id,
        question_name: current_question.name
      };

      // this additional param will tell BE to update the answer to the visit even pending_updates = false
      if (is_new_reactivation_flow) {
        body.is_reactivating = true;
      }

      return (axiosMiddleware || axios)
        .post(`/api/patients/${resp?.patient.id}/visits/${resp.visit.id}/answers`, body, { headers })
        .then(response => {
          return Promise.resolve(response.data);
        })
        .catch(err => {
          tracking.errorV2('Error to answer current question', err, {
            tags: ['visit', 'answer', 'error'],
            body
          });

          return Promise.reject(err);
        });
    });
  };

export const upload_object_for_current_question =
  (axiosMiddleware, file, file_type, file_name, question) => dispatch => {
    return dispatch(answer_current_question(axiosMiddleware, { upload: true, file_type, file_name }, question))
      .then(resp => {
        const option = {
          headers: {
            ContentEncoding: 'base64',
            'Content-Type': file_type
          }
        };

        return (axiosMiddleware || axios).put(resp.object_url, file, option).then(res => {
          return Promise.resolve(res);
        });
      })
      .catch(err => Promise.reject(err));
  };

export const get_visits_for_patient = (axiosMiddleware, patient_id, visitId) => async (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  const user_attr = get_user_attr(getState());
  const { patient } = user_attr;

  const patientId = patient_id ?? patient.id;

  try {
    if (visitId) {
      await (axiosMiddleware || axios).post(
        `/api/patients/${patientId}/visits/${visitId}/update_assessment_scores`,
        {},
        {
          headers
        }
      );
    }
    const { data } = await (axiosMiddleware || axios).get(`/api/patients/${patientId}/visits`, { headers });
    dispatch(set_visits_for_patient(data));

    if (data.length > 0) {
      if (data[0].service_lines && data[0].service_lines.find(treatment => treatment.name === 'adhd')) {
        window.localStorage.setItem('isADHD', 'true');
      } else {
        window.localStorage.setItem('isADHD', 'false');
      }
    }

    return data;
  } catch (err) {
    throw new Error(
      `Could not get visits ${
        !isEmpty(patient)
          ? stringify({ patient_id: patientId, user_id: patient.user_id })
          : 'No user information has been provided'
      }. Error: ${!isEmpty(err) ? stringify(err) : 'no data was passed from API call'}`
    );
  }
};

export const get_payment_methods = axiosMiddleware => (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  return (axiosMiddleware || axios).get('/api/v2/payments/methods', { headers });
};

export const delete_payment_method = (axiosMiddleware, payment_method_id) => (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  return (axiosMiddleware || axios).delete(`/api/v2/payments/methods/${payment_method_id}`, { headers });
};

export const update_payment_method = (axiosMiddleware, body, payment_method_id) => (_, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  return (axiosMiddleware || axios).put(`/api/v2/payments/methods/${payment_method_id}`, body, { headers });
};

export const create_intent = (axiosMiddleware, body) => (_, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  return (axiosMiddleware || axios).post(`api/v2/payments/setup_intents`, body, { headers });
};

export const update_default_payment_method = (axiosMiddleware, payment_method_id, is_default) => (_, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  return (axiosMiddleware || axios).put(`/api/v2/payments/methods/${payment_method_id}`, { is_default }, { headers });
};

export const clean_up_patient_process = () => dispatch => {
  return dispatch(cleanup_questions_and_visit());
};

export const sign_out = () => dispatch => {
  setCustomDataKameleoon('isLoggedIn', false);
  setCustomDataKameleoon('userId', null);
  return dispatch(reset_state());
};

export const get_prescriptions = axiosMiddleware => dispatch => {
  dispatch(get_prescriptions_fetch());
  return axiosMiddleware
    .get('/api/prescriptions')
    .then(res => {
      dispatch(get_prescriptions_success());
      // dispatch(set_prescriptions(page > 1 ? prescriptionsMockPage2 : prescriptionsMock, page));
      dispatch(set_prescriptions(res.data));
    })
    .catch(() => {
      dispatch(get_prescriptions_error());
    });
};

export const cancel_subscription_start = ({ textOnLoading }) => ({
  type: CANCEL_SUBSCRIPTION_START,
  textOnLoading
});

export const cancel_subscription_success = ({ textOnLoaded, descriptionOnLoaded }) => ({
  type: CANCEL_SUBSCRIPTION_SUCESS,
  textOnLoaded,
  descriptionOnLoaded
});

export const cancel_subscription_finished = () => ({
  type: CANCEL_SUBSCRIPTION_FINISHED
});

export const cancel_subscription = () => async (axiosMiddleware, dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  const headers = axiosMiddleware ? null : make_headers(user_attr);
  const { patient } = user_attr;

  dispatch(cancel_subscription_start({ textOnLoading: 'Processing...' }));

  const { data } = await (axiosMiddleware || axios).put(`/api/patients/${patient.id}/cancellation`, {}, { headers });

  if (data.success) {
    dispatch(
      cancel_subscription_success({
        textOnLoaded: 'Subscription cancelation confirmed.',
        descriptionOnLoaded: `Your account will be active untill ${data.subscription_end_date}`
      })
    );
  } else {
    dispatch(cancel_subscription_finished());
    throw new Error('Error cancelling subscription!');
  }
};

export const apply_coupon_start = ({ textOnLoading }) => ({
  type: APPLY_COUPON_START,
  textOnLoading
});

export const apply_coupon_success = ({ textOnLoaded, discountEndDate }) => ({
  type: APPLY_COUPON_SUCESS,
  textOnLoaded,
  discountEndDate
});

export const apply_coupon_finished = () => ({
  type: APPLY_COUPON_FINISHED
});

export const apply_coupon =
  (axiosMiddleware, { coupon_id }) =>
  async (dispatch, getState) => {
    const user_attr = get_user_attr(getState());
    const headers = axiosMiddleware ? null : make_headers(user_attr);
    const { patient } = user_attr;

    dispatch(apply_coupon_start({ textOnLoading: 'Processing...' }));

    const { data } = await (axiosMiddleware || axios).put(
      `/api/patients/${patient.id}/apply_coupon`,
      { coupon_id },
      { headers }
    );

    if (data.success) {
      dispatch(
        apply_coupon_success({
          textOnLoaded: `50% discount applied untill ${data.cancellation_discount_end_date}`,
          discountEndDate: data.cancellation_discount_end_date
        })
      );
    } else {
      dispatch(apply_coupon_finished());
      throw new Error('Error applying coupon!');
    }
  };

export const saveCancellationAnswer =
  (axiosMiddleware, { answer }) =>
  async (dispatch, getState) => {
    const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
    const {
      global_reducer: {
        current_user: { attributes }
      },
      patient_reducer: { visits }
    } = getState();
    const visit = visits[visits.length - 1];
    const body = { answer, question_bank_id: '', question_name: 'reason_for_cancellation' };
    const response = await (axiosMiddleware || axios).post(
      `/api/patients/${attributes?.patient.id}/visits/${visit.id}/answers`,
      body,
      { headers }
    );
    return response.data;
  };

export const verifyCoupon =
  (axiosMiddleware, { coupon_id, terms_to_charge }) =>
  async (dispatch, getState) => {
    const {
      patient_reducer: {
        plan: { offering_key, is_insurance }
      },
      global_reducer: {
        current_user: { attributes }
      }
    } = getState();
    const {
      patient: { id }
    } = attributes;

    const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));

    const body = { coupon_id, offering: offering_key, is_insurance, terms_to_charge };
    const response = await (axiosMiddleware || axios).put(`/api/patients/${id}/coupon_valid`, body, { headers });
    return response.data;
  };

export const loadNotificationSettings = axiosMiddleware => async (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  const response = await (axiosMiddleware || axios).get(`/api/notification_settings/show`, { headers });
  return response.data;
};

export const saveNotificationSettings =
  (axiosMiddleware, { options }) =>
  async (dispatch, getState) => {
    const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
    const body = { patient: options };
    const response = await (axiosMiddleware || axios).post(`/api/notification_settings/update`, body, { headers });
    return response.data;
  };

export const updateVisitType =
  (axiosMiddleware, type = 'scheduled') =>
  async (dispatch, getState) => {
    const {
      global_reducer: {
        current_user: { attributes }
      },
      patient_reducer: {
        visit_object: { id: visitId }
      }
    } = getState();

    const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
    const {
      patient: { id: patientId }
    } = attributes;

    await (axiosMiddleware || axios).put(
      `/api/patients/${patientId}/visits/${visitId}`,
      { visit_type: type },
      { headers }
    );
    dispatch(setVisitType(type));
  };

export const setImmediatVisitType =
  (axiosMiddleware, type = 'video') =>
  (_, getState) => {
    const {
      global_reducer: {
        current_user: { attributes }
      }
    } = getState();

    const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));

    const body = {
      visit_type: type
    };

    if (type === 'phone') {
      body.phone = attributes.phone;
    }

    return (axiosMiddleware || axios).post('/api/visit_requests', body, { headers }).catch(err => {
      tracking.errorV2('Error set immediate visit type', err, {
        tags: ['visit', 'error'],
        body
      });
      return err;
    });
  };

export const setInsuranceStatus = status => ({
  type: SET_INSURANCE_STATUS,
  status
});

export const createVisitsInsurance = (axiosMiddleware, insurance, isOutOfNetwork) => async (dispatch, getState) => {
  const {
    global_reducer: {
      current_user: { attributes }
    }
  } = getState();
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));

  if (!isEmpty(insurance)) {
    const res = await (axiosMiddleware || axios).post(
      'api/insurance_policies',
      isOutOfNetwork
        ? { ...attributes?.patient, ...insurance, active: true, out_of_network: true }
        : { ...attributes?.patient, ...insurance, active: true },
      { headers }
    );
    if (res?.data?.insurance_policy) {
      dispatch(setInsuranceVisitsPolicy({ policy: { ...res.data.insurance_policy } }));
    }
  }
};

export const removeVisitsInsurance = axiosMiddleware => async (dispatch, getState) => {
  const {
    patient_reducer: {
      insurance_visits_policy: { id }
    }
  } = getState();
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));

  if (id) await (axiosMiddleware || axios).put(`api/insurance_policies/${id}`, { active: false }, { headers });
  dispatch(setInsuranceVisitsPolicy({ policy: {} }));
};

export const updateMedicationsInsurance =
  (axiosMiddleware, { insurance, updates }) =>
  async (dispatch, getState) => {
    const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));

    if (insurance && insurance.id) {
      await (axiosMiddleware || axios).put(`api/insurance_medications_policies/${insurance.id}`, updates, { headers });
      dispatch(setInsuranceMedicationsPolicy({ policy: { ...insurance, ...updates } }));
    }
  };

export const removeMedicationsInsurance = axiosMiddleware => async (dispatch, getState) => {
  const {
    patient_reducer: {
      insurance_medications_policy: { id }
    }
  } = getState();
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));

  if (id)
    await (axiosMiddleware || axios).put(`api/insurance_medications_policies/${id}`, { active: false }, { headers });
  dispatch(setInsuranceMedicationsPolicy({ policy: {} }));
};

export const setTermsVersionUpdated = status => ({
  type: SET_TERMS_VERSION_UPDATED,
  status
});

export const updateTermsVersion = axiosMiddleware => async (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  const patient = dispatch(get_current_patient());
  await (axiosMiddleware || axios).put(`/api/patients/${patient.id}/update_terms`, null, { headers });
  dispatch(setTermsVersionUpdated(false));
};

export const assignCarePerson = (type, carePerson, axiosMiddleware) => async (dispatch, getState) => {
  const {
    global_reducer: {
      current_user: { attributes }
    }
  } = getState();

  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  const {
    patient: { id }
  } = attributes;

  await (axiosMiddleware || axios).put(
    `/api/patients/${id}/assign_care_person`,
    { care_person_id: carePerson.id, care_person_type: type },
    { headers }
  );
  batch(() => {
    dispatch(setCareTeamMember(type, carePerson));
    if (type === 'provider') dispatch(setVisitType('scheduled'));
  });
};

export const signOver18Consent = axiosMiddleware => async (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  await (axiosMiddleware, axios).put(`/api/patients/sign_over18`, null, { headers });
};

export const getOnboardingStatus = (locationState, axiosMiddleware) => async (dispatch, getState) => {
  let apiPath = '/api/patients/onboarding_status';
  if (locationState?.next) {
    apiPath += `?redirect_url=${locationState.next}`;
  }

  if (window.location.search?.includes('ob_flow=insurance_eligibility_check')) {
    const separator = locationState?.next ? '&' : '?';
    apiPath += `${separator}ob_flow=insurance_eligibility_check`;
  }

  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));

  const { data } = await (axiosMiddleware || axios).get(apiPath, { headers });
  return data;
};

export const acknowledge_medicare = axiosMiddleware => async (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  await (axiosMiddleware || axios).put(`/api/patients/acknowledge_medicare`, null, { headers });
};

export const consent_telehealth = axiosMiddleware => async (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  await (axiosMiddleware || axios).put(`/api/patients/consent_telehealth`, null, { headers });
};

export const abortCancellationSubscription = axiosMiddleware => async (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  const res = await (axiosMiddleware || axios).put(`/api/patients/abort_cancellation_subscription`, null, { headers });
  dispatch(set_patient_status(res.data.status));
};

export const reactivateSubscription = (search, history, axiosMiddleware) => async (dispatch, getState) => {
  const urlParams = parse(search);
  const appsflyerObj = segment.urlParamsToContext(urlParams);
  const {
    global_reducer: {
      current_user: {
        attributes: {
          patient: { status }
        }
      }
    }
  } = getState();

  if (status === 'canceled') {
    history.push('/patient/dashboard/reactivation-zip-code');
  } else {
    await dispatch(abortCancellationSubscription(axiosMiddleware));
    history.push('/patient/cancellation/immediate-reactivation');
  }

  await segment.reactivateAccount(appsflyerObj);
};

export const get_cancellation_appointments = axiosMiddleware => (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  return (axiosMiddleware || axios).get(`/api/calendar/canceled_initial_appointments`, { headers }).then(resp => {
    dispatch(setCancellationAppointments(resp.data));
    return resp.data;
  });
};

export const update_cancel_notification_read = (id, value, axiosMiddleware) => async (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));

  await (axiosMiddleware || axios).patch(`api/calendar/${id}?is_cancel_notification_read=${value}`, null, {
    headers
  });
  dispatch(setCancelNotificationRead(id, value));
};

export const get_subscribed_after_removing_care_counselor = axiosMiddleware => (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));

  return (axiosMiddleware || axios)
    .get('/api/patients/subscribed_after_removing_care_counselor', {
      headers
    })
    .then(res => {
      dispatch(setSubscribedAfterRemovingCareCounselor(res.data.subscribed_after_removing_care_counselor));
    });
};

export const fetchServiceLinesByName = (axiosMiddleware, serviceLine) => {
  return axiosMiddleware.get(`/api/service_lines?name=${serviceLine}`).then(res => res.data);
};

export const update_diagnoses_service_lines = (axiosMiddleware, diagnoses) => async (dispatch, getState) => {
  const {
    global_reducer: {
      current_user: {
        attributes: { id: patient_id }
      }
    },
    patient_reducer: {
      visit_object: { id: visit_id }
    }
  } = getState();
  await axiosMiddleware.put(`/api/patients/${patient_id}/visits/${visit_id}/update_diagnoses_service_lines`, {
    diagnoses
  });
};

export const unassignCurrentClinicians = (axiosMiddleware, patientId) => {
  return axiosMiddleware.post(`/api/patients/${patientId}/unassign_current_clinicians`, {});
};

export const setImpactToken = value => ({
  type: SET_IMPACT_TOKEN,
  value
});

export const create_rte_status = axiosMiddleware => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  const headers = axiosMiddleware ? null : make_headers(user_attr);
  const { patient } = user_attr;

  return (axiosMiddleware || axios).post(
    `/api/patients/${patient.id}/rte_status`,
    {},
    {
      headers
    }
  );
};

export const update_rte_status = (axiosMiddleware, status) => (dispatch, getState) => {
  const headers = axiosMiddleware ? null : make_headers(get_user_attr(getState()));
  const patient = dispatch(get_current_patient());

  return (axiosMiddleware || axios).put(
    `/api/patients/${patient.id}/rte_status`,
    {
      rte_status: status
    },
    {
      headers
    }
  );
};
