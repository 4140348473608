import React, { useEffect, useRef } from 'react';
import strings from 'localization';
import * as Yup from 'yup';
import { RNWindow } from 'utils/react-native-webview-window';
import { isPast, addMinutes } from './date-fns';
import error_checker, { valid_states } from './error_checker';

export const capitalize = word => {
  return word[0].toUpperCase() + word.substring(1);
};

export const common_errors = {
  state_selector: 'Please select the state you live in from the list',
  first_name: 'This field can’t be blank. Please enter your first name here.',
  last_name: 'This field can’t be blank. Please enter your last name here',
  email: 'Oops! Please enter a valid email address. Example: example@email.com',
  password: 'Your password must contain at least 6 characters',
  password_length: 'At least 8 characters',
  password_min_condition: 'At least 3 of the following',
  password_upper_and_lower_case: 'Both upper and lowercase letters',
  password_upper_case: 'Upper case letters (A-Z)',
  password_lower_case: 'Lower case letters (a-z)',
  password_at_least_one_number: 'Numbers (0-9)',
  password_at_least_one_symbol: 'Special characters (e.g. !@#$%^&*)',
  password_confirm: 'Please make sure that your passwords match.',
  is_consent: 'Oops! The consent box needs to be checked to continue.',
  phone_number:
    'Hmm...the phone number you entered does not seem right. This field can only contain a valid phone number',
  birth_date: 'Please enter your date of birth in mm/dd/yyyy format',
  birth_date_uk: 'Oops! Please enter your date of birth in the following format: (Example dd/mm/yyyy)',
  is_adult: 'Unfortunately, you have to be 18 years old or over to be eligible for our service.',
  is_adult_uk: 'Unfortunately, you have to be 18 years old or over to be eligible for our service.',
  weight: 'Oops! Please enter your weight in lbs.',
  height_inch: 'You have to input your height in inches by selecting an option from the dropdown menu',
  height_ft: 'You have to input your height in feet by selecting an option from the dropdown menu',
  selected_checkbox: 'Oops! No answers were checked off. You have to check at least one box.',
  elaboration_box: 'Oops! This field can’t be blank.',
  address_1: 'Oops! This line can’t be empty. Please insert your shipping address.',
  addressOne: 'Oops! This line can’t be empty. Please insert your shipping address.',
  city: 'Oops! This line can’t be empty. Please type in the name of the city you live in.',
  region: 'Oops! Please select the state you live in.',
  postal_code: 'Sorry! Please type in the zipcode you live in.',
  postalCode: 'Sorry! Please type in the zipcode you live in.',
  postal_code_uk: 'Sorry! Please type in the postcode you live in.',
  name_on_card: 'Oops! This field can’t be blank. Please enter the name that appears on your credit card.',
  sign_up: 'This email/password combination is invalid. Please try again.',
  have_an_account: 'Email address already in use.',
  therapist_referral_general: 'You have to enter at least one client’s contact information to move forward.',
  referral_first_name: 'Please enter your client’s first name here.',
  referral_last_name: 'Please enter your client’s last name here.',
  referral_email: 'Oops! Please enter a valid email address. Example: example@email.com',
  emergency_contact_first_name: 'This field can’t be blank.',
  emergency_contact_last_name: 'This field can’t be blank.',
  number_of_days: 'Please enter a number from 0 to 366.',
  emergency_contact_phone_number:
    'Hmm...the phone number you entered does not seem right. This field can only contain a valid phone number',
  sms_opted_in: 'You must agree to our Terms of Use to create an account.',
  service_line_not_available: 'We are not live in your state yet, but please check back soon!',
  password_include_characters: 'A symbol, number, lowercase, and uppercase letter',
  password_update: 'Connection error: We couldn’t save your new password. Try again or contact us for more help.',
  password_social_login:
    'Password resets cannot be performed on accounts linked with social logins. To unlink your account and use password authentication please reach out to support'
};

const newErrorForShippingAddress = {
  address_1: 'Required',
  addressOne: 'Required',
  city: 'Required',
  region: 'Required',
  postal_code: '5 digit ZIP code is required',
  postalCode: '5 digit ZIP code is required'
};

export const resizeImage = (screenshot, cb) => {
  const img = new Image();

  img.onload = () => {
    const canvas = document.createElement('canvas');
    const max_size = 1200;

    let { width } = img;
    let { height } = img;

    if (width > height && width > max_size) {
      height *= max_size / width;
      width = max_size;
    } else if (height > max_size) {
      width *= max_size / height;
      height = max_size;
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(img, 0, 0, width, height);
    const resizedImage = canvas.toDataURL('image/jpeg');

    cb(resizedImage);
  };

  img.src = screenshot;
};

export const mapPlanToCouponCodeMedicalManagement = plan => {
  switch (plan) {
    case 'medication-management-85':
      return {
        coupon: strings.defaultMedicationManagementNYAndFL,
        price: strings.defaultMedicationManagementNYAndFLPrice,
        offering: plan
      };
    default:
      return {};
  }
};

export const planMedicationManagement = ['medication-management-85'];

export const calculateStep = (number1, number2) => {
  if (number2 === 0) {
    return 0;
  }
  return Math.round((number1 / number2) * 100) / 100;
};

// States get discount CARE79 and CARE99
export const allowDiscount = state => {
  return ['AR', 'LA', 'GA', 'OH', 'FL', 'MN', 'IN', 'AL', 'MA', 'NC', 'IL', 'TX'].includes(state);
};

// only selected states can see new plans
export const directProviderMessagingAndTherapy = state => {
  return ['MO'].includes(state);
};

// ideally fetch it from BE
const insuranceStates = ['CA'];

export const allowInsurance = state => {
  return insuranceStates.includes(state);
};

export const verifyLuhn = num => {
  let digit;
  let j;
  let odd;
  let sum;
  odd = true;
  sum = 0;
  const digits = `${num}`.split('').reverse();
  for (j = 0; j < digits.length; j += 1) {
    digit = digits[j];
    digit = parseInt(digit, 10);

    odd = !odd;
    if (odd) {
      digit *= 2;
    }
    if (digit > 9) {
      digit -= 9;
    }
    sum += digit;
  }
  return sum % 10 === 0;
};

export const getCardTypeAndMaxLength = number => {
  const onlyDigits = number.replace(/\D/g, '');

  if (/^3[47]\d{0,13}$/.test(onlyDigits.substring(0, 15))) {
    return ['AmEx', 17, onlyDigits];
  }

  if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(onlyDigits.substring(0, 14))) {
    return ['DinerClub', 16, onlyDigits];
  }

  return ['RegularCC', 19, onlyDigits];
};

export const cvvLengthByType = type => {
  if (type === 'AmEx') {
    return 4;
  }

  return 3;
};

export function formatCardNumber(value) {
  const [type, maxLength, onlyDigits] = getCardTypeAndMaxLength(value);

  let formatted;

  if (type === 'AmEx') {
    // american express, 15 digits
    formatted = onlyDigits.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
  } else if (type === 'DinerClub') {
    // diner's club, 14 digits
    formatted = onlyDigits.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
  } else {
    // regular cc number, 16 digits
    formatted = onlyDigits
      .replace(/(\d{4})/, '$1 ')
      .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
      .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
  }

  if (onlyDigits.length <= maxLength) {
    return formatted;
  }

  return formatted.substring(0, maxLength);
}

export const insuranceQuestionBankNames = {
  'follow-up-assessment': [
    'information_follow-up',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'manic_depression',
    'ptsd_assessment_full',
    'alcohol_dependence_assessment_full',
    'dast10_assessment_full',
    'whoqol_bref_assessment_full',
    'drug_abuse_screening_test2',
    'assessment_end'
  ],
  therapy: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'progress_checkout',
    'emergency_contact',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  therapy_2_sessions_monthly: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'progress_checkout',
    'emergency_contact',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  therapy_4_sessions_monthly: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'progress_checkout',
    'emergency_contact',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  coaching: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'progress_checkout',
    'emergency_contact',
    'information_counselor_preferences',
    'counselor_preferences',
    'information_counselor',
    'choose_counselor'
  ],
  'coaching-abtest': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'progress_checkout',
    'emergency_contact',
    'information_counselor_preferences',
    'counselor_preferences',
    'information_counselor',
    'choose_counselor'
  ],
  medication: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'visit_type',
    'visit_with_provider'
  ],
  'mat-counseling': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'communication',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_counselor_preferences',
    'counselor_preferences',
    'information_counselor',
    'choose_counselor'
  ],
  'medication-therapy': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  therapy_gp_2_sessions_monthly: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'communication',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  therapy_gp_4_sessions_monthly: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'communication',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  'medication-coaching': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_counselor_preferences',
    'counselor_preferences',
    'information_counselor',
    'choose_counselor'
  ],
  'medication-coaching-abtest': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_counselor_preferences',
    'counselor_preferences',
    'information_counselor',
    'choose_counselor'
  ],
  counseling: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'progress_checkout',
    'emergency_contact'
  ],
  'first-ad-hoc-therapy': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'survey',
    'shipping_address',
    'progress_checkout',
    'emergency_contact',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  'second-ad-hoc-therapy': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'alcohol_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'shipping_address',
    'progress_checkout',
    'emergency_contact',
    'communication',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ]
};

export const cashQuestionBankNames = {
  'follow-up-assessment': [
    'information_follow-up',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'alcohol_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'manic_depression',
    'ptsd_assessment_full',
    'alcohol_dependence_assessment_full',
    'dast10_assessment_full',
    'whoqol_bref_assessment_full',
    'drug_abuse_screening_test2',
    'shipping_address',
    'medication_insurance',
    'rx_task_completed',
    'initial_intake_form_task',
    'intake_assessment_task',
    'intake_assessment_reactivation_task',
    'assessment_end'
  ],
  'follow-up-medical-assessment': [
    'information_assessment',
    'mental_health_conditions_follow',
    'mental_health_medication_assessment'
  ],
  therapy: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'alcohol_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'progress_checkout',
    'emergency_contact',
    'communication',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  therapy_2_sessions_monthly: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'alcohol_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'progress_checkout',
    'emergency_contact',
    'communication',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  therapy_4_sessions_monthly: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'alcohol_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'progress_checkout',
    'emergency_contact',
    'communication',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  coaching: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'alcohol_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'progress_checkout',
    'emergency_contact',
    'communication',
    'information_counselor_preferences',
    'counselor_preferences',
    'information_counselor',
    'choose_counselor'
  ],
  'coaching-abtest': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'alcohol_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'progress_checkout',
    'emergency_contact',
    'communication',
    'information_counselor_preferences',
    'counselor_preferences',
    'information_counselor',
    'choose_counselor'
  ],
  medication: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'communication',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_counselor_preferences',
    'counselor_preferences',
    'information_counselor',
    'choose_counselor'
  ],
  medication_99: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'communication',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_counselor_preferences',
    'counselor_preferences',
    'information_counselor',
    'choose_counselor'
  ],
  'mat-counseling': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'communication',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_counselor_preferences',
    'counselor_preferences',
    'information_counselor',
    'choose_counselor'
  ],
  'medication-therapy': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'communication',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  therapy_gp_2_sessions_monthly: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'communication',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  therapy_gp_4_sessions_monthly: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'communication',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  'medication-first-ad-hoc-therapy': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'communication',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  'medication-second-ad-hoc-therapy': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'communication',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  'medication-coaching': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'communication',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_counselor_preferences',
    'counselor_preferences',
    'information_counselor',
    'choose_counselor'
  ],
  'medication-coaching-abtest': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'communication',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_counselor_preferences',
    'counselor_preferences',
    'information_counselor',
    'choose_counselor'
  ],
  counseling: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'assessment_end',
    'plan_selection',
    'progress_checkout',
    'emergency_contact',
    'manic_depression',
    'communication',
    'information_counselor_preferences',
    'counselor_preferences',
    'information_counselor',
    'choose_counselor',
    'oon_insurance'
  ],
  'medication-management-85': [
    'anxiety_assessment_full',
    'depression_assessment_full',
    'simple_manic_depression',
    'health_and_history_part3'
  ],
  'first-ad-hoc-therapy': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'alcohol_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'shipping_address',
    'progress_checkout',
    'emergency_contact',
    'communication',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  'second-ad-hoc-therapy': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'alcohol_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'shipping_address',
    'progress_checkout',
    'emergency_contact',
    'communication',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  'upsell-from-meditation': [
    'insomnia_assessment_full',
    'depression_assessment_full',
    'alcohol_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'drug_abuse_screening_test2',
    'assessment_end',
    'checkout',
    'shipping_address',
    'progress_checkout',
    'emergency_contact',
    'communication',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ]
};

const flows_removed_for_dpm = [
  'update_plan',
  'progress_provider_visit',
  'information_counselor_preferences',
  'counselor_preferences',
  'information_counselor',
  'choose_counselor'
];

const flows_removed_for_dpm_with_therapy = [
  'progress_provider_visit',
  'information_counselor_preferences',
  'counselor_preferences',
  'information_counselor',
  'choose_counselor'
];

export const bankToPath = bankNames => (path, bank) => {
  const idx = bankNames.indexOf(bank?.name);
  const pathArr = path;
  if (idx > -1) {
    pathArr[idx] = bank;
  }
  return pathArr;
};

const isCareManagerNeeded = (oldOfferingKey, newOfferingKey) => {
  const counselingOfferingKeys = ['counseling', 'medication', 'medication_99'];
  const coachingOfferingKeys = ['medication-coaching', 'coaching', 'medication-coaching-abtest', 'coaching-abtest'];

  return (
    (counselingOfferingKeys.includes(oldOfferingKey) && coachingOfferingKeys.includes(newOfferingKey)) ||
    (counselingOfferingKeys.includes(newOfferingKey) && coachingOfferingKeys.includes(oldOfferingKey))
  );
};

const isAdHocTherapyToTherapy = (oldOfferingKey, newOfferingKey) => {
  return (
    ['first-ad-hoc-therapy', 'second-ad-hoc-therapy'].includes(oldOfferingKey) && ['therapy'].includes(newOfferingKey)
  );
};
const isAdHocTherapyToMedicationTherapy = (oldOfferingKey, newOfferingKey) => {
  return (
    ['first-ad-hoc-therapy', 'second-ad-hoc-therapy'].includes(oldOfferingKey) &&
    ['medication-therapy'].includes(newOfferingKey)
  );
};
const isMedAdHocTherapyToMedicationTherapy = (oldOfferingKey, newOfferingKey) => {
  return (
    ['medication-first-ad-hoc-therapy', 'medication-second-ad-hoc-therapy'].includes(oldOfferingKey) &&
    ['medication-therapy', 'therapy'].includes(newOfferingKey)
  );
};

export const customDifference = ({
  newBanks,
  oldBanks,
  isProviderNeeded = false,
  isTherapistNeeded = false,
  newOfferingKey,
  oldOfferingKey
}) => {
  const providerBanks = ['progress_identity', 'visit_type', 'visit_with_provider', 'progress_provider_visit'];
  const therapistBanks = ['information_therapists', 'choose_therapist'];
  const adHocTherapistBanks = [
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ];
  const coachBanks = [
    'information_counselor_preferences',
    'counselor_preferences',
    'information_counselor',
    'choose_counselor'
  ];

  const setB = new Set(oldBanks);
  const requiredBanks = [];
  if (isProviderNeeded) {
    requiredBanks.push(...providerBanks);
  }

  if (isTherapistNeeded) {
    requiredBanks.push(...therapistBanks);
  }

  if (isCareManagerNeeded(oldOfferingKey, newOfferingKey)) {
    requiredBanks.push(...coachBanks);
  }

  if (oldOfferingKey === 'medication-first-ad-hoc-therapy' && newOfferingKey === 'medication-second-ad-hoc-therapy') {
    requiredBanks.push(...adHocTherapistBanks);
  }

  if (isAdHocTherapyToTherapy(oldOfferingKey, newOfferingKey)) {
    requiredBanks.push('choose_therapist');
  }

  if (isAdHocTherapyToMedicationTherapy(oldOfferingKey, newOfferingKey)) {
    requiredBanks.push(...['oon_insurance', 'choose_therapist']);
  }

  if (isMedAdHocTherapyToMedicationTherapy(oldOfferingKey, newOfferingKey)) {
    requiredBanks.push('choose_therapist');
  }

  if (newOfferingKey === 'second-ad-hoc-therapy') {
    requiredBanks.push(...adHocTherapistBanks);
  }

  const customFilter = bank => {
    if (requiredBanks.includes(bank)) {
      return true;
    }
    return !setB.has(bank);
  };

  return newBanks.filter(customFilter);
};

const handlePendingUpdates = (change, isPilot, region) => {
  const previousChangeBanks = change.is_old_plan_insurance ? insuranceQuestionBankNames : cashQuestionBankNames;
  const newChangeBanks = change.is_new_plan_insurance ? insuranceQuestionBankNames : cashQuestionBankNames;
  const diff = customDifference({
    newBanks: newChangeBanks[change.new_offering_key],
    oldBanks: previousChangeBanks[change.old_offering_key],
    isProviderNeeded: change.needs_insurance_provider,
    isTherapistNeeded: change.needs_insurance_therapist,
    newOfferingKey: change.new_offering_key,
    oldOfferingKey: change.old_offering_key
  });

  if (diff[diff.length - 1] === 'progress_provider_visit') {
    diff.pop();
  }

  // cut all counselor_preferences screens
  if (isPilot && directProviderMessagingAndTherapy(region)) {
    return diff.filter(name => !flows_removed_for_dpm_with_therapy.includes(name));
  }
  if (isPilot) {
    return diff.filter(name => !flows_removed_for_dpm.includes(name));
  }

  return diff;
};

export const handleInitialPlanChange = (isInsurance, isPilot, region, flowType = 'medication') => {
  const questionBanks = isInsurance ? insuranceQuestionBankNames : cashQuestionBankNames;
  const planFlows = questionBanks[flowType] || questionBanks['follow-up-assessment'];

  // cut all counselor_preferences screens
  if (isPilot && directProviderMessagingAndTherapy(region)) {
    return planFlows.filter(name => !flows_removed_for_dpm_with_therapy.includes(name));
  }
  if (isPilot) {
    return planFlows.filter(name => !flows_removed_for_dpm.includes(name));
  }

  return planFlows;
};

export const adhdPricingTestState = [
  'AZ',
  'CA',
  'CO',
  'DE',
  'DC',
  'FL',
  'HI',
  'IL',
  'IA',
  'KS',
  'KY',
  'ME',
  'MD',
  'MA',
  'MI',
  'NE',
  'NM',
  'NY',
  'NC',
  'OR',
  'SD',
  'VT',
  'VA',
  'WA',
  'WI',
  'OH'
];

export const toBankNames = (pending, change, flowType, isInsurance, isPilot, region) => {
  if (pending) {
    return handlePendingUpdates(change, isPilot, region);
  }
  return handleInitialPlanChange(isInsurance, isPilot, region, flowType);
};

export const getNumberOfQuestionsBefore = (banks, before, isPaymentSubmitted) => {
  let sum = 0;

  if (isPaymentSubmitted) {
    let idx = banks.findIndex(bank => bank?.name === 'checkout');
    if (idx < 0) {
      idx = 0;
    }
    for (let i = idx; i < before; i += 1) {
      sum += banks[i].num_questions;
      if (banks[i]?.name === 'checkout') {
        sum -= 1; // -1 is payment page
      }
    }
    return sum;
  }

  for (let i = 0; i < before; i += 1) {
    sum += banks[i].num_questions;
  }
  return sum;
};

export const getNumberOfQuestions = (banks, isPaymentSubmitted, isFollowUp) => {
  if (isFollowUp) {
    return banks.reduce((acc, bank) => acc + bank.num_questions, 0) - 1; // -1 removed one(last question from assessment_end)
  }

  let idx = banks.findIndex(bank => bank?.name === 'checkout');
  if (isPaymentSubmitted) {
    let extra = -1; // -1 is for payment page if exists
    if (idx < 0) {
      idx = 0;
      extra = 0;
    }
    return banks.slice(idx).reduce((acc, bank) => acc + bank.num_questions, 0) + extra;
  }
  return banks.slice(0, idx).reduce((acc, bank) => acc + bank.num_questions, 0);
};

export const isBeforePayment = status => {
  return ['lead', 'canceled'].includes(status);
};

// latest visit is the first visit
export const latestVisit = arr => {
  if (arr.length > 0) {
    return arr[0];
  }

  return null;
};

export const latestInitialVisit = visits => {
  let initials = [];
  if (Array.isArray(visits)) {
    initials = visits.filter(visit => visit.is_initial === true);
  }
  return latestVisit(initials);
};
export const isVisitCompleted = visit => {
  const { complete, pending_updates } = visit;

  return complete && !pending_updates;
};

export const isRequiredQuestion = questionName => {
  return ['sex', 'allergy', 'emergency_contact', 'current_medications', 'prv_med', 'hh_med', 'specific_meds'].includes(
    questionName
  );
};

export const formatDate = date => {
  if (!date) {
    return null;
  }

  const [year, month, day] = date.split('-').map(Number);
  return [month, day, year].join('/');
};

export const removeDuplicates = (arrOfObj, prop) => {
  const arr = arrOfObj.map(obj => obj[prop]);
  return [...new Set(arr)];
};

export const mapCodeToError = err => {
  if (err.code) {
    return { message: 'Please confirm that your payment details are correct.' };
  }

  if (err.data) {
    return err.data;
  }

  return { message: '' };
};

export const isRegionValid = region => {
  return valid_states.some(valid_state => valid_state.includes(region.toLowerCase()));
};

export const formatDob = (dob, type) => {
  if (!dob) return '';

  if (type === 'dashes') {
    if (dob.indexOf('/') === -1) return dob;
    const [mm, dd, yyyy] = dob.split('/');
    return `${yyyy}-${mm}-${dd}`;
  }

  if (dob.indexOf('/') >= 0) return dob;
  if (dob.indexOf('-') === -1) return dob;
  const [yyyy, mm, dd] = dob.split('-');
  return `${mm}/${dd}/${yyyy}`;
};

export const stateToErrorReducer = (state, prefix = '') =>
  Object.keys(state).reduce((err, type) => {
    const errObj = err;
    if (error_checker[type]) {
      if (type === 'password_confirm') {
        if (!error_checker[type](state[type], state.password)) {
          errObj[type] = common_errors[prefix + type];
        }
        return errObj;
      }

      if (['address_1', 'city', 'region', 'postal_code'].includes(type)) {
        if (!error_checker[type](state[type])) {
          errObj[type] = newErrorForShippingAddress[prefix + type];
        }
        return errObj;
      }

      if (!error_checker[type](state[type])) {
        errObj[type] = common_errors[prefix + type];
      }
    }
    return errObj;
  }, {});

export const imgtoBlob = (img, contentType = '', sliceSize = 512) => {
  const b64Data = img.replace(/^data:.+;base64,/, '');

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
};

export const isManicDepression = answer => {
  if (Array.isArray(answer)) {
    const found = answer.find(option => option?.name === 'Manic depression (Bipolar disorder)');
    return !!found;
  }

  if (typeof answer === 'object') {
    return answer.answer === 'No';
  }

  return false;
};

export const getStartDate = date => {
  if (date) {
    return new Date(formatDate(date));
  }

  return new Date();
};

export const isUserActive = status => status && !['canceled', 'paused'].includes(status);

export const isUserCanceled = status => status && ['canceled', 'cancellation_scheduled'].includes(status);

export const isUserFullyCanceled = status => status && ['canceled'].includes(status);

export const isUserPaused = status => status && ['paused', 'pause_scheduled'].includes(status);

export const getNextBankOffset = (questionName, answer) => {
  switch (questionName) {
    case 'mental_health_conditions':
      return isManicDepression(answer) + 1;
    case 'elated_mood':
      return isManicDepression(answer) + 2;
    default:
      return 0;
  }
};

export const is_touch_device = () => {
  return ('ontouchstart' in window || window.DocumentTouch) === true;
};

export const sleep = timeout => new Promise(resolve => setTimeout(resolve, timeout));

export const defaultError = 'Hmmm… It seems like something went wrong. Please try again.';

export const mapResponseToError = error => {
  if (!error) {
    return defaultError;
  }

  const { data = {} } = error;
  const key = Object.keys(data).find(item => typeof data[item] !== 'boolean');

  if (Array.isArray(data[key]) && data[key].length > 0) {
    return data[key][0];
  }

  if (typeof data[key] === 'string' && data[key].length > 0) {
    return data[key];
  }

  return defaultError;
};

// User this function to map error from API
export const mapResponseAPIToError = ({ response }) => {
  if (response?.data?.errors?.length > 0) {
    return response.data.errors.join(', ');
  }

  return defaultError;
};

export const mapResponseToError1 = error => {
  if (!error) {
    return defaultError;
  }

  const data = error?.response?.data || {};

  const key = Object.keys(data).find(item => typeof data[item] !== 'boolean');

  if (Array.isArray(data[key]) && data[key].length > 0) {
    return data[key][0];
  }

  if (typeof data[key] === 'string' && data[key].length > 0) {
    return data[key];
  }

  return defaultError;
};

export const useDelay = () => {
  const timeoutRef = useRef(0);
  const targetRef = useRef();
  const callbackRef = useRef();

  const check = () => {
    if (isPast(targetRef.current)) {
      callbackRef.current();
    } else {
      timeoutRef.current = setTimeout(check, 1000);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (time, fn) => {
    targetRef.current = time;
    callbackRef.current = fn;

    check();
  };
};

export const mapTypeToView = type =>
  ({
    care_manager: 'schedule-cm',
    provider: 'schedule-dr',
    therapist: 'schedule-th'
  })[type] || type;

export const mapNameToType = type =>
  ({
    care_manager: 'Care Counselor',
    provider: localStorage.getItem('country') === 'uk' ? 'private GP' : 'prescriber',
    therapist: 'therapist'
  })[type] || type;

export const mapNameToTypes = type =>
  ({
    care_manager: 'Care Counselors',
    provider: localStorage.getItem('country') === 'uk' ? 'Private GPs' : 'Prescribers',
    therapist: 'Therapists',
    coach: 'Coaches'
  })[type] || type;

export const medicationName = prescription => {
  if (!prescription) return '';
  const name = prescription.medication_name_generic || prescription.medication_name_for_local_pharmcy;

  return name ? capitalize(name) : '';
};

export const scheduleLink = ({ appointment }, offeringKey) => {
  switch (appointment.appointment_type) {
    case 'therapist':
      if (offeringKey === 'therapy_2_sessions_monthly' || offeringKey === 'therapy_gp_2_sessions_monthly') {
        return '/patient/schedule/therapist/schedule-th/30';
      }
      return '/patient/schedule/therapist/schedule-th/45';
    case 'care_manager':
      return '/patient/schedule/care_manager/schedule-cm/30';
    default:
      return '/dashboard';
  }
};

export const appointmentStatusBarTime = ({ appointment }) => {
  let delay = 0;

  switch (appointment.appointment_type) {
    case 'therapist':
      delay = 38;
      break;
    case 'care_manager':
      delay = 28;
      break;
    default:
      throw new Error('This appointment type doesnt use an iFrame bar delay');
  }

  return addMinutes(appointment.starts_at, delay);
};

export const formatCurrency = value => {
  const instance = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  return instance.format(value);
};

/**
 * Checks if the user-agent is via iOS, Android, or something else.
 *
 * @returns 'iOS', 'Android', undefined
 */
export const getMobileType = () => {
  const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
  const isIos = Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  if (isIos) return 'iOS';

  const isAndroid = Boolean(userAgent.match(/Android/i));
  if (isAndroid) return 'Android';

  return undefined;
};

export const isMobileWebView = () => {
  return sessionStorage.getItem('fromMobileUser') !== null;
};

export const valueFromStorage = key => (typeof window !== 'undefined' ? localStorage.getItem(key) : null);

export const convertIdToPlanName = (id, name) => {
  switch (id) {
    case 'medication-therapy-smoking-cessation-treatment':
      return 'Medication + Therapy + Smoking Cessation Treatment';
    case 'medication-smoking-cessation-treatment':
      return 'Medication + Smoking Cessation Treatment';
    default:
      return `${name} Plan`;
  }
};

export const isAddictionServiceLine = visits => {
  return visits?.some(({ service_lines }) =>
    service_lines?.some(({ name }) => name?.includes('smoking') || name?.includes('alcohol'))
  );
};

export const isOnlySmokingAddictionServiceLine = visits => {
  return visits?.every(({ service_lines }) => service_lines?.every(({ name }) => name?.includes('smoking')));
};

export const insuranceModalMessages = () => {
  return (
    `Adding photos of your prescription insurance card is required in order to have your medications covered by insurance. ` +
    `By skipping this step, you consent to paying full price for your medication when prescribed.`
  );
};

export const capitalizeFirstLetter = data => {
  if (data != null) {
    return data.charAt(0).toUpperCase() + data.slice(1);
  }
  return '';
};

export const isAsyncTelehealthOffering = offeringKey => {
  return ['medication-management-85'].includes(offeringKey);
};

// sync from server - app/models/plan.rb - latest commit <7324291c12ba357d0987c20b139b95c90df38788>
export const isMedicationOffering = offeringKey => {
  return [
    'medication',
    'medication_99',
    'medication-smoking-cessation-treatment',
    'medication-adhd-115-management',
    'medication-adhd-135-management',
    '99_med_management',
    '109_med_management',
    '119_med_management'
  ].includes(offeringKey);
};

export const isTherapyOffering = offeringKey => {
  return ['therapy', '275_therapy', '290_therapy'].includes(offeringKey);
};

export const isBasicTherapyPlan = offeringKey => {
  return ['medication-therapy', 'therapy'].includes(offeringKey);
};

export const isMedicationTherapyOffering = offeringKey => {
  return ['medication-therapy', '365_meds_therapy', '345_meds_therapy'].includes(offeringKey);
};

export const isMeditationServiceLine = serviceLine => {
  return ['meditation', 'meditation_yearly', 'meditation_b', 'meditation_yearly_b'].includes(serviceLine);
};

export const isInNetworkState = state => {
  return ['TX'].includes(state);
};

const rxbinRegExp = /^[0-9]*$/;

export const validationSchema = Yup.object().shape({
  policy_id: Yup.string().required('Required'),
  rx_bin: Yup.string()
    .matches(rxbinRegExp, 'Your Rx Bin number has to be 6 digits long')
    .min(6, 'Your Rx Bin number has to be 6 digits long')
    .max(6, 'Your Rx Bin number has to be 6 digits long')
    .required('Required')
});

export const formatLabel = (label, value) => {
  if (!value) {
    return label;
  }
  return label.split(value).reduce((prev, current, i) => {
    if (!i) {
      return [current];
    }
    return prev.concat(<b key={value + current}>{value}</b>, current);
  }, []);
};

export const isV4PostVisit = flowKey => flowKey === 'initial_v4';
export const DEFAULT_FLOW = 'v4';

export const isSameDay = (date1, date2) => {
  if (!date1 || !date2) {
    return false;
  }

  const actualDate1 = new Date(date1);
  const actualDate2 = new Date(date2);
  return (
    actualDate1.getFullYear() === actualDate2.getFullYear() &&
    actualDate1.getMonth() === actualDate2.getMonth() &&
    actualDate1.getDate() === actualDate2.getDate()
  );
};

export const isRunningOnMobile = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('utm_source') === 'mobile_app' || RNWindow.ReactNativeWebView !== undefined;
};

export const isProduction = () => {
  return (
    process.env.NODE_ENV === 'production' &&
    !window.location.href.includes('cerebral-development') &&
    !window.location.href.includes('app-staging') &&
    !window.location.href.includes('app-dev') &&
    !window.location.href.includes('app-release') &&
    !window.location.href.includes('app-fe-release') &&
    !window.location.href.includes('app-insurance') &&
    !window.location.href.includes('preprod')
  );
};

export const isReleaseOrProduction = () => {
  return process.env.REACT_APP_CLIENT_ENV === 'production' || process.env.REACT_APP_CLIENT_ENV === 'release';
};

export const getFAQInsuranceReferralURL = () => {
  return process.env.REACT_APP_CLIENT_ENV === 'production'
    ? 'https://cerebral.com/faqs/plans-costs-insurance#why-do-i-need-a-referral'
    : 'https://home-fe-release.getcerebral.com/faqs/plans-costs-insurance#why-do-i-need-a-referral';
};

export const MENTAL_HEALTH_SPECIALITY = {
  medication_therapy: 'Mental Health Therapy / Mental Health Medication Management',
  medication: 'Mental Health Medication Management',
  therapy: 'Mental Health Therapy'
};

export const parsePhoneNumber = (phone, defaultCode = '+1') => {
  const [currentCode, ...currentPhone] =
    phone && phone.includes(defaultCode) ? phone.split(' ') : [defaultCode, phone || ''];
  return { currentCode, currentPhone: currentPhone.join(' ') };
};
