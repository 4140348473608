import { CarePerson, TypesOfCarePerson } from 'types/care-person';
import { CareCoordinator } from 'types/care-coordinator';
import { CareUserEnum } from '../types/care-user.enum';

export const CARE_PERSON_TYPE_DOCTOR = 'Prescriber';

export const mapRoleToType = (carePerson: CarePerson): TypesOfCarePerson => {
  if (!carePerson.user) return 'Care Counselor';
  const {
    user: { role },
    name
  } = carePerson;

  const mappedRoles: Partial<Record<CareUserEnum, TypesOfCarePerson>> = {
    [CareUserEnum.COACH]: 'Coach',
    [CareUserEnum.CARE_COORDINATOR]: 'Coordinator',
    [CareUserEnum.DOCTOR]: 'Prescriber',
    [CareUserEnum.MHC]: name === 'Kate Kingsley' || name === 'Eileen Davis' ? 'Coordinator' : 'Care Counselor'
  };
  return mappedRoles[role] || 'Therapist';
};

export const hasCareCoordinator = (careCoordinator: CareCoordinator | Record<string, unknown>): boolean => {
  return careCoordinator && Object.keys(careCoordinator).length > 0;
};

interface StringVariation {
  singularNormal: string;
  pluralNormal: string;
  singularCapitalize: string;
  pluralCapitalize: string;
  singularUppercase: string;
  pluralUppercase: string;
}

function buildStringVariant(str: string): StringVariation {
  return {
    singularNormal: str,
    pluralNormal: `${str}s`,
    singularCapitalize: `${str.charAt(0).toUpperCase()}${str.slice(1)}`,
    pluralCapitalize: `${str.charAt(0).toUpperCase()}${str.slice(1)}s`,
    singularUppercase: `${str.toUpperCase()}`,
    pluralUppercase: `${str.toUpperCase()}S`
  };
}

export const prescriberVariant: StringVariation = buildStringVariant('prescriber');
export const providerVariant: StringVariation = buildStringVariant('provider');
