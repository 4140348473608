export enum API_PATHS {
  CALENDAR = '/api/calendar',
  ILV_VISITS = '/api/visit_requests',
  PLANS = '/api/plans',
  PLAN_GROUP = '/api/plan_group',
  PLAN_DETAILS = '/api/plan_details',
  PLAN_UPGRADE = '/api/patients/upgrade',
  CAPACITY_CHECK = '/api/waitlists/prescriber_capacity_check?care_person_type=provider',
  CONTRACT_RATE = '/api/contract_rates',
  WAITLIST_CHECK = '/api/waitlists/check_patient_in_waitlist',
  PLAN_GROUP_PLANS = '/api/v2/plan_group/plans'
}
