import { datadogLogs } from '@datadog/browser-logs';

export const initDatadog = () => {
  datadogLogs.init({
    clientToken: 'pub78022276b60eb529d001d23c9276592e',
    site: 'datadoghq.com',
    service: 'Client',
    sessionSampleRate: 100,
    forwardErrorsToLogs: false,
    env: process.env.REACT_APP_CLIENT_ENV || process.env.NODE_ENV,
    version: process.env.REACT_APP_GIT_SHA || 'missing-git-sha'
  });
  datadogLogs.setGlobalContextProperty('referrer', document.referrer);
  if (process.env.NODE_ENV === 'production') {
    datadogLogs.logger.setLevel('info');
  }
};
