import React, { CSSProperties } from 'react';

interface ErrorProps {
  className?: string;
  error?: string;
  style?: CSSProperties;
}

const Error = ({
  className = 'red left pad-0 marg-0 weight_normal',
  error = '',
  style = { marginTop: '5px' }
}: ErrorProps) => (
  <p className={className} style={style}>
    {error}
  </p>
);

export default Error;
