import React from 'react';
import styled from 'styled-components/macro';

import cerebralLogo from 'img/icons/cerebral-logo.svg';

const OutterIconContainer = styled.div`
  height: 6em;
  width: 6em;
  border-radius: 50%;
  background: linear-gradient(135deg, #ddf1f3 0%, #eceaff 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
`;

const InnerIconContainer = styled.div`
  width: 2.5em;
  height: 2.5em;
  margin-top: 0.5em;
  & > img {
    height: 100%;
    width: 100%;
  }
`;

export const Icon = (): JSX.Element => {
  return (
    <OutterIconContainer>
      <InnerIconContainer>
        <img src={cerebralLogo} alt="Cerebral" />
      </InnerIconContainer>
    </OutterIconContainer>
  );
};
