import produce from 'immer';
import { exercise_actions } from '../actions/exercise_actions';

export const init_exercise_reducer = {
  categories: [],
  assignments: [],
  exercise_history: [],
  all_exercises: [],
  active_exercise: {
    exercise_id: undefined,
    rating_before: undefined,
    rating_after: undefined,
    answers: {}
  },
  completed_exercise: {}
};

export const exercise_reducer = produce((draft, action) => {
  switch (action.type) {
    case exercise_actions.SET_CATEGORIES:
      draft.categories = action.categories;
      break;
    case exercise_actions.SET_ASSIGNMENTS:
      draft.assignments = action.assignments;
      break;
    case exercise_actions.SET_EXERCISE_HISTORY:
      draft.exercise_history = action.exercises;
      break;
    case exercise_actions.SET_ALL_EXERCISES:
      draft.all_exercises = action.allExercises;
      break;
    case exercise_actions.SET_ACTIVE_EXERCISE:
      draft.active_exercise.exercise_id = action.exercise_id;
      break;
    case exercise_actions.SET_BEFORE_RATING:
      draft.active_exercise.rating_before = action.payload.rating_before;
      break;
    case exercise_actions.SET_AFTER_RATING:
      draft.active_exercise.rating_after = action.payload.rating_after;
      break;
    case exercise_actions.SET_STEP_ANSWER:
      draft.active_exercise.answers[`${action.payload.step_id}`] = action.payload.answer;
      break;
    case exercise_actions.SET_COMPLETED_EXERCISE:
      draft.completed_exercise = action.payload;
      break;

    case exercise_actions.CLEAR_ACTIVE_STEP:
      draft.active_exercise = { answers: {} };
      break;
    default:
  }
}, init_exercise_reducer);
