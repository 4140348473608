import React, { createContext, useContext, useState, useMemo, ReactNode } from 'react';
import { Question } from './types';

interface QuestionContextProps {
  children: ReactNode;
}

interface QuestionContextValue {
  question: Question | null;
  updateQuestionTitle: (title: string) => void;
  setQuestion: (question: Question) => void;
}
export const QuestionsContext = createContext<QuestionContextValue>({
  question: null,
  updateQuestionTitle: () => {},
  setQuestion: () => {}
});

export const QuestionsContextProvider = ({ children }: QuestionContextProps): JSX.Element => {
  const [question, setQuestion] = useState<Question | null>(null);

  const updateQuestionTitle = (title: string) => {
    if (!title) return;
    setQuestion({ ...question, title });
  };

  const value = useMemo(
    () => ({
      question,
      updateQuestionTitle,
      setQuestion
    }),
    [question]
  );

  return <QuestionsContext.Provider value={value}>{children}</QuestionsContext.Provider>;
};

export const useQuestionsContext = (): QuestionContextValue => {
  return useContext(QuestionsContext);
};
