import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'types/store';
import { UseOnboardingBannerState } from './types';

const useOnboardingBanner = (): UseOnboardingBannerState => {
  const [show, setShow] = useState<boolean>(true);
  const [text, setText] = useState<string>('');
  const isMeditationUpgrade = useSelector((store: Store) => Boolean(store.patient_reducer.isMeditationUpgrade));

  useEffect(() => {
    setShow(isMeditationUpgrade);
    if (isMeditationUpgrade) {
      setText(
        'Keep all of your Self-Care content for free when you sign up for one of Cerebral’s mental healthcare plans!'
      );
    }
  }, [isMeditationUpgrade]);

  return { show, text };
};

export default useOnboardingBanner;
