import Crypto, { SHA256 } from 'crypto-js';

const secret = process.env.HASH_SECRET || '';

export const hashValueSha2WithSecret = (value: string | number): string => {
  if (!value) {
    return '';
  }

  const message = typeof value === 'number' ? value.toString() : value;
  return Crypto.HmacSHA256(message, secret).toString(Crypto.enc.Hex);
};

export const hashValueSha2WithoutSecret = (value: string | number): string => {
  if (!value) {
    return '';
  }

  const message = typeof value === 'number' ? value.toString() : value;
  return SHA256(message).toString(Crypto.enc.Hex);
};
