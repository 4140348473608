export const SET_QUESTION_BANKS = 'patient/SET_QUESTION_BANKS';

const set_question_banks = (question_bank_objects, questions_banks, bank_step = 0, completed_payment_at) => ({
  type: SET_QUESTION_BANKS,
  question_bank_objects,
  question_banks: questions_banks,
  question_banks_step: bank_step,
  completed_payment_at
});

export default set_question_banks;
