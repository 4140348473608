import React from 'react';
import { useFeatureFlags } from 'features';
import { useRedirectToAuth0 } from 'components/UniversalLogin/useRedirectToAuth0';
import { useHistory } from 'react-router';
import { NB } from '@cerebral-inc/ui';
import { toWebCallbackUrl } from 'types/universal-login.utils';
import { Progress } from '../Progress';

const { Center } = NB;

export const GettingStarted = () => {
  const redirectToAuth0 = useRedirectToAuth0();
  const { data: flags } = useFeatureFlags();
  const history = useHistory();

  const nextQuestion = () => {
    if (flags?.auth_userlogin_auth0) {
      redirectToAuth0({ returnTo: toWebCallbackUrl(), screen_hint: 'signup' });
    } else {
      history.push('/signup');
    }
  };

  return (
    <>
      <Center borderBottomWidth="1px" borderBottomColor="#F0F1F5" mb="40px" pt="16px" pb="16px">
        <img height="16" src={`${process.env.PUBLIC_URL}/img/logo-purple.svg`} alt="cerebral-logo" />
      </Center>
      <Progress step={1} nextQuestion={nextQuestion} />
    </>
  );
};
