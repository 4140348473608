import { useAuth0 } from '@auth0/auth0-react';

export const useRedirectToAuth0 = () => {
  const { loginWithRedirect } = useAuth0();
  return ({ returnTo, screen_hint }: { returnTo: string; screen_hint?: string }) => {
    loginWithRedirect({
      appState: {
        returnTo
      },
      authorizationParams: {
        prompt: 'login',
        screen_hint
      }
    });
  };
};
