import Cookies from 'js-cookie';

export const msClickIdCookieName = '_uetmsclkid';

const msClkIdParamName = 'msclkid';
const msClickIdExpirationTime = 7776e3; // 90 days

export const setCookie = (name: string, value: string, expiration: number) => {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + expiration * 1e3);
  Cookies.set(name, value, { expires: expirationDate });
};

export const extractMSClickId = (params: URLSearchParams) => {
  const clickId = params.get(msClkIdParamName) || Cookies.get(msClkIdParamName);
  if (clickId) {
    let misMatchFlag = '0';
    const existedValue = Cookies.get(msClickIdCookieName);
    if (existedValue && existedValue.split('-')[0] !== clickId) {
      misMatchFlag = '1';
    }

    setCookie(msClickIdCookieName, `${clickId}-${misMatchFlag}`, msClickIdExpirationTime);
  }
};

export const getMSClickId = () => {
  const existedClickId = Cookies.get(msClickIdCookieName);
  return existedClickId || 'N';
};
