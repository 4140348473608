import React from 'react';
import { NB } from '@cerebral-inc/ui';
import { OnboardingButton } from 'components/Onboarding/shared';
import RedAlert from './icons/red-alert.svg';

const { Modal, Image, Heading, Center, Text, Box } = NB;

interface IEmergencySituationProps {
  isOpen: boolean;
  onClose: () => void;
}

export const EmergencySituation = ({ isOpen, onClose }: IEmergencySituationProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      _overlay={{
        style: {
          zIndex: 5,
          position: 'fixed' as 'absolute'
        }
      }}
      overlayVisible
      backdropVisible
    >
      <Modal.Content
        margin="24px"
        maxWidth={{
          md: '3/4',
          sm: 'full'
        }}
        width={{
          md: '824px',
          sm: 'full'
        }}
        maxHeight="100%"
      >
        <Modal.Body>
          <Modal.CloseButton _icon={{ size: '16px', color: '#250044' }} />
          <Center>
            <Image src={RedAlert} alt="" height="24" width="24" mb="2" />
            <Heading
              fontSize="22px"
              textAlign="center"
              size="h3"
              mb="2"
              lineHeight="lg"
              fontFamily="Poppins"
              fontWeight="600"
            >
              We’re here for you.{'\n'}Here’s how to get emergency help.
            </Heading>
            <Text fontFamily="Poppins" fontWeight="400" mb="2" fontSize="sm" textAlign="center">
              If you think you may hurt yourself, someone else, or plan to end your life — this is a mental
              health&nbsp;emergency.
            </Text>
            <Text textAlign="center" mb="2" fontSize="sm" fontFamily="Poppins">
              <strong>Please get help as soon as possible.</strong> Here’s how:
            </Text>
            <Box bgColor="c_secondary.50" width="full" borderRadius="1" padding="3" textAlign="center" mb="2">
              <Text mb="0.5" fontSize="sm" lineHeight="24" fontFamily="Poppins">
                1. <strong>Call 911</strong> or go to your <strong>nearest emergency room</strong>
              </Text>
              <Text mb="0.5" fontSize="sm" lineHeight="24" fontFamily="Poppins">
                2. Dial 988 to contact the <strong>National Suicide Hotline</strong>
              </Text>
              <Text mb="3" fontSize="sm" lineHeight="24" fontFamily="Poppins">
                3. Text HOME to 741-741 to access the <strong>Crisis Text Line</strong>
              </Text>
              <Text fontSize="sm" lineHeight="24" fontFamily="Poppins">
                Help is free, confidential and available 24/7
              </Text>
            </Box>
            <Text fontSize="sm" mb="2" textAlign="center" fontFamily="Poppins">
              If you are not currently experiencing an emergency, you can find long-term support at&nbsp;Cerebral.
            </Text>
            <Box maxWidth="sm" width="full">
              <OnboardingButton text="This is not an emergency" onSubmit={onClose} />
            </Box>
          </Center>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
