import React, { createContext, useContext, useEffect } from 'react';

export interface Props {
  purposes: Record<string, boolean>;
}
export interface KetchContextProviderProps {
  children: React.ReactNode;
}

export const KetchContext = createContext<Props>({ purposes: {} });

const KetchContextProvider = ({ children }: KetchContextProviderProps) => {
  const [purposes, setPurposes] = React.useState<Record<string, boolean>>({});

  useEffect(() => {
    window.ketch('on', 'consent', c => {
      if (c.purposes) {
        setPurposes(c.purposes);
      }
    });
  }, []);

  return <KetchContext.Provider value={{ purposes }}>{children}</KetchContext.Provider>;
};
export default KetchContextProvider;

export const useKetchContext = () => useContext(KetchContext);
