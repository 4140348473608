import React from 'react';
import PageLoading from 'components/UI/PageLoading/PageLoading';
import { Auth0Provider } from '@auth0/auth0-react';
import { useConfig } from './ConfigContext';

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_REDIRECT_URI, REACT_APP_AUTH0_AUDIENCE } =
    useConfig() as Record<string, string>;

  if (
    !REACT_APP_AUTH0_DOMAIN ||
    !REACT_APP_AUTH0_CLIENT_ID ||
    !REACT_APP_AUTH0_REDIRECT_URI ||
    !REACT_APP_AUTH0_AUDIENCE
  )
    return <PageLoading />;
  return (
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      useRefreshTokens
      useRefreshTokensFallback
      authorizationParams={{
        redirect_uri: REACT_APP_AUTH0_REDIRECT_URI,
        audience: REACT_APP_AUTH0_AUDIENCE
      }}
    >
      {children}
    </Auth0Provider>
  );
};
