import { PriceModel } from './types';

const createPricing = (
  priceMonth: number,
  priceMonthDiscount: number,
  priceYear?: number,
  priceYearDiscount?: number
): PriceModel => ({
  priceMonth,
  priceMonthDiscount,
  priceWeek: Math.ceil(priceMonth / 4.33),
  priceWeekDiscount: Math.ceil(priceMonthDiscount / 4.33),
  priceYear,
  priceYearDiscount
});

export default createPricing;
