import React from 'react';
import styled from 'styled-components/macro';
import { emailSupportInsuranceUS, phoneNumberSupport, emailSupport } from 'utils/supportInformation';
import mailIcon from './img/mail.svg';
import phoneIcon from './img/phone.svg';
import supportIcon from './img/support.svg';

const Wrapper = styled.div<{ isMaintenanceMode?: boolean }>`
  width: 343px;
  height: 104px;
  border: 1px solid #d2d5e7;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 21px 14px;
  position: fixed;
  left: calc(-50vw + 50% + 20px);
  bottom: 20px;
  margin-top: 80px;
  background-color: white;
  &:before {
    background: url('${supportIcon}') no-repeat center/86px;
    width: 86px;
    height: 104px;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    content: '';
  }
  @media (max-width: 1400px) {
    justify-content: center;
    margin-top: ${props => (props.isMaintenanceMode ? 'auto' : '80px')};
  }

  @media (max-width: 500px) {
    margin: ${props => (props.isMaintenanceMode ? 'auto auto 0' : '80px auto 0')};
    left: unset;
  }
`;

const Title = styled.div`
  color: #250044;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 9px;
`;

const Description = styled.div<{ icon: string }>`
  color: #250044;
  font-size: 12px;
  line-height: 16px;
  position: relative;
  text-indent: 24px;
  margin-bottom: 3px;
  &:before {
    background: url('${props => props.icon}') no-repeat center/13px;
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-right: 12px;
    position: absolute;
    left: 0;
    content: '';
  }
`;

const HelpContact = ({ isMaintenanceMode }: { isMaintenanceMode?: boolean }): JSX.Element => {
  const helpContactText = {
    title: 'Can we help?',
    mailSupport: isMaintenanceMode ? emailSupport : emailSupportInsuranceUS,
    phoneNumber: phoneNumberSupport
  };
  const helpContactIcon = {
    mailSupport: mailIcon,
    phonenumber: phoneIcon
  };

  return (
    <Wrapper isMaintenanceMode={isMaintenanceMode}>
      <Title>{helpContactText.title}</Title>
      <Description icon={helpContactIcon.mailSupport}>{helpContactText.mailSupport}</Description>
      <Description icon={helpContactIcon.phonenumber}>{helpContactText.phoneNumber}</Description>
    </Wrapper>
  );
};

export default HelpContact;
