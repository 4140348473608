import Cookies from 'js-cookie';

// This class is used to get a param from the URL query string and set it as a cookie
// mainly so it can make it through Auth0 and maintain the data if the user comes back later
export class QueryToCookieConverter {
  private key: string;

  constructor(key: string) {
    this.key = key;
  }

  private searchParams = new URLSearchParams(window.location?.search);

  public setKey(key: string) {
    this.key = key;
  }

  public getKey() {
    return this.key;
  }

  // optional key name in case param key and value is different than what should be saved on the cookie
  public setCookieParamFromQueryString(alternateKeyName?: string): void {
    if (this.searchParams.has(this.key) && alternateKeyName) {
      Cookies.set(alternateKeyName, this.searchParams.get(this.key) || '', { expires: 30 });
    } else if (this.searchParams.has(this.key)) {
      Cookies.set(this.key, this.searchParams.get(this.key) || '', { expires: 30 });
    }
  }

  public getCookieValue(): string | null {
    let param = null;
    const cookieParam = Cookies.get(this.key);

    if (cookieParam) {
      param = cookieParam;
    } else if (this.searchParams.has(this.key)) {
      param = this.searchParams.get(this.key);
    }

    return param as string;
  }
}
