import { differenceInYears, isValid, MmDdSwitch, format } from './date-fns';

const isFilledIn = str => str?.length > 0;
const isFilledInPhoneNumber = str => isFilledIn(str) && !str.includes('_');

export const email_validation = email => {
  const emailFormat = /^\w+([.(-|+)]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return !!email.match(emailFormat);
};

export const valid_states = [
  ['california', 'ca'],
  ['ohio', 'oh'],
  ['florida', 'fl'],
  ['georgia', 'ga'],
  ['maryland', 'md'],
  ['pennsylvania', 'pa'],
  ['texas', 'tx'],
  ['michigan', 'mi'],
  ['illinois', 'il'],
  ['new york', 'ny'],
  ['washington', 'wa'],
  ['virginia', 'va'],
  ['connecticut', 'ct'],
  ['colorado', 'co'],
  ['oregon', 'or'],
  ['indiana', 'in'],
  ['minnesota', 'mn'],
  ['utah', 'ut'],
  ['wisconsin', 'wi'],
  ['iowa', 'ia'],
  ['district of columbia', 'dc'],
  ['kentucky', 'ky'],
  ['tennessee', 'tn'],
  ['new mexico', 'nm'],
  ['north carolina', 'nc'],
  ['arizona', 'az'],
  ['new jersey', 'nj'],
  ['massachusetts', 'ma'],
  ['idaho', 'id'],
  ['south dakota', 'sd'],
  ['maine', 'me'],
  ['alabama', 'al'],
  ['nevada', 'nv'],
  ['alaska', 'ak'],
  ['north dakota', 'nd'],
  ['new hampshire', 'nh'],
  ['rhode island', 'ri'],
  ['delaware', 'de'],
  ['west virginia', 'wv'],
  ['wyoming', 'wy'],
  ['montana', 'mt'],
  ['south carolina', 'sc'],
  ['nebraska', 'ne'],
  ['kansas', 'ks'],
  ['mississippi', 'ms'],
  ['missouri', 'mo'],
  ['oklahoma', 'ok'],
  ['arkansas', 'ar'],
  ['hawaii', 'hi'],
  ['vermont', 'vt'],
  ['louisiana', 'la'],
  ['united kingdom', 'uk']
];

export const dobValidation = date => {
  if (!date) return false;

  const d = new Date(date).setHours(12);
  const before = new Date('01/01/1900');

  if (isValid(d)) {
    const formatted = format(d, {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });

    return d > before && date === formatted;
  }

  return false;
};

const error_checker = {
  email: email => email_validation(email),
  first_name: isFilledIn,
  last_name: isFilledIn,
  password: password => password?.length > 5,
  password_confirm: (current, password) => isFilledIn(current) && current === password,
  is_consent: value => value === true,
  phone_number: isFilledInPhoneNumber,
  state_selector: isFilledIn,
  birth_date: date => dobValidation(date),
  birth_date_uk: date => dobValidation(MmDdSwitch(date)),
  is_adult: date => isValid(date) && differenceInYears(date) >= 18,
  is_adult_uk: date => isValid(MmDdSwitch(date)) && differenceInYears(MmDdSwitch(date)) >= 18,
  weight: weight => /^\d+$/.test(weight) && Number(weight) > 0,
  height_inch: isFilledIn,
  height_ft: isFilledIn,
  number_of_days: isFilledIn,
  region: state => valid_states.some(valid_state => valid_state.includes(state.toLowerCase())),
  postal_code: zip => /^\d{5}$/.test(zip),
  postalCode: zip => /^\d{5}$/.test(zip),
  // https://stackoverflow.com/a/34755045
  postal_code_uk: zip =>
    new RegExp(
      /([Gg][Ii][Rr]\s0[Aa]{2})/.source +
        /|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/
          .source
    ).test(zip),
  city: isFilledIn,
  address_1: isFilledIn,
  addressOne: isFilledIn,
  name_on_card: isFilledIn,
  selected_checkbox: boxes => boxes.some(isChecked => isChecked === true),
  sms_opted_in: optIn => optIn
};

export default error_checker;
