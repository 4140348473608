import React from 'react';
import styled from 'styled-components/macro';

const TitleContainer = styled.h1`
  font-size: 1.75em;
  line-height: 32px;
  margin-bottom: 40px;
`;

export const Title = (): JSX.Element => {
  return <TitleContainer>Cerebral is undergoing maintenance</TitleContainer>;
};
