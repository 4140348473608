import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isFromMobile, redirectToPreSignup, toMobileCallbackUrl } from 'types/universal-login.utils';
import { sign_out } from 'actions/user_auth_action';
import { useDispatch } from 'react-redux';
import { NB } from '@cerebral-inc/ui';
import { PRE_SIGNUP_BASE_PATH } from 'components/Onboarding/PreSignupFlow/QuestionManager/constant';
import { useRedirectToAuth0 } from '../useRedirectToAuth0';

export const Signup = (): JSX.Element => {
  const history = useHistory();
  const dispatchRedux = useDispatch();
  const { Flex, Spinner } = NB;

  const redirectToAuth0 = useRedirectToAuth0();

  useEffect(() => {
    dispatchRedux(sign_out());
    if (isFromMobile()) {
      redirectToAuth0({ returnTo: toMobileCallbackUrl(), screen_hint: 'signup' });
      return;
    }

    if (sessionStorage.getItem('anonymous_id')) {
      history.push(redirectToPreSignup(`${PRE_SIGNUP_BASE_PATH}/getting-started`));
      return;
    }
    history.push(redirectToPreSignup(PRE_SIGNUP_BASE_PATH));
  }, []);

  return (
    <Flex w="50px" mx="auto" mt="70px">
      <Spinner size="1.0rem" />
    </Flex>
  );
};
