import { useState, useEffect } from 'react';

function getVisibility() {
  if (typeof document === 'undefined') return true;
  return document.visibilityState;
}

function useDocumentVisibility() {
  const [documentVisibility, setDocumentVisibility] = useState(getVisibility());

  function handleVisibilityChange() {
    setDocumentVisibility(getVisibility());
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return documentVisibility;
}

export default useDocumentVisibility;
