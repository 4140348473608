import React from 'react';
import styled from 'styled-components/macro';
import { useConfig } from 'contexts/ConfigContext';
import { getHeaderImageUrl } from '../../utils';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 64px;
  & > img {
    margin-left:5em;
  }
  @media(max-width: 500px) {
    display:none;
  }
}
`;

export const Header = (): JSX.Element => {
  const config = useConfig();
  const imageUrl = getHeaderImageUrl(config.PUBLIC_URL);
  return (
    <HeaderContainer>
      <img className="cerebral-logo" src={imageUrl} alt="Cerebral" />
    </HeaderContainer>
  );
};
