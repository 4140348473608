export const BING_ADS = 'bing_ads';

export const PAGE_VIEW = 'page-view';
export const CUSTOM_EVENT = 'custom-event';
export type ANALYTICS_PLATFORM_TYPE = typeof BING_ADS;
export type ANALYTICS_EVENT_TYPE = typeof PAGE_VIEW | typeof CUSTOM_EVENT;

export interface IAnalyticsData {
  platforms: ANALYTICS_PLATFORM_TYPE[];
  eventName: string;
  pageUrl: string;
  ltv: number;
  planNameAlias: string;
}
