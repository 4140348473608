import React from 'react';
import CheckBox from '@iconscout/react-unicons/icons/uil-check';
import { NB } from '@cerebral-inc/ui';

const { Box, Text } = NB;

interface CheckboxProps {
  children: React.ReactNode;
  checked: boolean;
  onChange: () => void;
  isReactivation?: boolean;
}

export const Checkbox = ({ children, checked, onChange, isReactivation }: CheckboxProps): JSX.Element => (
  <Box
    display="flex"
    flexDirection={isReactivation ? 'row' : 'column'}
    alignItems={isReactivation ? 'center' : 'flex-start'}
  >
    <Text onPress={onChange}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        w="16px"
        h="16px"
        mr={isReactivation ? '8px' : '0'} // Adjust margin only if reactivation
        mb={isReactivation ? '0' : '8px'} // Adjust margin only if not reactivation
        bg={checked ? '#51459E' : '#fff'}
        borderRadius={5}
        borderStyle="solid"
        borderWidth="1.5px"
        borderColor="#A7BCFF"
        position={isReactivation ? 'relative' : 'absolute'}
        top={isReactivation ? '0' : '4px'}
        left={isReactivation ? '0' : '4px'}
      >
        <CheckBox style={{ display: checked ? 'block' : 'none', marginLeft: '-1px' }} size={16} color="white" />
      </Box>
    </Text>
    <Text
      color={isReactivation ? '#353A41' : '#1C1E21'}
      onPress={onChange}
      fontFamily="Poppins"
      fontSize={12}
      lineHeight={20}
      ml={isReactivation ? '0' : '34px'}
    >
      {children}
    </Text>
  </Box>
);
