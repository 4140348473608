/* eslint-disable */
import React from 'react';
import { useLocation } from 'react-router';
import SmartBanner from 'react-smartbanner';
import { isFromMobile } from 'types/universal-login.utils';
import { getVariantValue } from '../utils/kameleoon/utils';

const DENY_PATH_LIST = Object.freeze(['/meditation-completed']);

const SmartAppBanner = () => {
  const [visible, setVisible] = React.useState(false);
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (!window.Kameleoon) return;

    const result = getVariantValue('Smart App Banner');
    if (isFromMobile() || result !== 'Reference') {
      return;
    }

    const tagName = 'apple-itunes-app';
    const existingMetaTag = document.head.querySelector(`meta[name="${tagName}"]`);
    if (!existingMetaTag) {
      const metaTag = document.createElement('meta');
      metaTag.name = tagName;
      metaTag.content = 'app-id=1537193671';
      document.head.appendChild(metaTag);
    }

    setVisible(true);
  }, [window.Kameleoon]);

  if (!visible || DENY_PATH_LIST.includes(pathname)) return <></>;
  return <SmartBanner title="Cerebral" />;
};
export default SmartAppBanner;
