import { datadogRum } from '@datadog/browser-rum';

const env = window.location.hostname;

export default () => {
  if (env === 'localhost') return null;
  datadogRum.init({
    applicationId: '53dee54c-31f4-4f39-9015-da51577d0492',
    clientToken: 'pub78022276b60eb529d001d23c9276592e',
    site: 'datadoghq.com',
    service: 'Client',
    env,
    version: process.env.REACT_APP_GIT_SHA || 'missing-git-sha',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      /https:\/\/cerebral\.com/,
      /https:\/\/(?!auth\.cerebral\.com).*\.cerebral\.com/,
      /https:\/\/(?!auth-release\.getcerebral\.com).*\.getcerebral\.com/,
      /https:\/\/(?!auth\.cerebral-development\.com).*\.cerebral-development\.com/
    ]
  });

  datadogRum.startSessionReplayRecording();
  return undefined;
};
