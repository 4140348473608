import React from 'react';
import { BannerContainer, BannerText } from './styled';
import { BannerProps } from './types';

const Banner = ({ text, show }: BannerProps) => {
  if (!show) {
    return null;
  }

  return (
    <BannerContainer>
      <BannerText>{text}</BannerText>
    </BannerContainer>
  );
};

export default Banner;
