import axios, { AxiosInstance } from 'axios';
import { Dispatch } from 'redux';
import { batch } from 'react-redux';
import { triggerEventKameleoon, setCustomDataKameleoon } from 'utils/kameleoon/utils';
import { Visit, ServiceLine } from 'types/visit';
import { Store } from 'types/store';
import * as global_actions from '../user_auth_action';
import { isUserCanceled } from '../../utils/common';
import set_question_banks from './setQuestionBanks';
import set_visit from './setVisit';

const { get_user_attr, make_headers } = global_actions;

type Props = {
  service_line_ids?: string;
  assessment_ids?: string;
  other_service_line_response?: string;
  isReactivation?: boolean;
  visit_type?: string;
  symptomIds?: number[];
  assessment_flow?: string;
  assessment_fallback?: boolean;
  message_id?: number;
  monthly_task?: boolean;
};

const create_visit =
  (
    axiosMiddleware: AxiosInstance | null,
    {
      service_line_ids,
      other_service_line_response = undefined,
      isReactivation = false,
      assessment_ids,
      visit_type,
      symptomIds,
      assessment_fallback,
      assessment_flow,
      message_id,
      monthly_task
    }: Props
  ) =>
  (dispatch: Dispatch, getState: () => Store): Promise<Visit> => {
    const { patient } = getState().global_reducer.current_user.attributes;
    const { region } = getState().patient_reducer;

    const searchParams = new URLSearchParams(window.location.search);
    const taskId = searchParams.get('task_id');
    const headers = axiosMiddleware ? {} : make_headers(get_user_attr(getState()));

    const body = {
      patient_id: patient?.id,
      visit: {
        patient_id: patient?.id,
        service_line_ids,
        other_service_line_response,
        is_reactivation: isReactivation,
        assessment_ids,
        visit_type,
        symptom_ids: symptomIds,
        assessment_fallback,
        assessment_flow,
        message_id,
        monthly_task,
        task_id: taskId ? Number(taskId) : undefined
      }
    };

    if (isUserCanceled(patient?.status)) {
      body.visit.is_reactivation = true;
    }

    return (axiosMiddleware || axios)
      .post<Visit>(`/api/patients/${patient?.id}/visits`, body, { headers })
      .then(resp => {
        batch(() => {
          dispatch(set_visit(resp.data));
          dispatch(set_question_banks({}, [], 0));
        });

        if (
          resp.data &&
          resp.data.service_lines &&
          resp.data.service_lines.find((treatment: ServiceLine) => treatment.name === 'adhd')
        ) {
          setCustomDataKameleoon('ADHD Client Data', 'select-adhd', false);
          window.localStorage.setItem('isADHD', 'true');
        } else {
          window.localStorage.setItem('isADHD', 'false');
        }

        // Trigger event state for Kameleoon
        triggerEventKameleoon(`State-${region}`);

        return Promise.resolve(resp.data);
      });
  };

export default create_visit;
