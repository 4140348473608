import React, { createContext, useContext, useEffect, ReactNode } from 'react';
import { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { Store } from 'redux';
import { useStore } from 'react-redux';
import axiosInstance from './api-client';
import { isReleaseOrProduction } from './common';

const ApiContext = createContext(axiosInstance);

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL ?? '';
const apiGatewayServerUrl = process.env.REACT_APP_API_SERVER_API_GATEWAY_URL ?? '';
const apiGatewayServerPublicUrl = `${process.env.REACT_APP_API_SERVER_API_GATEWAY_URL}/public`;
const cerebralPlatfromUrl = process.env.REACT_APP_CEREBRAL_PLATFORM_URL ?? '';
const cerebralPlatformApiGatewayUrl = process.env.REACT_APP_CEREBRAL_PLATFORM_API_GATEWAY_URL ?? '';
const publicApiEndpoints = ['v1/feature_flags', 'insurance/rte_configs'];

interface ApiProviderProps {
  children: ReactNode;
}

const handleRequestInterceptor = (config: InternalAxiosRequestConfig<unknown>, store: Store) => {
  const state = store.getState();
  const {
    global_reducer: {
      current_user: { attributes }
    }
  } = state;

  config.headers['Content-Type'] = 'application/json';
  const segmentAID = localStorage.getItem('ajs_anonymous_id');

  if (segmentAID) {
    config.headers['X-AnonymousId'] = segmentAID.replace(/['"]+/g, '');
  }

  // set the public base url for public api requests
  if (publicApiEndpoints.some(endpoint => config.url?.includes(endpoint))) {
    if (isReleaseOrProduction()) {
      config.baseURL = apiGatewayServerPublicUrl;
    }
    return config;
  }

  if (attributes.auth0AccessToken) {
    if (!config.headers.auth0AccessToken) config.headers.auth0AccessToken = attributes.auth0AccessToken;

    if (config.baseURL?.includes(cerebralPlatfromUrl)) {
      config.baseURL = config.baseURL.replace(cerebralPlatfromUrl, cerebralPlatformApiGatewayUrl);
    } else if (config.baseURL?.includes(apiServerUrl)) {
      config.baseURL = config.baseURL.replace(apiServerUrl, apiGatewayServerUrl);
    } else if (config.baseURL?.includes(apiGatewayServerPublicUrl)) {
      config.baseURL = config.baseURL.replace(apiGatewayServerPublicUrl, apiGatewayServerUrl);
    }
  } else if (attributes['access-token'] && attributes.client && attributes.uid) {
    config.headers['access-token'] = attributes['access-token'];
    config.headers.client = attributes.client;
    config.headers.uid = attributes.uid;
    if (attributes['jwt-token']) {
      config.headers.Authorization = `Bearer ${attributes['jwt-token']}`;
    } else if (attributes.Authorization) {
      config.headers.Authorization = attributes.Authorization;
    }
  }

  return config;
};

const ApiProvider = ({ children }: ApiProviderProps): JSX.Element => {
  const store = useStore();

  useEffect(() => {
    axiosInstance.interceptors?.request.use(
      config => handleRequestInterceptor(config, store),
      error => {
        throw error;
      }
    );

    axiosInstance.interceptors?.response.use(
      response => {
        return response;
      },
      error => {
        throw error;
      }
    );
  }, []);

  return <ApiContext.Provider value={axiosInstance}>{children}</ApiContext.Provider>;
};

const withApi = (Component: React.ComponentType<{ api: AxiosInstance; children?: ReactNode }>) => {
  return ({ children, ...props }: { children: ReactNode; [key: string]: unknown }) => {
    const api = useContext(ApiContext);
    return (
      <Component {...props} api={api}>
        {children}
      </Component>
    );
  };
};

const useApi = (): AxiosInstance => useContext(ApiContext);

export { ApiProvider, ApiContext, withApi, useApi, handleRequestInterceptor };
