import styled from 'styled-components/macro';

export const BannerContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 50px;
  background: #9cffe7;
  z-index: 3;
  padding: 15px 20px 8px 20px;
  @media (max-width: 991px) {
    min-height: 68px;
    position: relative;
    width: 100vw;
    height: auto;
  }
`;

export const BannerText = styled.h3`
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #250044;
  margin: 0;
  font-weight: 600;
  font-style: normal;
  font-family: 'Montserrat';
`;
