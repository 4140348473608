// key in alphabetical order
export enum CareUserEnum {
  COACH = 'coach',
  CARE_COORDINATOR = 'care_coordinator',
  CARE_MANAGER = 'care_manager',
  DOCTOR = 'doctor',
  MHC = 'mhc',
  PROVIDER = 'provider',
  THERAPIST = 'therapist',
  CURBSIDE_MD = 'curbside_md'
}
